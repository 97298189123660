import Axios from 'axios'
import { API, TokenUser } from './../../config'
import * as SecureLS from 'secure-ls';
import { 
    // register user email
    REGISTER_USER_REQUEST,
    REGISTER_USER_FAILED,
    REGISTER_USER_SUCCESS,
    SEND_LOGINUSER_REQUEST,
    SEND_LOGINUSER_SUCCESS,
    SEND_LOGINUSER_FAILED,
    SEND_GETDATAUSER_REQUEST,
    SEND_GETDATAUSER_SUCCESS,
    SEND_GETDATAUSER_FAILED
    
} from './AuthUserTypes'

const ls = new SecureLS();

// register user by email
const registerUserEmailRequest = () => {
    return {
        type: REGISTER_USER_REQUEST
    }
}

const registerUserEmailFailed = ( message ) => {
    return {
        type: REGISTER_USER_FAILED,
        payload: message
    }
}

const registerUserEmailSuccess = ( data ) => {
    return {
        type: REGISTER_USER_SUCCESS,
        payload: data
    }
}

export const registerUserEmail = ( input ) => {
    // console.log(input, 'input data dari register')
    let formdata = new FormData();

    let Headers = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    };
    
    formdata.append("fullname", input.fullname);
    formdata.append("email", input.email);
    formdata.append("password", input.password);
    formdata.append("is_admin", false);

    return (
        ( dispatch ) => {
            dispatch(registerUserEmailRequest())
            setTimeout( async() => {
                const register = await Axios.post(`${API}/auth/register`, formdata, Headers)
                    .then((res) => {
                        // console.log(res, 'sukses dari auth post register')
                        dispatch(registerUserEmailSuccess(res.data))
                        localStorage.setItem('usrmdz', res.data.message.email)
                    }).catch((err) => {
                        // console.log(err.response.data.message[0].message, 'error dari auth post register')
                        dispatch(registerUserEmailFailed(err.response.data.message[0].message))
                    })
                return register;
            }, 2000);
            
        }
    )
}

const userLoginRequest = () => {
    return {
        type: SEND_LOGINUSER_REQUEST
    }
}

const userLoginSuccess = ( data ) => {
    return {
        type: SEND_LOGINUSER_SUCCESS,
        payload: data
    }
}

const userLoginFailed = ( message ) => {
    return {
        type: SEND_LOGINUSER_FAILED,
        payload: message
    }
}

export const userLogin = ( input ) => {
    let formdata = new FormData();    
    formdata.append("email", input.email);
    formdata.append("password", input.password); 

    return (
        ( dispatch ) => {
            dispatch(userLoginRequest())
            setTimeout( async () => {
              const login = await  Axios.post(`${API}/auth`, formdata)
                    .then((res) => {
                        dispatch(userLoginSuccess(res.data))    
                        if(res.data.data.auth.is_admin === 'true') {
                            dispatch(userLoginSuccess({ message : 'kamu terdaftar sebagai admin' }))     
                        } else {
                            localStorage.setItem('nama', res.data.data.auth.fullname)               
                            ls.set('_@tKn_iD&&', {data: res.data.data.auth.auth.token}); 
                            if(res.data.data.auth.account_setting === 'true') {
                                localStorage.setItem('acc_sttg', res.data.data.auth.account_setting) 
                            } else {
                                localStorage.setItem('acc_sttg', res.data.data.auth.account_setting) 
                            }                    
                            window.location.reload()           
                        }                              
                    }).catch((res) => {
                        dispatch(userLoginFailed(res.data))
                        
                    })
                return login; 
            }, 2000);
        }
    )
}



const getDataUserLoginRequest = () => {
    return {
        type: SEND_GETDATAUSER_REQUEST
    }
} 

const getDataUserLoginSuccess = ( detailuser ) => {
    return {
        type: SEND_GETDATAUSER_SUCCESS,
        payload: detailuser
    }
}

const getDataUserLoginFailed = ( message ) => {
    return {
        type: SEND_GETDATAUSER_FAILED,
        payload: message
    }
}

export const getDetailUser =  (input) => {
    return (
        ( dispatch ) => {
            dispatch(getDataUserLoginRequest())
            let options = {
                headers: {                    
                    Authorization: `Bearer ${TokenUser}`
                }
            }
            Axios.post(`${API}/user-verify-access`,null,options)
 
            .then((res) => {
                console.log(res.data, 'verysy logib')
                if(res.data.error) {
                    dispatch(getDataUserLoginFailed(res.data.message))
                    
                } else {
                    dispatch(getDataUserLoginSuccess(res.data.data))
                    console.log(res.data.data,'ini data user login dari redux')
                }
            }).catch((err) => {
                dispatch(getDataUserLoginFailed(err.message))
                
            })
        }
    )
}


