import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Box} from '@material-ui/core';
import './../../assets/css/about.css'

const useStyles = makeStyles((theme) => ({
    
    mainFeaturedPostContent: {
      position: 'relative',      
      [theme.breakpoints.up('xs')]: {
        fontSize: '10px',
        height: 730,
        paddingTop: '130px',
        paddingBottom: '50px',
      },
      [theme.breakpoints.up('sm')]: {        
        paddingTop: '150px',
        paddingBottom: '100px',
        height: 800
      },
      [theme.breakpoints.up('md')]: {        
        paddingTop: '200px',
        height: 850
      },
      [theme.breakpoints.up('lg')]: {        
        paddingTop: '200px',
        height: 950,
      },
      [theme.breakpoints.up('xl')]: {
        paddingTop: '200px',
        height: 990,
      }
    }, 
    buttonaction: {
      background: '#fff',
     
    },
    headlineText : {     
      fontWeight: 'bold',
      paddingBottom: '5px', 
      lineHeight: 1.2,
      color: '#142461',
      textAlign: 'center',
      [theme.breakpoints.up('xs')]: {
        fontSize: '25px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '45px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '50px',
      }
    },
    headlineCamption: {
      color: '#142461',
      textAlign: 'center',
      fontFamily: 'Baloo Tamma 2',
      lineHeight: 1.5,
      [theme.breakpoints.up('xs')]: {
        fontSize: '15px',
        paddingTop: '10px'
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '20px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '25px',
      }
    },
    captiontext: {
      fontFamily: 'Open Sans',
      fontWeight: 100,
      paddingBottom: '17px',
      color: '#fff,'
    },
    headlineButton: {
      backgroundColor: '#7c71d5',
      [theme.breakpoints.up('xs')]: {
        borderRadius: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '5px',
        paddingBottom: '5px',
      },      
      [theme.breakpoints.up('sm')]: {
        borderRadius: '30px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '1px',
        paddingBottom: '1px'
      },
      [theme.breakpoints.up('md')]: {        
        borderRadius: '30px',
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      [theme.breakpoints.up('lg')]: {
        borderRadius: '30px',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingTop: '15px',
        paddingBottom: '15px'
      },
    },
    buttonText:{
      color: '#fbfbfb',
      fontFamily: 'Baloo Tamma 2',
      fontWeight: 700,
      fontSize: '20px',
      textTransform: 'capitalize'
  },
  }));

  
  const SectionAboutOne = () => {
 
    const classes = useStyles();
    
    useEffect(() => {
      const script = document.createElement('script');
      script.src = "/some_script.js";
      script.async = true;
      document.body.appendChild(script);

    return () => {
        document.body.removeChild(script);
      }
    }, []);


    return (
    <>

    
    <Paper className='mainFeaturedPostAboutOne' >
      <Container >
        <Grid container  direction='row' justify='center' >
          <Grid item lg={12} md={12} sm={12} xs={12}  >
            <div id='fontTamma' className={classes.mainFeaturedPostContent}>
              
                <Box  className={classes.headlineText} >
                We love connecting influencers and <br/> businesses for win-win collaboration.
                </Box>
    {/* 
                <Box pt={6} >
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Box className={classes.imgHeadline}  >
                        <img src={imgAbout} alt='imgabout' width='400px' height='400px' />
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Box className={classes.imgHeadline}  >
                          <img src={imgAbout} alt='imgabout' width='400px' height='400px' />
                        </Box>
                      </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Box className={classes.imgHeadline}  >
                        <img src={imgAbout} alt='imgabout' width='400px' height='400px' />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
    */}         
              <main>
                <div id="carousel">

                    <div className="hideLeft">
                      <img alt='about s' src="https://i1.sndcdn.com/artworks-000165384395-rhrjdn-t500x500.jpg" />
                    </div>

                    <div className="prevLeftSecond">
                      <img alt='about s' src="https://i1.sndcdn.com/artworks-000185743981-tuesoj-t500x500.jpg" />
                    </div>

                    <div className="prev">
                      <img alt='about s' src="https://i1.sndcdn.com/artworks-000158708482-k160g1-t500x500.jpg" />
                    </div>

                    <div className="selected">
                      <img alt='about s' src="https://i1.sndcdn.com/artworks-000062423439-lf7ll2-t500x500.jpg" />
                    </div>

                    <div className="next">
                      <img alt='about s' src="https://i1.sndcdn.com/artworks-000028787381-1vad7y-t500x500.jpg" />
                    </div>

                    <div className="nextRightSecond">
                      <img alt='about s' src="https://i1.sndcdn.com/artworks-000108468163-dp0b6y-t500x500.jpg" />
                    </div>

                    <div className="hideRight">
                      <img alt='about s' src="https://i1.sndcdn.com/artworks-000064920701-xrez5z-t500x500.jpg" />
                    </div>
                    
                </div>
              </main>
            </div>
          </Grid>
          
        </Grid>
      </Container>
    </Paper>
  </>
    )
}

export default SectionAboutOne;