import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { Button, Box } from '@material-ui/core';
import getpaid from '../../assets/img/getpaid.png'
import grownetwork from '../../assets/img/grownetwork.png'
import flexible from '../../assets/img/flexible.png'
import lastestupdate from '../../assets/img/lastestupdate.png'
import TabPanel from "./TabPanel.js";
import useStyles from './style/styleThird'

  
  const SectionThird = () => {
 
    const classes = useStyles();

    return (
    <Paper id='fontTamma' className={classes.mainFeaturedPost} >
      
      <div/>
      <Container maxWidth="lg">
        <Box textAlign='center' >
            <Box  className={classes.headTitleSecond} >
              and these are
            </Box>
            <Box className={classes.headTextSecond}  >
              What Kolia is able to provide for you.
            </Box>
        </Box>

        <Grid container direction="row" justify='center' ></Grid>
        <div id="navigation-pills">
          <Grid container>          
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TabPanel
                color='primary'
                alignCenter='center'
                tabs={[                  
                
                  {
                    tabButton: 'Get paid for what you love',
                    tabContent: (
                      <span>      
                        <Grid container  direction='row' >       
                          <Grid item md={6} sm={6} xs={12}  >
                            <Box className={classes.mainFeaturedPostLeft} >
                              <img alt='two people' width='90%' src={getpaid} />
                            </Box>
                          </Grid> 
                          <Grid item md={6} sm={6} xs={12}  >
                            <div className={classes.mainFeaturedPostRight}>
                                <Box className={classes.headlineText}  >
                                We Want To Empower You
                                </Box>
                                <Box pt={2} >
                                  <Box className={classes.headlineCamption} >
                                  Have full charge of  your earnings by being able to decide which or number of campaigns to take off. Things are automated, simplified and have a proper workﬂow. Your project is under control which gives you time and energy to focus on creating content. Your manager will not be stressed anymore and will love to run more and more campaigns.
                                  </Box>
                                </Box>
                                <Box pt={4}  >
                                <Link href="/benefit">
                                  <Button className={classes.headlineButton} variant='contained' color='action' size='large'>
                                    <Box className={classes.buttonText}>Know more</Box>
                                  </Button>
                                </Link>
                                </Box>
                            </div>
                          </Grid>
                        </Grid>
                      </span>
                    )
                  },
                  {
                    tabButton: 'Grow Your Network',
                    tabContent: (
                      <span>      
                        <Grid container  direction='row' >       
                          <Grid item md={6} sm={6} xs={12}  >
                            <Box className={classes.mainFeaturedPostLeft} >
                              <img alt='two people' width='80%' src={grownetwork} />
                            </Box>
                          </Grid> 
                          <Grid item md={6} sm={6} xs={12}  >
                            <div className={classes.mainFeaturedPostRight}>
                                <Box className={classes.headlineText}  >
                                Grow Your Network
                                </Box>
                                <Box pt={2} >
                                  <Box className={classes.headlineCamption} >
                                  We connect you to the exclusive brands, agencies and events that need your creativity and passion
                                  </Box>
                                </Box>
                                <Box pt={4}  >
                                <Link href="/benefit">
                                  <Button className={classes.headlineButton} variant='contained' color='action' size='large'>
                                    <Box className={classes.buttonText}>Know more</Box>
                                  </Button>
                                </Link>
                                </Box>
                            </div>
                          </Grid>
                        </Grid>
                      </span>
                    )
                  },
                  {
                    tabButton: 'Flexible & Freedom',
                    tabContent: (
                      <span>      
                        <Grid container  direction='row' >       
                          <Grid item md={6} sm={6} xs={12}  >
                            <Box className={classes.mainFeaturedPostLeft} >
                              <img alt='two people' width='90%' src={flexible} />
                            </Box>
                          </Grid> 
                          <Grid item md={6} sm={6} xs={12}  >
                            <div className={classes.mainFeaturedPostRight}>
                                <Box className={classes.headlineText}  >
                                Flexibility & Freedom
                                </Box>
                                <Box pt={2} >
                                  <Box className={classes.headlineCamption} >
                                  Our team of influencer success manager will assist with your on-boarding and campaign execution whenever needed.
                                  </Box>
                                </Box>
                                <Box pt={4}  >
                                <Link href="/benefit">
                                  <Button className={classes.headlineButton} variant='contained' color='action' size='large'>
                                    <Box className={classes.buttonText}>Know more</Box>
                                  </Button>
                                </Link>
                                </Box>
                            </div>
                          </Grid>
                        </Grid>
                      </span>
                    )
                  },
                  {
                    tabButton: 'Latest Updates',
                    tabContent: (
                      <span>      
                        <Grid container  direction='row' >       
                          <Grid item md={6} sm={6} xs={12}  >
                            <Box className={classes.mainFeaturedPostLeft} >
                              <img alt='two people' width='90%' src={lastestupdate} />
                            </Box>
                          </Grid> 
                          <Grid item md={6} sm={6} xs={12}  >
                            <div className={classes.mainFeaturedPostRight}>
                                <Box className={classes.headlineText}  >
                                Latest Updates
                                </Box>
                                <Box pt={2} >
                                  <Box className={classes.headlineCamption} >
                                  From nano to macro-influencers, Kolia allows brand to quickly and easily identify tens or even hundreds of influencers to work with seamlessly.
                                  </Box>
                                </Box>
                                <Box pt={4}  >
                                <Link href="/benefit">
                                  <Button className={classes.headlineButton} variant='contained' color='action' size='large'>
                                    <Box className={classes.buttonText}>Know more</Box>
                                  </Button>
                                </Link>
                                </Box>
                            </div>
                          </Grid>
                        </Grid>
                      </span>
                    )
                  },
                  
                ]}
              />

            </Grid>
            
          </Grid>
        </div>
           
       </Container>
    </Paper>

    )
}

export default SectionThird;