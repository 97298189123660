import React from 'react';
import NavbarLanding from '../../components/landing/NavbarLanding';
import SectionPortfolioOne from '../../components/portfolio/SectionPortfolioOne'
import FooterLanding from '../../components/landing/FooterLanding'

const PortfolioPage = () => {
    return (
        <React.Fragment>
            <NavbarLanding/>
            <SectionPortfolioOne/>              
            <FooterLanding/>  
        </React.Fragment>
    )
}

export default PortfolioPage;