import React, {useState,useEffect} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AboutPage from './pages/landingpage/AboutUsPage';
import BenefitPage from './pages/landingpage/BenefitPage';
import PortfolioPage from './pages/landingpage/PortfolioPage';
import RegSuccessPage from './pages/auth/RegSuccessPage';
import RequestProjects from './pages/dashboard/RequestProjects';
import ProgressProjects from './pages/dashboard/ProgressProjects';
import HistoryPage from './pages/dashboard/HistoryPage';
import ErrorPage from './pages/landingpage/ErrorPage';
import AuthRoute from './protectroute/AuthRoute';
import PriceBook from './pages/dashboard/PriceBook';
import LandingPage from './pages/landingpage/LandingPage';
import SignUpPage from './pages/auth/SignUpPage';
import SignInPage from './pages/auth/SignInPage';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import SuccesSendEmail from './pages/auth/SuccesSendEmail';
import MyInterest from './pages/dashboard/MyInterest';
import AccountSetting from './pages/dashboard/AccountSetting';
import MyProfile from './pages/dashboard/MyProfile';
import SocialProfile from './pages/dashboard/SocialProfiles'
import ActivatedAccount from './pages/auth/ActivatedAccount';
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barColors: {
    "0": "#7a6fd2",
    "0.5": "#4e499d",
    "1.0": "#383f8a" 
  },
  shadowBlur: 4
});

function AppRoute() {

  const [isLoading, setLoading] = useState(true);

  function fakeRequest() {
    return new Promise(resolve => setTimeout(() => resolve(), 300));
  }

  useEffect(() => {
    fakeRequest().then(() => {      
        setLoading(!isLoading);      
    });

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return (
      <TopBarProgress />
    )
  } else {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path='/' component={LandingPage} exact />           
          <Route path='/about' component={AboutPage} exact />         
          <Route path='/portfolio' component={PortfolioPage} exact />         
          <Route path='/benefit' component={BenefitPage} exact />                
          <Route path='/register' component={SignUpPage} exact />          
          <Route path='/login' component={SignInPage} exact />         
          <Route path='/forgot' component={ForgetPassword} exact />           
          <Route path='/reset/:token' component={ResetPassword} exact />         
          <Route path='/forgot/info' component={SuccesSendEmail} exact />         
          <Route path='/register/success' component={RegSuccessPage} exact />            
          <AuthRoute path='/project-progress' component={ProgressProjects} exact />         
          <AuthRoute path='/dashboard' component={RequestProjects} exact />         
          <AuthRoute path='/history' component={HistoryPage} exact />         
          <AuthRoute path='/my-profile' component={MyProfile} exact />
          <AuthRoute path='/social-profiles' component={SocialProfile} exact />
          <AuthRoute path='/pricebook' component={PriceBook} exact />
          <AuthRoute path='/my-interest' component={MyInterest} exact />
          <AuthRoute path='/account-setting' component={AccountSetting} exact />          
          <Route path='/activated-account' component={ActivatedAccount} exact />

          <Route path='*' component={ErrorPage} exact />         
        </Switch>
      </BrowserRouter>
      
    </div>
  )}
}

export default AppRoute;
