import React, {useEffect, useState} from 'react';
import AppBarUser from '../../components/AppBarUser';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Box,
} from '@material-ui/core';
import { API, TokenUser } from '../../config'
// import { useDispatch } from 'react-redux';
import { STORE } from '../../config'
import Axios from 'axios'
import MenuSideUser from '../../components/MenuSIdeUser'
import profile from '../../assets/img/profile_img.png'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import {Form} from 'react-bootstrap'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment'
// import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root : {
      marginBottom: '100px',
      marginTop: '130px'  
    },
    containerdiv: {
      textAlign: 'center'
    },
    rootComponent: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: '100px',
            paddingBottom: '100px',
        }, 
        [theme.breakpoints.up('sm')]: {
            paddingTop: '100px',
            paddingBottom: '100px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
    },
    lineDivider: {
        paddingTop: '6px',
        borderBottom: '2px solid #eeeeee'
    },
    medium: {
        width: theme.spacing(14),
        height: theme.spacing(14),
    },
    headlineButton: {
        textAlign: 'left',
        backgroundColor: '#7c71d5',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '7px',
          paddingBottom: '7px'
        },
      },
      buttonText:{
          color: '#fbfbfb',
          fontFamily: 'Baloo Tamma 2',
          fontWeight: 700,
          fontSize: '15px',
          textTransform: 'capitalize'
      },
      sectionAbout: {
          paddingTop: '15px'
      },
      input: {
        display: 'none'
    },
    loadingCircle: {
        paddingLeft: '70px',
        paddingRight: '70px'
    },
    loadingCircleUpdate: {
        paddingLeft: '50px',
        paddingRight: '50px'
    }
    
  }));

const MyProfile = () => {
    const classes = useStyles();
    
    const [dataProfile, setDataProfile] = useState([])       
    const [dataExist, setDataExist] = useState(false)    
    const [detailProfile, setDetailProfile] = useState([]) 
    const [loading, setLoading] = React.useState(false);
    // const [errorState, setErrorState] = React.useState(false);

    // console.log(detailProfile, 'data profile')

    const [updateProfile, setUpdateProfile] = useState({
        birthday: '',
        fullname: '',
        imageUrl: '',
        bio: '',
        place_bod: '',
        address: '',
        gender: '',
        phone_number: ''
    })

    // console.log(updateProfile, 'data update')

    const handleChange = fullname => event => {
        setUpdateProfile({...updateProfile, [fullname]: event.target.value})
      }

    const handleBirthday = data => {
        setUpdateProfile({ ...updateProfile, birthday: data })
    };
    
    useEffect(()=>{    
        const getUserProfile = () => {
            setLoading(true)            
            Axios.get(`${API}/influencer/profile`, {               
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": `Bearer ${TokenUser}`
                }
            })
            .then((res) => {
                setDataProfile(res.data.result)
                setDetailProfile(res.data.result.profile)
                setDataExist(true)
                // console.log(res.data, 'response data single')                
                setLoading(false)

            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })        
        }
        getUserProfile()

    }, []);

    
    const [alertError, setAlertError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [alertSuccess, setAlertSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")

    
    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertError(false);
      };
    
      const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertSuccess(false);
      };

    const updateProfilePhoto = (data) => {
        // console.log(data, 'data update klik')
        setLoading(true)
        setTimeout(async() => {
            if(data.image.name) {

                let formdata = new FormData();
                
                formdata.append("file", data.image);
                formdata.append("email", 'admin@mediacartz.com');
                formdata.append("password", 123456);
                
                // console.log(formdata, 'form data ') 
    
                const updateWithNewImg = await Axios.post(`${STORE}`, formdata)
                .then((res) => {
                    // console.log(res.data.data.url, 'response post foto')  
                        let url = res.data.data.url 
    
                        let updateUser = {                            
                            imageUrl : url,                            
                        }
                        Axios.post(`${API}/influencer/profile`, updateUser, {
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Authorization": `Bearer ${TokenUser}`
                            }
                        })
                        .then((res) => {
                            setLoading(false)
                            setAlertSuccess(true)
                            setSuccessMessage(res.data.message)
                            setTimeout(() => {
                                window.location.reload()
                            }, 1000);
                        }).catch((err) => {
                            console.log(err)
                            setLoading(false)
                        })  
                    
                }).catch((err) => {
                    setLoading(false)
                    setAlertError(true)
                    setErrorMessage(err.response.data.message[0].message)
                })
    
                return updateWithNewImg
                
            } else {
               setAlertError(true)
               setErrorMessage("Please Choose Files Photo")
               setLoading(false)
            }
        }, 1000);
        
    }

    const updateProfileUser = (data) => {
        // console.log(data, 'data update klik')
        setLoading(true)
        setTimeout(async() => {
                
        let updateUser = {
            fullname : data.fullname,
            phone_number : data.phone_number,                    
            bio : data.bio,
            place_bod : data.place_bod,
            birthdays : data.birthday,
            address : data.address,
            gender : data.gender
        }
        
        const update = await Axios.post(`${API}/influencer/profile`, updateUser, {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${TokenUser}`
            }
            })
            .then((res) => {
                setLoading(false)
                setAlertSuccess(true)
                setSuccessMessage(res.data.message)
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }).catch((err) => {
                setLoading(false)
                setAlertError(true)
                setErrorMessage(err.response.data.message[0].message)
            })  

        return update;  
            
        }, 2000);
        
    }

    const [selectedFile1, setSelectedFile1] = useState()
       

    const onSelectFile1 = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile1(undefined)
            return
        }
        setSelectedFile1(e.target.files[0])
    }

    // const errorMessage = () => {
    //     if(errorState) {
    //         return (
    //             <Alert severity="error">
    //                 Profile Picture must be uploaded!
    //             </Alert>
    //         )
    //     }
    // }

    const renderButton = () => {
        if(dataProfile.profile !== null) { 
            return (        
                <Box  className={classes.buttonText}>Upload New Picture </Box>     
            )
           
        } else {
            return (
                <Box  className={classes.buttonText}>Upload</Box>
            )
        }
    }

   const renderImg = () => {
        if(dataProfile.profile !== null) { 
            return (                
                <img src={detailProfile.imageUrl} alt='profile' width='100%' />
            )           
        } else {            
            return (
            <img src={profile} alt='profile' width='100%' />
            )
        }
    }

    

    const renderData = () => {
        if(dataExist) {
            return (
                <div className={classes.rootComponent}>
                    <Grid container>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box id='fontTamma' fontSize={25} color='#343c5a' >
                                <span style={{fontWeight:'bold', color:'#7c71d5'}}>{localStorage.getItem('nama') + ' '}</span>
                                - My Profile
                            </Box>
                        </Grid>

                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box className={classes.lineDivider}></Box>
                        </Grid>

                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box pt={2} id='fontTamma' color='#6e738a' fontSize={18} >
                                Keterangan tentang edit profile
                            </Box>
                        </Grid>

                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box pt={4} id='fontTamma' color='#343c5a' fontWeight={600} fontSize={19} >
                                Profile Picture
                            </Box>
                        </Grid>

                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Grid container direction='row' >
                                <Box pt={1}>
                                    <Avatar className={classes.medium}>
                                        {renderImg()}
                                    </Avatar>
                                </Box>
                                <Box pt={1} pl={3} >
                                    <Box pt={1}>
                                        <input 
                                            accept="image/*" id="icon-button-file" type="file"                      
                                            multiple
                                            onChange={onSelectFile1}
                                        />
                                    </Box>
                                    <Box pt={1} pb={1} id='fontTamma' color='#6e738a' fontSize={17} >
                                        File must be in JPG or PNG format!
                                    </Box>
                                    <Box pt={1}>

                                    <Button 
                                        className={classes.headlineButton} 
                                        variant='contained' color='primary' 
                                        onClick={() => {                                                    
                                                
                                            let data = {
                                                    image : selectedFile1 === undefined ? dataProfile.profile.imageUrl : selectedFile1
                                                }
                                            
                                                updateProfilePhoto(data)
                                            // console.log(data, 'submit button')
                                    }}
                                    > 
                                       { loading ? 
                                            <Box className={classes.loadingCircle} ><CircularProgress color="action" size={23} /></Box>
                                            :
                                            <>
                                            {renderButton()}
                                            </>
                                        }
                                                                    
                                        
                                    </Button>
                                    </Box>
                                    

                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box pt={4} id='fontTamma' color='#343c5a' fontWeight={600} fontSize={19} >
                                About You
                            </Box>
                        </Grid>

                    </Grid>
                    
                    <div className={classes.sectionAbout} >
                        
                        <Form>                                
                            <Grid container >  
                                <Grid item lg={10} md={10} sm={12} xs={12}>
                                
                                <Form.Group controlId="exampleForm.ControlInput12">
                                    <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >FULL NAME</Box></Form.Label>
                                    <Form.Control 
                                        required type="text" 
                                        onChange={handleChange('fullname')}     
                                        defaultValue={`${dataProfile.fullname}`}  
                                    />
                                </Form.Group>
                                {
                                    dataProfile.profile !== null ? 

                                    <Form.Group controlId="exampleForm.ControlInput2">
                                        <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >LOCATION / CITY </Box> </Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            onChange={handleChange('place_bod')} 
                                            required
                                            defaultValue={`${dataProfile.profile.place_bod === null ? '' : dataProfile.profile.place_bod }`}
                                        />
                                    </Form.Group>
                                    : 
                                    <Form.Group controlId="exampleForm.ControlInput4">
                                        <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >LOCATION / CITY </Box> </Form.Label>
                                        <Form.Control required type="text"  onChange={handleChange('place_bod')}  placeholder='Type your location' />
                                    </Form.Group>

                                }
                                
                                {
                                    dataProfile.profile !== null ? 

                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >BIOGRAPHY </Box></Form.Label>
                                        <Form.Control 
                                            as="textarea" rows="3" 
                                            onChange={handleChange('bio')} 
                                            defaultValue={`${dataProfile.profile.bio === null ? '' : dataProfile.profile.bio }`}
                                        />
                                    </Form.Group>
                                    :
                                    <Form.Group controlId="exampleForm.ControlTextarea3">
                                        <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >BIOGRAPHY </Box></Form.Label>
                                        <Form.Control as="textarea" 
                                            onChange={handleChange('bio')}  
                                            placeholder='Brief description for your profile.'
                                            rows="3" 
                                        />
                                    </Form.Group>
                                }
                            
                                {
                                    dataProfile.profile !== null ? 
                                    <>
                                    <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >BIRTHDAY </Box></Form.Label>
                                        <DatePicker
                                            className="form-control"                                 
                                            onChange={handleBirthday}
                                            placeholderText={`${moment(dataProfile.profile.birthdays).subtract(10, 'days').calendar()}`}
                                            selected={updateProfile.birthday}

                                        />
                                    </>

                                    :
                                    <>
                                    <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >BIRTHDAY </Box></Form.Label>
                                        <DatePicker
                                            className="form-control"                                 
                                            onChange={handleBirthday}                                            
                                            selected={updateProfile.birthday}
                                        />
                                    </>

                                }
                                
                                {
                                    dataProfile.profile !== null ?
                                    <Form.Group controlId="formGridState">
                                        <Form.Label><Box id='fontTamma' color='#142461' fontSize={15}>GENDER </Box></Form.Label>
                                        <Form.Control as="select" 
                                            onChange={(e) => setUpdateProfile({...updateProfile, gender : e.target.value })}
                                        >
                                            <option>{dataProfile.profile.gender}</option>
                                            <option value="Male" >Male</option>
                                            <option value="Female">Female</option>
                                        </Form.Control>
                                    </Form.Group>
                                    : 
                                    <Form.Group controlId="formGridState2">
                                        <Form.Label><Box id='fontTamma' color='#142461' fontSize={15}>GENDER </Box></Form.Label>
                                        <Form.Control as="select" 
                                            onChange={(e) => setUpdateProfile({...updateProfile, gender : e.target.value })}
                                        >
                                            <option>Choose Gender</option>
                                            <option value="Male" >Male</option>
                                            <option value="Female">Female</option>
                                        </Form.Control>
                                    </Form.Group>
                                }
                            
                                                            
                                <Box pt={4} id='fontTamma' color='#343c5a' fontWeight={600} fontSize={19} >
                                    Contact Information
                                </Box>

                                <Box id='fontTamma' color='#6e738a' fontSize={18} >
                                    Keterangan kenapa harus menyertakan info kontak
                                </Box>

                                
                                <Form.Group controlId="exampleForm.ControlInput5">
                                    <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >PHONE NUMBER</Box></Form.Label>
                                    <Form.Control 
                                        type="number"
                                        onChange={handleChange('phone_number')} 
                                        required
                                        defaultValue={`${dataProfile.phone_number}`}
                                        />
                                </Form.Group>

                                {
                                    dataProfile.profile !== null ?

                                    <Form.Group controlId="exampleForm.ControlInput6">
                                        <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >ADDRESS </Box> </Form.Label>
                                        <Form.Control required type="text" 
                                            onChange={handleChange('address')} 
                                            defaultValue={`${dataProfile.profile.address}`} 
                                        />
                                    </Form.Group>
                                    : 
                                    <Form.Group controlId="exampleForm.ControlInput7">
                                        <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >ADDRESS </Box> </Form.Label>
                                        <Form.Control 
                                            required type="text" 
                                            onChange={handleChange('address')} 
                                            placeholder='Type your address' 
                                        />
                                    </Form.Group>

                                }
                                
                               <Box pt={2} className={classes.lineDivider}></Box>
                            
                                    <Box pt={4}>
                                    <Grid container direction='row' justify='flex-end'  >
                                        <Button 
                                            className={classes.headlineButton} 
                                            variant='contained' color='primary' 
                                            onClick={() => {                                                     
                                                if(dataProfile.profile !== null) {
                                                    let data = {                                                    
                                                        fullname : updateProfile.fullname === '' ? dataProfile.fullname : updateProfile.fullname ,
                                                        phone_number : updateProfile.phone_number === '' ? dataProfile.phone_number : updateProfile.phone_number,
                                                        place_bod : updateProfile.place_bod === '' ? dataProfile.place_bod : updateProfile.place_bod,
                                                        bio : updateProfile.bio === '' ? dataProfile.bio : updateProfile.bio ,
                                                        address : updateProfile.address === '' ? dataProfile.address : updateProfile.address,
                                                        gender : updateProfile.gender === '' ? dataProfile.gender : updateProfile.gender,
                                                        birthday: updateProfile.birthday === '' ? dataProfile.birthday : moment(updateProfile.birthday).format('YYYY-MM-DD')
                                                    }
                                                    updateProfileUser(data)

                                                } else {
                                                    let data = {                                                    
                                                        fullname : updateProfile.fullname === '' ? dataProfile.fullname : updateProfile.fullname ,
                                                        phone_number : updateProfile.phone_number === '' ? dataProfile.phone_number : updateProfile.phone_number,
                                                        place_bod : updateProfile.place_bod === '' ? '' : updateProfile.place_bod,
                                                        bio : updateProfile.bio === '' ? '' : updateProfile.bio ,
                                                        address : updateProfile.address === '' ? '' : updateProfile.address,
                                                        gender : updateProfile.gender === '' ? '' : updateProfile.gender,
                                                        birthday: updateProfile.birthday === '' ? '' : moment(updateProfile.birthday).format('YYYY-MM-DD')
                                                    }
                                                    updateProfileUser(data)
                                                }
                                            }}
                                        >
                                            { loading ?
                                                <Box className={classes.loadingCircleUpdate} ><CircularProgress color="action" size={23} /></Box>
                                                :
                                                <Box className={classes.buttonText}>Update Profile</Box>
                                            }

                                        </Button>
                                    </Grid>
                                </Box>

                                </Grid>                                
                            </Grid>
                                
                        </Form>
                    </div>
                </div>
            )
        } else {
            return ( 
                <div className={classes.rootComponent}>
                    <Grid container>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <div>
                                <Skeleton variant="text"  height={40} />
                                <Skeleton variant="text" width={300} height={30} />
                                <Box pt={2}></Box>
                                <Skeleton variant="text" width={100} height={20} />
                                <Box pt={2}></Box>
                                <Skeleton variant="circle" width={100} height={100} />
                                <Box pt={2}></Box>
                                <Skeleton variant="text" width={100} height={20} />
                                <Box pt={1}></Box>
                                <Skeleton variant="rect"  height={40} />
                                <Box pt={2}></Box>
                                <Skeleton variant="text" width={100} height={20} />
                                <Box pt={1}></Box>
                                <Skeleton variant="rect"  height={40} />
                                <Box pt={2}></Box>
                                <Skeleton variant="text" width={100} height={20} />
                                <Box pt={1}></Box>
                                <Skeleton variant="rect"  height={120} />
                                <Box pt={2}></Box>
                                <Skeleton variant="text" width={100} height={20} />
                                <Box pt={1}></Box>
                                <Skeleton variant="rect"  height={40} />
                                <Box pt={2}></Box>
                                <Skeleton variant="text" width={100} height={20} />
                                <Box pt={1}></Box>
                                <Skeleton variant="rect"  height={40} />
                                <Box pt={2}></Box>
                            </div>
                        </Grid>
                    </Grid>
                </div>
               
            )
        }
    }
    

    return (

    <React.Fragment>
    <AppBarUser/>   
        <div className={classes.roots}>
        <Snackbar 
            open={alertError} 
            autoHideDuration={2000} 
            onClose={handleCloseError}
            anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}
        >
            <Alert onClose={handleCloseError} severity="error">
            <Box className={classes.textError} >{errorMessage}</Box>
            </Alert>
        </Snackbar>

        <Snackbar 
            open={alertSuccess} 
            autoHideDuration={2000} 
            onClose={handleCloseSuccess}
            anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}
        >
            <Alert onClose={handleCloseSuccess} severity="success">
            <Box className={classes.textError} >{successMessage}</Box>
            </Alert>
        </Snackbar>

        </div>        
        <Container maxWidth='lg'>
            <Grid container spacing={2} >
                <Grid item lg={3} md={3}>
                    <MenuSideUser/>
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12} >
                    {renderData()}
                </Grid>
            </Grid>            
        </Container>
        
    </React.Fragment>
    )
}

export default MyProfile;