import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { Button, Box } from '@material-ui/core';
import sucessimg from '../../assets/img/success.svg'

const useStyles = makeStyles((theme) => ({
    mainFeaturedPost: {
      [theme.breakpoints.up('xs')]: {
        height: 750,
      },
      [theme.breakpoints.up('sm')]: {
        height: 500,
      },
      [theme.breakpoints.up('md')]: {
        height: 600,
      },
      [theme.breakpoints.up('lg')]: {
        height: 800,
      },
      [theme.breakpoints.up('xl')]: {
        height: 1000,
      }
    },
    mainFeaturedPostContent: {
      position: 'relative',      
      [theme.breakpoints.up('xs')]: {
        fontSize: '10px',
        paddingTop: '120px',
        textAlign: 'center'
      },
      [theme.breakpoints.up('sm')]: {
        paddingRight: 0,
        paddingTop: '80px',
        textAlign: 'center'
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: 0,
        paddingTop: '100px',
        textAlign: 'left'
      },
      [theme.breakpoints.up('lg')]: {
        paddingRight: 0,
        paddingTop: '200px',
        textAlign: 'left'
      },
      [theme.breakpoints.up('xl')]: {
        paddingRight: 0,
        paddingTop: '250px',
        textAlign: 'left'
      }
    },
    mainFeaturedPostRight: {
      position: 'relative',      
      [theme.breakpoints.up('xs')]: {
        fontSize: '10px',
        paddingTop: '50px',
        textAlign: 'center'
      },
      [theme.breakpoints.up('sm')]: {
        paddingRight: 0,
        paddingTop: '80px',
        textAlign: 'center'
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: 0,
        paddingTop: '100px',
        textAlign: 'left'
      },
      [theme.breakpoints.up('lg')]: {
        paddingRight: 0,
        paddingTop: '200px',
        textAlign: 'left'
      },
      [theme.breakpoints.up('xl')]: {
        paddingRight: 0,
        paddingTop: '250px',
        textAlign: 'left'
      }
    },
    imgSuccess: {
      [theme.breakpoints.up('xs')]: {
        width: '75%'
      },
      [theme.breakpoints.up('sm')]: {
        width: '75%'
      },
      [theme.breakpoints.up('md')]: {        
        width: '75%'
      },
      [theme.breakpoints.up('lg')]: {
        width: '75%'
      },
      [theme.breakpoints.up('xl')]: {
        width: '80%'
      }
    },
    headlineText : {     
      fontWeight: 'bold',
      fontFamily: 'Baloo Tamma 2',
      paddingBottom: '5px', 
      lineHeight: 1.4,
      color: '#fbfbfb',      
      [theme.breakpoints.up('xs')]: {
        fontSize: '25px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '30px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '35px',
        textAlign: 'left',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '35px',
        textAlign: 'left',
      }
    },
    headlineCamption: {
      color: '#fbfbfb',
      fontFamily: 'Baloo Tamma 2',
      lineHeight: 1.4,
      [theme.breakpoints.up('xs')]: {
        fontSize: '13px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '20px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '24px',
        textAlign: 'left',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '24px',
        textAlign: 'left',
      }
    },
    headlineCamption2: {
        color: '#fbfbfb',
        fontFamily: 'Baloo Tamma 2',
        lineHeight: 1.4,
        [theme.breakpoints.up('xs')]: {
          fontSize: '10px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: '12px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '17px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '20px',
          textAlign: 'left',
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '20px',
          textAlign: 'left',
        }
      },
    headlineButton: {
      textAlign: 'left',
      backgroundColor: '#eae8ff',
      [theme.breakpoints.up('xs')]: {
        borderRadius: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '1px',
        paddingBottom: '1px',
        fontSize:'8px'
      },      
      [theme.breakpoints.up('sm')]: {
        borderRadius: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '1px',
        paddingBottom: '1px'
      },
      [theme.breakpoints.up('md')]: {        
        borderRadius: '30px',
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      [theme.breakpoints.up('lg')]: {
        borderRadius: '30px',
        paddingLeft: '60px',
        paddingRight: '60px',
        paddingTop: '10px',
        paddingBottom: '10px'
      },
    },
    buttonText:{
        color: '#7c71d5',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'capitalize'
    },
    headTextSecond: {
      fontFamily: 'Baloo Tamma 2',      
      fontWeight: 'bold',
      color: '#142461',
      [theme.breakpoints.up('xs')]: {
        fontSize: '25px',
      },      
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '30px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '40px',
      },
    },
    headTitleSecond: {
      color: '#bcc3de',
      [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
      },      
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '20px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '30px',
      },
    },
    
  }));

  
  const SuccessSendEmail = () => {
 
    const classes = useStyles();

    return (
    <Paper id='fontTamma' className='mainFeaturedPostSuccess' >
      <Container maxWidth="lg" className={classes.mainFeaturedPost}>
        
        <Grid container spacing={4} direction='row' >        
          <Grid item lg={5} md={6} sm={6} xs={12}  >
            <div className={classes.mainFeaturedPostContent}>
              
                <Box className={classes.headlineText}  >                  
                  Please check your inbox.
                </Box>

                <Box>
                  <Box className={classes.headlineCamption} >
                  We have sent a link to your email address to reset your password.
                  </Box>
                </Box>
               

                <Box pt={1}>
                  <Box className={classes.headlineCamption2} >
                  If you don't receive it within 5 minutes, please check your spam folder.
                  </Box>
                </Box>

                <Box pt={4}  >
                  <Link href="/">
                    <Button className={classes.headlineButton} variant='contained' color='action' size='large'>
                      <Box className={classes.buttonText}>Home</Box>
                    </Button>
                  </Link>
                </Box>
              
            </div>
          </Grid>
          <Grid item lg={7} md={6} sm={6} xs={12}  >
            <Box className={classes.mainFeaturedPostRight} >
              
              <img alt='two people' className={classes.imgSuccess} src={sucessimg} />
              
            </Box>
          </Grid>
          
        </Grid>
      </Container>
    </Paper>

    )
}

export default SuccessSendEmail;