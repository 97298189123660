import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  mainFeaturedPostContent: {
    position: 'relative',      
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px',
      height: 650,
      paddingTop: '200px',
      paddingBottom: '50px'
    },
    [theme.breakpoints.up('sm')]: {        
      paddingTop: '120px',
      paddingBottom: '100px',
      height: 450,
    },
    [theme.breakpoints.up('md')]: {        
      paddingTop: '200px',
      height: 650,
    },
    [theme.breakpoints.up('lg')]: {        
      paddingTop: '250px',
      height: 750
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '280px',
      height: 970,
    }
  }, 
  buttonaction: {
    background: '#fff',
   
  },
  headlineText : {     
    fontWeight: 'bold',
    fontFamily: 'Baloo Tamma 2',
    paddingBottom: '5px', 
    color: '#142461',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      fontSize: '35px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '35px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '50px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '60px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '70px',
    }
  },
  headlineCamption: {
    color: '#142461',
    textAlign: 'center',
    fontFamily: 'Baloo Tamma 2',
    lineHeight: 1.5,
    [theme.breakpoints.up('xs')]: {
      fontSize: '15px',
      paddingTop: '10px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '25px',
    }
  },
  captiontext: {
    fontFamily: 'Open Sans',
    fontWeight: 100,
    paddingBottom: '17px',
    color: '#fff,'
  },
  headlineButton: {
    backgroundColor: '#7c71d5',
    [theme.breakpoints.up('xs')]: {
      borderRadius: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '8px',
      paddingBottom: '5px',
    },      
    [theme.breakpoints.up('sm')]: {
      borderRadius: '30px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '8px',
      paddingBottom: '5px'
    },
    [theme.breakpoints.up('md')]: {        
      borderRadius: '30px',
      paddingLeft: '40px',
      paddingRight: '40px',
      paddingTop: '13px',
      paddingBottom: '10px'
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '30px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingTop: '18px',
      paddingBottom: '15px'
    },
  },
  buttonText:{
    color: '#fbfbfb',
    fontFamily: 'Baloo Tamma 2',
    fontWeight: 700,
    fontSize: '20px',
    textTransform: 'capitalize'
  },
}));
