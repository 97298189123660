import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Link, withRouter } from 'react-router-dom'
import profile from '../assets/img/profile_img.png'
import { API, TokenUser } from '../config'
import Axios from 'axios'
import Skeleton from '@material-ui/lab/Skeleton';


const isActive = (history, path) => {
    if (history.location.pathname === path) {
        return { color: '#7c71d5', fontWeight: 'bold', marginLeft: '15px'  };
    } else {
        return { color: '#6e738a' };
    }
  };

const useStyles = makeStyles((theme) =>  ({
    root: {
        paddingTop: '150px',
    },
    medium: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    avatarProfile: {
        paddingLeft: '30px'
    },
    menuName: {
        fontSize: '18px',       
        paddingBottom: '4px',
        paddingTop: '4px',       
    },
    menuNameActive: {
        fontSize: '18px',
        color: '#7c71d5',
        paddingBottom: '5px',
        paddingTop: '5px',        
    },
    menuSide: {    
        [theme.breakpoints.up('xs')]: {
          display: 'none'
        },
    
        [theme.breakpoints.up('sm')]: {
          display: 'none'
         
        },
        [theme.breakpoints.up('md')]: {
          display: 'flex'
         
        },
        [theme.breakpoints.up('lg')]: {
          display: 'flex'
          
        },
        [theme.breakpoints.up('xl')]: {
          display: 'flex'
          
        },
      },
}))

const MenuSideUser = ({ history }) => {
    const classes = useStyles();

    const [dataProfile, setDataProfile] = useState([])       
    const [dataExist, setDataExist] = useState(false)    
    const [detailProfile, setDetailProfile] = useState([]) 
    // const [loading, setLoading] = React.useState(false);

    // console.log(loading)
    useEffect(()=>{    
    const getUserProfile = () => {
        // setLoading(true)            
        Axios.get(`${API}/influencer/profile`, {               
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${TokenUser}`
            }
        })
        .then((res) => {
            setDataProfile(res.data.result)
            setDetailProfile(res.data.result.profile)
            setDataExist(true)
            // console.log(res.data, 'response data single')                
            // setLoading(false)

        }).catch((err) => {
            console.log(err)
            // setLoading(false)
        })        
    }
    getUserProfile()
    }, []);

    
    const renderData = () => {
        if(dataExist) {
            return (
                <div className={classes.menuSide}>
                <div className={classes.root} >
                    <Grid className={classes.avatarProfile}>
                        <Avatar className={classes.medium}>
                            { dataProfile.profile !== null ?
                                <img src={detailProfile.imageUrl} alt='profile' width='100%' />
                                :
                                <img src={profile} alt='profile' width='100%' />
                            }
                            
                        </Avatar>
                    </Grid>
                    <Box>
                        <MenuList id="menu-list-grow" >
                            <Link to='/my-profile' >
                                <MenuItem >
                                    <Box pl={2} id='fontTamma' className={classes.menuName} style={isActive(history, "/my-profile")}>Edit Profile</Box>
                                </MenuItem>
                            </Link>
                            {/* 
                            <Link to='/account-setting' >
                                <MenuItem >
                                    <Box pl={2} id='fontTamma' className={classes.menuName} style={isActive(history, "/account-setting")}>Account Settings</Box>
                                </MenuItem>  
                            </Link>
                            */}
                            <Link to='/social-profiles' >
                                <MenuItem >
                                    <Box pl={2} id='fontTamma' className={classes.menuName} style={isActive(history, "/social-profiles")}>Social Profiles</Box>
                                </MenuItem>
                            </Link>
                            <Link to='/my-interest' >
                                <MenuItem >
                                    <Box pl={2} id='fontTamma' className={classes.menuName} style={isActive(history, "/my-interest")}>My Interests</Box>
                                </MenuItem>
                            </Link>
                            <Link to='/pricebook' >
                                <MenuItem >
                                    <Box pl={2} id='fontTamma' className={classes.menuName} style={isActive(history, "/pricebook")}>Price Book</Box>
                                </MenuItem>
                            </Link>
                        </MenuList>
                    </Box>
                </div>
                </div>
            )
        } else {
            return (
                <div className={classes.menuSide}>
                <div className={classes.root} >
                    <Skeleton variant="circle" width={90} height={90} />
                    <Box pt={2}></Box>
                    <Skeleton variant="text"   width={160} height={40} />                    
                    <Box pt={2}></Box>
                    <Skeleton variant="text"   width={160} height={40} />
                    <Box pt={2}></Box>
                    <Skeleton variant="text"   width={160} height={40} />
                    <Box pt={2}></Box>
                    <Skeleton variant="text"   width={160} height={40} />
                    <Box pt={2}></Box>
                    <Skeleton variant="text"   width={160} height={40} />
                </div>
                </div>
            )
        }
    }

  return (
    <>
        {renderData()}
    </>
  );
}

export default withRouter(MenuSideUser);

