import React from 'react';
import AppBarUser from '../../components/AppBarUser';
import NavbarLanding from '../../components/landing/NavbarLanding'
import FooterLanding from '../../components/landing/FooterLanding';
import { 
    makeStyles, 
    Container, 
    Grid, 
    Button,
    Box, 
    Paper ,
    Link
} from '@material-ui/core';
import errorpage from '../../assets/img/errorpage.png'

const useStyles = makeStyles((theme) => ({
    mainFeaturedPost: {
        [theme.breakpoints.up('xs')]: {
          height: 750,
        },
        [theme.breakpoints.up('sm')]: {
          height: 600,
        },
        [theme.breakpoints.up('md')]: {
          height: 700,
        },
        [theme.breakpoints.up('lg')]: {
          height: 800,
        },
        [theme.breakpoints.up('xl')]: {
          height: 1100,
        }
      },
      mainFeaturedPostContent: {
        position: 'relative',      
        [theme.breakpoints.up('xs')]: {
          fontSize: '10px',
          paddingTop: '120px',
          textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
          paddingRight: 0,
          paddingTop: '150px',
          textAlign: 'center'
        },
        [theme.breakpoints.up('md')]: {
          paddingRight: 0,
          paddingTop: '200px',
          textAlign: 'center'
        },
        [theme.breakpoints.up('lg')]: {
          paddingRight: 0,
          paddingTop: '200px',
          textAlign: 'left'
        },
        [theme.breakpoints.up('xl')]: {
          paddingRight: 0,
          paddingTop: '250px',
          textAlign: 'left'
        }
      },
      mainFeaturedPostRight: {
        position: 'relative',      
        [theme.breakpoints.up('xs')]: {
          fontSize: '10px',
          paddingTop: '50px',
          textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
          paddingRight: 0,
          paddingTop: '150px',
          textAlign: 'center'
        },
        [theme.breakpoints.up('md')]: {
          paddingRight: 0,
          paddingTop: '200px',
          textAlign: 'left'
        },
        [theme.breakpoints.up('lg')]: {
          paddingRight: 0,
          paddingTop: '200px',
          textAlign: 'left'
        },
        [theme.breakpoints.up('xl')]: {
          paddingRight: 0,
          paddingTop: '250px',
          textAlign: 'left'
        }
      },
      imgSuccess: {
        [theme.breakpoints.up('xs')]: {
          width: '75%'
        },
        [theme.breakpoints.up('sm')]: {
          width: '75%'
        },
        [theme.breakpoints.up('md')]: {        
          width: '75%'
        },
        [theme.breakpoints.up('lg')]: {
          width: '75%'
        },
        [theme.breakpoints.up('xl')]: {
          width: '80%'
        }
      },
      headlineText : {     
        fontWeight: 'bold',
        fontFamily: 'Baloo Tamma 2',
        paddingBottom: '5px', 
        lineHeight: 1.4,
        color: '#343c5a',      
        [theme.breakpoints.up('xs')]: {
          fontSize: '25px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: '30px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '30px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '45px',
          textAlign: 'left',
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '55px',
          textAlign: 'left',
        }
      },
      headlineCamption: {
        color: '#343c5a',
        fontFamily: 'Baloo Tamma 2',
        lineHeight: 1.4,
        [theme.breakpoints.up('xs')]: {
          fontSize: '13px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: '15px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '15px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '17px',
          textAlign: 'left',
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '20px',
          textAlign: 'left',
        }
      },
      headlineButton: {
        textAlign: 'left',
        backgroundColor: '#7c71d5',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '5px',
          paddingBottom: '5px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '60px',
          paddingRight: '60px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
      },
      buttonText:{
          color: '#fbfbfb',
          fontFamily: 'Baloo Tamma 2',
          fontWeight: 700,
          fontSize: '20px',
          textTransform: 'capitalize'
      },
      headTextSecond: {
        fontFamily: 'Baloo Tamma 2',      
        fontWeight: 'bold',
        color: '#142461',
        [theme.breakpoints.up('xs')]: {
          fontSize: '25px',
        },      
        [theme.breakpoints.up('sm')]: {
          fontSize: '30px',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '40px',
        },
      },
      headTitleSecond: {
        color: '#bcc3de',
        [theme.breakpoints.up('xs')]: {
          fontSize: '20px',
        },      
        [theme.breakpoints.up('sm')]: {
          fontSize: '20px',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '20px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '30px',
        },
      },
})) 

const ErrorPage = () => {
    const classes = useStyles()

    return (
        <React.Fragment>
        { 
            localStorage.getItem('_@tKn_iD&&') ? 
            <AppBarUser/>
            : 
            <NavbarLanding/>
        }
             
        <Paper id='fontTamma'>
            <Container maxWidth="lg" className={classes.mainFeaturedPost}>
                
                <Grid container spacing={4} direction='row' >        
                <Grid item lg={5} md={6} sm={6} xs={12}  >
                    <div className={classes.mainFeaturedPostContent}>
                    
                        <Box className={classes.headlineText}  >
                        Upsss, this page <br/> isn't available
                        </Box>

                        <Box>
                        <Box className={classes.headlineCamption} >
                            The page you're looking for couldn't be found. 404 <br/>
                            You may have mistyped the address or the <br/>
                            page may have moved.
                        </Box>

                        </Box>                

                        <Box pt={4}  >
                        <Link href="/">
                            <Button className={classes.headlineButton}  variant='contained' color='primary' size='large'>
                            <Box className={classes.buttonText}>Back to Home</Box>
                            </Button>
                        </Link>
                        </Box>
                    
                    </div>
                </Grid>
                <Grid item lg={7} md={6} sm={6} xs={12}  >
                    <Box className={classes.mainFeaturedPostRight} >
                    
                    <img alt='two people' className={classes.imgSuccess} src={errorpage} />
                    
                    </Box>
                </Grid>
                
                </Grid>
            </Container>
            </Paper>

        { 
            localStorage.getItem('_@tKn_iD&&') ? 
            ''
            : 
            <FooterLanding/>
        }

        </React.Fragment>
    )
}

export default ErrorPage;