import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    mainFeaturedPost: {
        color: theme.palette.common.white,
        backgroundColor: '#fff',
        border: 'none',
        [theme.breakpoints.up('xs')]: {
          paddingTop: '5px',
          height: 750,
        },
        [theme.breakpoints.up('sm')]: {
          paddingTop: '10px',
          height: 500,
        },
        [theme.breakpoints.up('md')]: {
          paddingTop: '10px',
          height: 600,
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: '30px',
          height: 800,
        },
        [theme.breakpoints.up('xl')]: {
          paddingTop: '30px',
          height: 850,
        }
      },
      mainFeaturedPostContent: {
        position: 'relative',      
        [theme.breakpoints.up('xs')]: {
          fontSize: '10px',
          paddingTop: '30px',
          textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
          paddingRight: 0,
          paddingTop: '80px',
          textAlign: 'center'
        },
        [theme.breakpoints.up('md')]: {
          paddingRight: 0,
          paddingTop: '80px',
          textAlign: 'left'
        },
        [theme.breakpoints.up('lg')]: {
          paddingRight: 0,
          paddingTop: '100px',
          textAlign: 'left'
        }
      },
      headlineText : {     
        fontWeight: 'bold',
        fontFamily: 'Baloo Tamma 2',
        paddingBottom: '5px', 
        lineHeight: 1.4,
        color: '#142461',      
        [theme.breakpoints.up('xs')]: {
          fontSize: '30px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: '32px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '30px',
          textAlign: 'left',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '37px',
          textAlign: 'left',
        }
      },
      headlineCamption: {
        color: '#142461',
        fontFamily: 'Baloo Tamma 2',
        lineHeight: 1.4,
        [theme.breakpoints.up('xs')]: {
          fontSize: '15px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: '15px',
          textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '25px',
          textAlign: 'left',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '24px',
          textAlign: 'left',
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '25px',
          textAlign: 'left',
        }
      },
      headlineButton: {
        textAlign: 'left',
        backgroundColor: '#eae8ff',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '60px',
          paddingRight: '60px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
      },
      buttonText:{
          color: '#7c71d5',
          fontFamily: 'Baloo Tamma 2',
          fontWeight: 700,
          fontSize: '20px',
          textTransform: 'capitalize'
      },
      headTextSecond: {
        fontFamily: 'Baloo Tamma 2',      
        fontWeight: 'bold',
        color: '#142461',
        [theme.breakpoints.up('xs')]: {
          fontSize: '25px',
        },      
        [theme.breakpoints.up('sm')]: {
          fontSize: '30px',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '40px',
        },
      },
      headTitleSecond: {
        color: '#bcc3de',
        [theme.breakpoints.up('xs')]: {
          fontSize: '20px',
        },      
        [theme.breakpoints.up('sm')]: {
          fontSize: '20px',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '20px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '30px',
        },
      },
}));
