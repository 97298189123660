import React, { useState, useEffect } from 'react';
import AppBarUser from '../../components/AppBarUser';
// import FooterDashboard from '../../components/FooterDashboard';
import { 
    Grid, 
    Container, 
    Paper,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TextField,
    Divider
} from '@material-ui/core';
import Axios from "axios";
import { API, TokenUser } from '../../config'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import { FaTimes } from 'react-icons/fa'
import SkeletonTable from '../../components/SkeletonTable'
import {CopyToClipboard} from 'react-copy-to-clipboard';  
import noprogress from '../../assets/img/noprogress.png'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import useStyles from '../../assets/css/progProStyle'
import SkeletonMobile from '../../components/SkeletonMobile'
import norequest from '../../assets/img/norequest.png'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


const ProgressProjects = ({ history }) => {
    
    const classes = useStyles();
    
    const [progressProjects, setProgressProjects] = useState([])     
    const [progressProjectsData, setProgressProjectsData] = useState([])     
    const [dataLoaded, setDataLoaded] = useState(false)    
    
    // console.log(progressProjects, 'data data ')

    const [alertError, setAlertError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [alertSuccess, setAlertSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")

    const [detailProjects, setDetailProjects] = useState({
        dialog: false,
        project_name: '',
        owner_brand: '',
        description: '',
        id:'',
        start_date: '',
        end_date: '',
        link_content: '',
        desc_content: '',
        status: '',
        is_influencer_approved: '',
        confirm_budget: '',
        type_of_post: '',
        content_provide: '',
        expire_date: '',
        payment_type: '',
        comment_brand: ''
    })

    const [detailCaption, setDetailCaption] = useState({
        dialog: false,
        link_content: '', 
        desc_content: '',
        project_name: '',
        owner_brand: '',
        status: '',
        copied: false
    })
    
    const [detailCaptionMobile, setDetailCaptionMobile] = useState({
        dialog: false,
        link_content: '',
        desc_content: '',
        project_name: '',
        owner_brand: '',
        status: '',
        copied: false
    })

    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState('md');
    const [maxWidthMobile] = React.useState('xs');
    // const [maxWidthAttach] = React.useState('sm');


    const [page, setPage] = React.useState(1);

    const handleChange = (event, value) => {
      setPage(value);
      loadprogressProjectsPaginate(value)
    };


    const handleClose = () => {
        setDetailProjects({...detailProjects, dialog: false});
    };

    const handleCloseCaption = () => {
        setDetailCaption({...detailCaption, dialog: false, copied: false});
    };

    const handleCloseCaptionMobile = () => {
        setDetailCaptionMobile({...detailCaptionMobile, dialog: false, copied: false});
    };
    const [updatelink, setUpdateLink] = useState({
        link_post: ''
    })

    const handleChangePost = description => event => {
        setUpdateLink({...updatelink, [description]: event.target.value})
      }

    const loadprogressProjectsPaginate = (data) => {
        console.log(data, 'dataa')

        let page = data

        // setLoading(true)            
        Axios.get(`${API}/influencer/project/list`, {
            method: 'GET',
            params: {
                page: page,
                rows: 10,
                status : ["RUNNING" , "AGREE", "NEGOTIATING", "CONSOLIDATION", "WAITING FOR OWNER",
                            "VERIFY CONTENT", "REQUIRE REPOST CONTENT" ]
            },
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${TokenUser}`
            }
        })
        .then((res) => {
            setProgressProjects(res.data.data)
            setProgressProjectsData(res.data)
            setDataLoaded(true)
            // console.log(res.data, 'response data single')                
            // setLoading(false)

        }).catch((err) => {
            console.log(err)
            // setLoading(false)
        })        
    }

    
    useEffect(()=>{    

        const loadprogressProjects = () => {
            // setLoading(true)            
            Axios.get(`${API}/influencer/project/list`, {
                method: 'GET',
                params: {
                    page: 1,
                    rows: 10,
                    status : ["RUNNING" , "AGREE", "NEGOTIATING", "CONSOLIDATION", "WAITING FOR OWNER",
                            "VERIFY CONTENT", "REQUIRE REPOST CONTENT" ]
                },
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": `Bearer ${TokenUser}`
                }
            })
            .then((res) => {
                setProgressProjects(res.data.data)
                setProgressProjectsData(res.data)
                setDataLoaded(true)
                // console.log(res.data, 'response data single')                
                // setLoading(false)

            }).catch((err) => {
                console.log(err)
                // setLoading(false)
            })        
        }
        loadprogressProjects()

      }, []);


     const [loading, setLoading] = React.useState(false);


     const onClickupdatelink = async (data) => {
        // console.log(data, 'data update content')
        // setErrorPostLink(false)
       
        let dataBody = {
         order_influencer_id : detailProjects.id,
         link_content : data.link_post
       }
 
       console.log(dataBody, 'data bod')
 
       setLoading(true)
 
       const postLink = await Axios.post(`${API}/influencer/project/content`, dataBody, {            
             headers: {
                 "Content-type": "application/json; charset=UTF-8",
                 "Authorization": `Bearer ${TokenUser}`
             }
         })
         .then((res) => {
            //  console.log(res.data, 'response data single')                
            setDetailProjects({...detailProjects, dialog: false})
            setLoading(false)
            setSuccessMessage(res.data.message)
            setAlertSuccess(true)           
            setTimeout(() => {
                window.location.reload()
            }, 2000);
 
         }).catch((err) => {
            setDetailProjects({...detailProjects, dialog: false})
            if(err.response.status === 500) {
                setErrorMessage(err.response.data.error.message)
                setAlertError(true)
                // setTimeout(() => {
                //     window.location.reload()
                // }, 2000);
            } else if (err.response.status === 400) {
                setErrorMessage(err.response.data.message)
                setAlertError(true)
                // setTimeout(() => {
                //     window.location.reload()
                // }, 2000);
            }
            setLoading(false)
         })  
         return postLink;
 
    }

    const onClickupdatelinkMobile = async (data) => {
      
        let dataBody = {
         order_influencer_id : data.id,
         link_content : data.link_post
       }
 
       setLoading(true)
 
       const postLink = await Axios.post(`${API}/influencer/project/content`, dataBody, {            
             headers: {
                 "Content-type": "application/json; charset=UTF-8",
                 "Authorization": `Bearer ${TokenUser}`
             }
         })
         .then((res) => {
            setLoading(false)
            setSuccessMessage(res.data.message)
            setAlertSuccess(true)           
            setTimeout(() => {
                window.location.reload()
            }, 2000);
 
         }).catch((err) => {
            if(err.response.status === 500) {
                setErrorMessage(err.response.data.error.message)
                setAlertError(true)
            } else if (err.response.status === 400) {
                setErrorMessage(err.response.data.message)
                setAlertError(true)
            }
            setLoading(false)
         })  
         return postLink;
 
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertError(false);
      };
    
    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertSuccess(false);
      };
      
    const renderPaginate = () => {
        if(dataLoaded) {
            if(progressProjects.length > 10) {
                return (                    
                    <Pagination count={progressProjectsData.lastPage} page={page} onChange={handleChange} />
                )
            }
        }
    }

    const renderDataProject = () => {
        if(dataLoaded) {
            if(progressProjects.length !== 0) {
                return (
                  <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                      <TableRow   >
                          <TableCell id='fontTamma' width='10px' className={classes.titlerow} ></TableCell>
                          <TableCell id='fontTamma' className={classes.titlerow}>PROJECT</TableCell>
                          <TableCell id='fontTamma' className={classes.titlerow}>BRAND</TableCell>
                          <TableCell id='fontTamma' className={classes.titlerow}>DATE</TableCell>
                          <TableCell id='fontTamma' className={classes.titlerow}>TYPE</TableCell>
                          <TableCell id='fontTamma' className={classes.titlerow}>STATUS</TableCell>
                          <TableCell id='fontTamma' className={classes.titlerow}>ACTION</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {progressProjects.map((data, index) => (                    
                      <TableRow >
                          <TableCell id='fontTamma' className={classes.titleCell} >

                            { progressProjectsData.page > 1 ? 
                                <>
                                { index + 1 + (progressProjectsData.perPage  * (progressProjectsData.page - 1) ) }
                                </>

                                : 

                                <>
                                {index  + 1}
                                </>

                            }
                          
                          </TableCell>
                          <TableCell id='fontTamma' className={classes.titleCell} >
                              {data.order.project_name}
                          </TableCell>
                          <TableCell id='fontTamma' className={classes.titleCell} >{data.order.owner_brand}</TableCell>
                          <TableCell id='fontTamma' className={classes.titleCell} >
                            {moment(data.start_date).format('DD MMMM YYYY')}
                          </TableCell>
                          <TableCell id='fontTamma' className={classes.titleCell} >
                            {data.order.payment_type}
                          </TableCell>
                          <TableCell id='fontTamma' className={classes.titleCell} >                          
                            <button className={classes.buttonStatus}>
                                <Box className={classes.textStatus}>
                                { data.status }
                                </Box>
                            </button> 
                              
                          </TableCell>
                          <TableCell >   
                              <Button 
                                  className={classes.headlineButton}
                                  disabled={ data.status !== 'RUNNING' && data.status !== 'VERIFY CONTENT' ? false : true}
                                  variant='contained' 
                                  color='primary' 
                                  onClick={() => {
                                      setDetailProjects({...detailProjects, 
                                        dialog: true,
                                        project_name: data.order.project_name,
                                        owner_brand: data.order.owner_brand,
                                        description:  data.order.order_desc,
                                        id: data.id,
                                        start_date: data.start_date ,
                                        end_date: data.end_date ,
                                        desc_content: data.order.description,
                                        link_content: data.order.link_content,
                                        status: data.status,
                                        is_influencer_approved: data.is_influencer_approved,
                                        confirm_budget : data.confirm_budget,
                                        content_provide : data.order.content_by_influencer,
                                        expire_date: data.order.expire_date,
                                        type_of_post: data.order.platform_child.name,
                                        payment_type: data.order.payment_type,
                                        comment_brand : data.content.length === 0 ? 'null' : data.content[0].link_comment 

                                          // is_influencer_approved: 'false'
  
                                      })
                                  }} 
                              >
                                  <Box className={classes.buttonText}>Update Link</Box>
                              </Button> 
                                                                                       
                          </TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
                  </Table>
                  
                )
            } else {
                return ( 
                
                <Grid container direction='column' alignItems='center'  >
                    <Box className={classes.sectionNoData}>
                        <Box textAlign='center'>
                            <img src={noprogress} alt='icon' className={classes.imgStyle} />
                        </Box>

                        <Box id='fontTamma' textAlign='center' pt={5} >
                            You don't have any progress yet.
                        </Box>
                    </Box>
                </Grid>
                    
                )
            }
        } else {
            return (
                <SkeletonTable/>
            )
        }
    }

    const [expanded, setExpanded] = React.useState(false);

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [errPost, setErrPost] = useState(false)

    const errorPost = () => {
        if(errPost) {
            return <Box id='fontTamma' fontSize={13} pt={1} color="red">Please fill out this field!</Box>
        }
    }

    const [errPostDesktop, setErrPostDesktop] = useState(false)
    
    const errorPostDesktop = () => {
        if(errPostDesktop) {
            return <Box id='fontTamma' fontSize={13} pt={1} color="red">Please fill out this field!</Box>
        }
    }
    // console.log(progressProjects)

    const renderDataProjectMobile = () => {
        if(dataLoaded) {
            if(progressProjects.length !== 0) {
                return (
                <>
                 {progressProjects.map((data, index) => (

                <>
                 <Box pb={2}>                            
                    <Accordion key={index} expanded={expanded === `${data.id}`} onChange={handleChangeAccordion(`${data.id}`)} >
                        <AccordionSummary                           
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Grid container direction="column" justify="left" alignItems="left" >
                                <Grid container direction="row" justify="space-between" style={{paddingBottom: '10px'}}>
                                    <Box id='fontTamma' color="#b5c3f3" fontSize={15} >
                                    { 
                                        data.status === 'WAITING FOR INFLUENCER' ? 'Waiting' :
                                        data.status === 'REJECTED BY INFLUENCER' ? 'Rejected' :
                                        data.status
                                    }
                                    </Box>
                                    <Box className={classes.socialMedia} >
                                        <Box id='fontTamma' color="#9e69d2" fontSize={13} style={{paddingTop: '3px'}} >
                                            { data.order.platform_child === null ? '' : data.order.platform_child.name}
                                        </Box>
                                    </Box>
                                </Grid>

                                <Divider />

                                <Box pt={1} id='fontTamma' fontSize={18} color='#7c71d5'  fontWeight='bold'>
                                   {data.order.project_name}
                                </Box>
                                <Box pb={1} id='fontTamma' fontSize={15}>
                                    By {data.order.owner_brand} 
                                </Box>


                                { data.status !== 'RUNNING' && data.status !== 'VERIFY CONTENT' ? 
                                <>
                                <Divider/>
                                <Box pt={2} id='fontTamma' color='#6e738a' fontSize={13}  >
                                    UPDATE LINK 
                                </Box>
                                <Box >
                                
                                <Grid container spacing={2} className={classes.updateSection}>
                                    <Grid item sm={9} xs={9}>
                                        <TextField                     
                                            label="Link Content Post"
                                            onChange={handleChangePost('link_post')}  
                                            style={{backgroundColor:'#fff'}}
                                            size='small'
                                            variant="outlined"
                                            fullWidth                                            
                                        />
                                        {errorPost()}
                                    </Grid>

                                    <Grid item sm={3} xs={3}>
                                        <Button 
                                            className={classes.headlineButton}
                                            variant='contained' 
                                            color='primary'
                                            onClick={() => {
                                                if(updatelink.link_post === "") {
                                                    setErrPost(true)
                                                    setTimeout(() => {
                                                        setErrPost(false)
                                                    }, 4000);
                                                } else {
                                                    let data_post = {
                                                        id : data.id,
                                                        link_post : updatelink.link_post
                                                    }
                                                    onClickupdatelinkMobile(data_post)
                                                    setErrPost(false)
                                                }
                                            }}  
                                        >
                                            <Box className={classes.buttonText}>Update</Box>
                                        </Button> 
                                    </Grid>
                                </Grid>
                                   
                                </Box> 
                                {/* <Box pt={1} pb={1} ><Divider /></Box> */}
                                </>

                                : ""
                                }     

                                 
                            </Grid>

                        </AccordionSummary>

                        <AccordionDetails>
                        <Grid container direction="column" justify="left" alignItems="left" >                     


                                {data.content.length === 0 ? '' : 
                                <>
                                <Box style={{backgroundColor: '#e2dff4'}}>    
                                    { data.content[0].link_comment === null ? "" :  
                                    <>                              
                                    <Box pt={1} pl={1} pr={1} id='fontTamma' color='#6e738a' fontSize={14}  >
                                        MESSAGE FROM BRAND 
                                    </Box>
                                    <Box pl={1} pb={1} pr={1} id='fontTamma' fontSize={16} fontWeight="reguler" >
                                        {data.content[0].link_comment }
                                    </Box>  
                                    </>
                                    }                                    
                                </Box>    
                                </>
                                }                  
                                {data.content.length === 0 ? '' : 
                                    <Box pt={1} pb={1} ><Divider /></Box>
                                }
                                
                                <Box id='fontTamma' color='#6e738a' fontSize={14}  >
                                    CONTENT PROVIDER 
                                </Box>
                                <Box id='fontTamma' fontSize={16} fontWeight="reguler" >
                                    {data.order.content_by_influencer === 'false' ? 
                                        'Brand' : 'Influencer'
                                    }
                                </Box>
                                <Box pt={1} pb={1} ><Divider /></Box>
                                <Box id='fontTamma' color='#6e738a' fontSize={14}  >
                                    TYPE OF POST 
                                </Box>
                                <Box id='fontTamma' fontSize={16} >
                                    { data.order.platform_child === null ? '' : data.order.platform_child.name}
                                </Box>
                                <Box pt={1} pb={1} ><Divider /></Box>
                                <Box id='fontTamma' color='#6e738a' fontSize={14}  >
                                    BUDGET 
                                </Box>
                                <Box id='fontTamma' fontSize={16} >
                                    Rp.{data.order.confirm_budget.toLocaleString()}
                                </Box>
                                <Box pt={1} pb={1} ><Divider /></Box>
                                <Box id='fontTamma' color='#6e738a' fontSize={14}  >
                                    CONTENT 
                                </Box>
                                
                                <Box id='fontTamma' color='#6e738a' fontSize={14} pt={1} pl={2} >
                                     PHOTOS OR VIDEOS
                                </Box>
                                <Box pl={2} pt={1} pb={1}>
                                    <Box  >
                                        <img src={data.order.link_content} width="50px"
                                        alt="pho captions" 
                                        style={{borderRadius: '6px'}}
                                        />
                                    </Box>
                                    <Box id='fontTamma' 
                                        fontSize={14} pt={1} fontWeight='bold' color='#7c71d5' 
                                        onClick={() => {
                                            setDetailCaptionMobile({...detailCaptionMobile, 
                                                dialog: true,
                                                project_name: data.order.project_name,
                                                owner_brand: data.order.owner_brand,
                                                desc_content: data.order.description,
                                                link_content: data.order.link_content,
                                                // status: detailProjects.status
                                            })                                           
                                        }}
                                    >
                                     View Captions
                                    </Box>
                                </Box>
                                <Box id='fontTamma' color='#6e738a' fontSize={14} pl={2} >
                                     DESCRIPTION
                                </Box>
                                <Box id='fontTamma' fontSize={14} pl={2} >
                                    {data.order.order_desc} 
                                </Box>

                                <Box pt={1} pb={1} ><Divider /></Box>
                                <Box id='fontTamma' color='#6e738a' fontSize={14}  >
                                    SCHEDULE 
                                </Box>
                                <Box id='fontTamma' color='#6e738a' fontSize={12} pl={2} >
                                    START DATE 
                                </Box>
                                <Box id='fontTamma' fontSize={15} pl={2} >
                                {moment(data.start_date).format('DD MMMM YYYY ')}
                                </Box>

                                <Box pt={2} id='fontTamma' color='#6e738a' fontSize={12} pl={2} >
                                    END DATE 
                                </Box>
                                <Box id='fontTamma' fontSize={15} pl={2}  >
                                {moment(data.end_date).format('DD MMMM YYYY ')}
                                </Box>
                                <Box pt={2} id='fontTamma' color='#6e738a' fontSize={12} pl={2} >
                                    EXPIRED DATE 
                                </Box>
                                <Box id='fontTamma' fontSize={15} pl={2} pb={1} >
                                {moment(data.order.expire_date).format('DD MMMM YYYY ')}
                                </Box>
                                
                                
                                <Box pt={1} pb={1} ><Divider /></Box>

                                <Box textAlign="center" 
                                    pt={1} id='fontTamma' 
                                    fontWeight='bold' fontSize={14} color='#7c71d5'  
                                    onClick={handleChangeAccordion(`${data.id}`)}>                                   
                                   Close Details <ExpandLessIcon />
                                </Box>  
                                
                        </Grid>
                        </AccordionDetails>
                    </Accordion>   
                    </Box>
                    
                    </> 
                   
                 ))}

                </>
                )
            } else {
                return ( 
                    <Grid container direction='column' alignItems='center'  >
                        <Box className={classes.sectionNoData}>
                            <Box textAlign='center'>
                                <img src={norequest} alt='icon' className={classes.imgStyle} />
                            </Box>

                            <Box id='fontTamma' textAlign='center' pt={5} >
                                You don't have any request.
                            </Box>
                        </Box>
                    </Grid>
                )
            }
        } else {
            return (
                <SkeletonMobile/>
            )
        }
    }

    return (
        <React.Fragment>
           <AppBarUser/>

           <div className={classes.root}>
                <Snackbar 
                    open={alertError} 
                    autoHideDuration={2000} 
                    onClose={handleCloseError}
                    anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}
                >
                    <Alert onClose={handleCloseError} severity="error">
                    <Box className={classes.textError} >{errorMessage}</Box>
                    </Alert>
                </Snackbar>

                <Snackbar 
                    open={alertSuccess} 
                    autoHideDuration={2000} 
                    onClose={handleCloseSuccess}
                    anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}
                >
                    <Alert onClose={handleCloseSuccess} severity="success">
                    <Box className={classes.textError} >{successMessage}</Box>
                    </Alert>
                </Snackbar>

            </div>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

           <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={detailProjects.dialog}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"    
                className={classes.maxHeight} 
                scroll="body"
                                 
            >
                <Grid container direction='row' justify='flex-end' >
                    <Box onClick={handleClose} pt={2} pr={2} style={{cursor:'pointer'}}><FaTimes/></Box>
                </Grid>
                <DialogContent style={{backgroundColor: '#fbfbfb'}} >
               
                <Grid container spacing={5} >                    
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        
                            <Box p={4} mb={4}>

                                <Grid container direction='row' justify='flex-start' alignItems='center'>
                                    <div >
                                        <Box id='fontTamma' fontSize={25} color='#7c71d5' fontWeight='bold'  style={{textTransform: 'capitalize'}} >
                                            {detailProjects.project_name}
                                        </Box>      
                                        <Box id='fontTamma' color='#6e738a' fontSize={18} >
                                            by {detailProjects.owner_brand}
                                        </Box>                                 
                                    </div>
                                </Grid>

                                <Box pt={2}></Box> 

                                {
                                    detailProjects.comment_brand === 'null' ? '' :
                                    <Grid container>
                                        <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                            <Box id='fontTamma' className={classes.titleLeft} >
                                                MESSAGE FROM BRAND 
                                            </Box>
                                        </Grid>
                                        <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                            <Box id='fontTamma' className={classes.contentRight} style={{textTransform: 'capitalize'}} >
                                                {detailProjects.comment_brand}
                                            </Box>
                                        </Grid>                               
                                    </Grid>
                                    
                                } 

                                <Box pt={2}></Box> 

                                <Grid container spacing={2} className={classes.updateSectionbg}  >
                                    <Grid item lg={3} md={3} sm={3} xs={3}>
                                        <Box id='fontTamma' className={classes.titleLeft3} >
                                            Update Link
                                        </Box>
                                    </Grid>

                                    <Grid item lg={9} md={9} sm={9} xs={9}>
                                        <Grid container spacing={2} className={classes.updateSection}>
                                           <Grid item lg={9} md={9} sm={9} xs={12}>
                                                <TextField                     
                                                    label="Link Content Post"
                                                    onChange={handleChangePost('link_post')}  
                                                    style={{backgroundColor:'#fff'}}
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                                {errorPostDesktop()}
                                            </Grid>

                                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                                <Button 
                                                    className={classes.headlineButton}
                                                    variant='contained' 
                                                    color='primary' 
                                                    onClick={() => {
                                                        if(updatelink.link_post === "") {
                                                            setErrPostDesktop(true)
                                                            setTimeout(() => {
                                                                setErrPostDesktop(false)
                                                            }, 4000);
                                                        } else {
                                                            let data = {
                                                                link_post : updatelink.link_post
                                                            }
                                                            onClickupdatelink(data)
                                                            setErrPost(false)
                                                        }
                                                    }}
                                                    // onClick={() => {
                                                    //     let data = {
                                                    //         link_post : updatelink.link_post
                                                    //     }
                                                    //     onClickupdatelink(data)
                                                    // }}  
                                                >
                                                    <Box className={classes.buttonText}>Update</Box>
                                                </Button> 
                                            </Grid>
                                        </Grid>  
                                    </Grid>
                                    
                                </Grid> 

                                                                    
                                <Grid container>
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft} >
                                            Campaign Name
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box id='fontTamma' className={classes.contentRight} style={{textTransform: 'capitalize'}} >
                                            {detailProjects.project_name}
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Divider />

                                <Grid container>
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft} >
                                            Content Provider
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box id='fontTamma' className={classes.contentRight} >
                                            {detailProjects.content_provide === 'false' ? 
                                            'Brand' : 'Influencer'
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Divider />

                                <Grid container>
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft} >
                                            Type of Post
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box id='fontTamma' className={classes.contentRight} >
                                            {detailProjects.type_of_post}
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Divider />

                                <Grid container>
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft} >
                                            Budget
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box id='fontTamma' className={classes.contentRight} >
                                            Rp.{detailProjects.confirm_budget.toLocaleString()}
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Divider />

                                <Grid container>
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft} >
                                            Content
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box id='fontTamma' className={classes.contentRight} >
                                            By Post
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft2} >
                                            Photos or Videos
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                    <Box>
                                        <img src={detailProjects.link_content} alt='img content' 
                                            className={classes.contengImg}
                                        /> 
                                    </Box>
                                    <Box 
                                        className={classes.ViewCaption} 
                                        pt={2} id='fontTamma' 
                                        onClick={() => {
                                            setDetailCaption({...detailCaption, 
                                                dialog: true,
                                                project_name: detailProjects.project_name,
                                                owner_brand: detailProjects.owner_brand,
                                                desc_content: detailProjects.desc_content,
                                                link_content: detailProjects.link_content,
                                                status: detailProjects.status
                                            })
                                            setDetailProjects({...detailProjects, 
                                                dialog: false,
                                            })
                                        }} 
                                    >
                                    View Captions</Box> 
                                    </Grid>
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft2} >
                                            Description
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box id='fontTamma' className={classes.contentRight2} >
                                         {detailProjects.description}
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Divider />

                                <Grid container>
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft} >
                                            Schedule 
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box id='fontTamma' className={classes.contentRight} >
                                            
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft2} >
                                            Start date 
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box id='fontTamma' className={classes.contentRight2} >
                                        {moment(detailProjects.start_date).format('DD MMMM YYYY ')}
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft2} >
                                            End date 
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box id='fontTamma' className={classes.contentRight2} >
                                        {moment(detailProjects.end_date).format('DD MMMM YYYY ')}
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft2} >
                                            Expired date 
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box id='fontTamma' className={classes.contentRight2} >
                                        {moment(detailProjects.expire_date).format('DD MMMM YYYY ')}
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Divider />
                                
                            </Box>
                    </Grid>
                </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={detailCaption.dialog}
                onClose={handleCloseCaption}
                aria-labelledby="max-width-dialog-title" 
                scroll="body" 
            >
                <Grid container direction='row' justify='flex-end' >
                    <Box onClick={handleCloseCaption} pt={2} pr={2} style={{cursor:'pointer'}}><FaTimes/></Box>
                </Grid>
                <DialogContent style={{backgroundColor: '#fbfbfb'}} >               
                <Grid container spacing={5} >                    
                    <Grid item lg={12} md={12} sm={12} xs={12}>                        
                    <Box p={2} mb={2}>
                                <Grid container direction='row' justify='space-between' alignItems='center'>
                                    <div >
                                        <Box id='fontTamma' fontSize={25} color='#7c71d5' fontWeight='bold' style={{textTransform: 'capitalize'}} >
                                            {detailCaption.project_name}
                                        </Box>      
                                        <Box id='fontTamma' color='#6e738a' fontSize={18} >
                                            by {detailCaption.owner_brand}
                                        </Box>                                 
                                    </div>
                                </Grid>

                                <Box pt={4}></Box>

                                <Grid container>
                                    
                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft} >
                                            Photos or Videos
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box>
                                            <img src={detailCaption.link_content} 
                                                alt='img content' className={classes.contengImg} />
                                            
                                        </Box>
                                        <a rel="noopener noreferrer" target='_blank' href={detailCaption.link_content}>
                                            <Box className={classes.ViewCaption} pt={2} id='fontTamma' >
                                                Download Image
                                            </Box> 
                                        </a>
                                    </Grid>

                                    <Grid item lg={3} xl={3} md={3} sm={4} xs={6}>
                                        <Box id='fontTamma' className={classes.titleLeft} >
                                            Captions
                                        </Box>
                                    </Grid>
                                    <Grid item lg={9} xl={9} md={9} sm={8} xs={6}>
                                        <Box pl={2}pr={2} className={classes.boxCaptions}>
                                            <Box id='fontTamma' className={classes.contentRight} >
                                            {detailCaption.desc_content }
                                            
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>

                                
                                <Box mt={4} mr={1} mb={4} >
                                    <Grid container direction='row' justify='space-between'>
                                        <Box 
                                            id='fontTamma' fontSize={17} color='#7c71d5' fontWeight='bold'
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                setDetailCaption({...detailCaption, 
                                                    dialog: false,
                                                    copied: false
                                                })
                                                setDetailProjects({...detailProjects, 
                                                    dialog: true,
                                                })
                                            }} 
                                        >
                                            Back to Project Details
                                        </Box>   

                                        <Box pt={2} pb={2}>
                                            <CopyToClipboard text={detailCaption.desc_content}
                                                onCopy={() => setDetailCaption({
                                                ...detailCaption, copied: true
                                                })}
                                            >
                                                <Button 
                                                    className={classes.headlineButton}
                                                    variant='contained' 
                                                    color='primary' 
                                                    fullWidth
                                                >
                                                    <Box    
                                                        className={classes.buttonText}
                                                    >
                                                        { detailCaption.copied ? 
                                                            'Copied' : 'Copy Caption'
                                                        }
                                                        
                                                    </Box>
                                                </Button> 
                                            </CopyToClipboard>                                        
                                        </Box>

                                                 
                                    </Grid>
                                    
                                    
                                </Box>
                            </Box>
                    </Grid>
                </Grid>
                </DialogContent>
            </Dialog>

            {/* START DIALOG CAOPTION FOR MOBILE ONLY */}
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidthMobile}
                open={detailCaptionMobile.dialog}
                onClose={handleCloseCaptionMobile}
                aria-labelledby="max-width-dialog-title"  
                scroll="body"
            >
                <Grid container direction='row' justify='flex-end' >
                    <Box onClick={handleCloseCaptionMobile} pt={2} pr={2} style={{cursor:'pointer'}}><FaTimes/></Box>
                </Grid>
                <DialogContent style={{backgroundColor: '#fbfbfb'}} >  

                <Box id='fontTamma' fontSize={24} color='#7c71d5' fontWeight='bold' style={{textTransform: 'capitalize'}} >
                    {detailCaptionMobile.project_name}
                </Box> 

                <Box id='fontTamma' color='#6e738a' fontSize={14} >
                    By {detailCaptionMobile.owner_brand}
                </Box> 

                <Box pt={1} pb={1}><Divider/></Box>

                <Box id='fontTamma' color='#6e738a' fontSize={15} >
                    PHOTOS OR VIDEOS
                </Box>

                <Box pt={1} pb={2} >
                    <Grid container direction="row" justify="left" >
                        
                        <Box>
                            <img 
                                src={detailCaptionMobile.link_content} 
                                width="80px"
                                alt="pho captions" 
                                style={{borderRadius: '6px'}}
                            />
                            <a rel="noopener noreferrer" target='_blank' href={detailCaptionMobile.link_content}>
                                <Box pt={1} 
                                    id='fontTamma' color='#7c71d5' 
                                    fontSize={14} 
                                    textAlign="center"
                                >Download</Box>
                            </a>
                            
                        </Box>

                    </Grid>
                </Box>

                <Box id='fontTamma' color='#6e738a' fontSize={15} >
                    CAPTIONS
                </Box>

                <Box className={classes.boxCaptions}>
                    <Box pl={1} pr={1} id='fontTamma' className={classes.contentRightMobile} >
                        {detailCaptionMobile.desc_content }                    
                    </Box>
                </Box>
                
                <Box pt={2} pb={2}>

                <CopyToClipboard text={detailCaptionMobile.desc_content}
                    onCopy={() => setDetailCaptionMobile({
                    ...detailCaptionMobile, copied: true
                    })}
                >
                    <Button 
                        className={classes.headlineButton}
                        fullWidth
                        variant='contained' 
                        color='primary' 
                    >
                        <Box    
                            className={classes.buttonText}
                        >
                            { detailCaptionMobile.copied ? 
                                'Copied' : 'Copy Caption'
                            }
                            
                        </Box>
                    </Button> 
                </CopyToClipboard>
                </Box>                                        
               

                <Box 
                    id='fontTamma' fontSize={14} color='#7c71d5' textAlign="center" fontWeight='bold'
                    style={{cursor: 'pointer', paddingBottom: '10px'}}
                    onClick={handleCloseCaptionMobile} 
                >
                    Back to Project Details
                </Box>  

                </DialogContent>
            </Dialog>
            {/* END DIALOG CAOPTION FOR MOBILE ONLY */}

            <div>
            <Container maxWidth='lg' className={classes.rootContainer} >
                <Grid container spacing={5} className={classes.root} >                    
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box pb={3}>
                            <Box id='fontTamma' fontSize={25} color='#7c71d5' fontWeight='bold' >
                                Projects Progess 
                            </Box>

                            <Box className={classes.lineDivider}></Box>

                            <Box pt={1}  id='fontTamma' color='#6e738a' fontSize={18} >
                                Here is your Projects Progess Data
                            </Box>
                                                        
                        </Box>

                        <Paper elevation={3} className={classes.paperproject}>
                            <Box p={2}>
                                
                                {renderDataProject()}                                        
                                
                            </Box>
                            <Grid container direction='row' justify='flex-end' >
                                <Box pt={2} pb={4} pr={6} textAlign='center' >
                                    {renderPaginate()}
                                </Box>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth='lg' className={classes.displayMobile}   >
                <Grid container spacing={5} className={classes.root} >                    
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box pb={1}>
                            <Box id='fontTamma' fontSize={24} color='#7c71d5' fontWeight='bold' >
                                Project Progess 
                            </Box>

                            <Box className={classes.lineDivider}></Box>
                            
                                                        
                        </Box>

                        <Box>
                            {renderDataProjectMobile}
                        </Box>

                        <Grid container direction='row' justify='center' >
                            <Box pt={2} pb={4}  textAlign='center' >
                                {renderPaginate()}
                            </Box>
                        </Grid>

                    </Grid>
                </Grid>
            </Container>
           {/* <FooterDashboard/> */}
           </div>
        </React.Fragment>
    )
}

export default ProgressProjects;