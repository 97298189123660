import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { 
  AppBar, 
  Typography,
  Toolbar, 
  Container,
  Button,
  Drawer,
  List,
  Divider,
  ListItem,
  Box,
  Link
  
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import kolia_color from './../../assets/img/kolia_color.png'
import { withRouter } from 'react-router-dom'
import useStyles from './style/styleNavbar'

const isActive = (history, path) => {
  if (history.location.pathname === path) {
      return { backgroundColor: '#dfd9f2', borderRadius: '20px', color:'#7c71d5' };
  } else {
      return {  };
  } 
};



const NavbarLanding = ({ history }) => {
  const classes = useStyles();

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (window.scrollY < 70) {
        setHeaderBackground('transparent')
      } else if (window.scrollY < 100) {
        setHeaderBackground('opacity25%')
      } else if (window.scrollY < 200) {
        setHeaderBackground('opacity50%')
      } else if (window.scrollY < 225) {
        setHeaderBackground('opacity60%')
      } else if (window.scrollY < 250) {
        setHeaderBackground('opacity75%')
      } else if (window.scrollY < 300) {
        setHeaderBackground('opacity80%')
      } else if (window.scrollY < 400) {
        setHeaderBackground('opacity90%')
      } else{
        setHeaderBackground('action')
      }
    })
  })

  const [headerBackground, setHeaderBackground] = useState('transparent')

  const [state, setState] = React.useState({
    top: false,
    left: false, 
    
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      id='fontTamma'
    >
      <List style={{ justifyContent: 'center' }}>
          <a href='/'>
          <ListItem className={classes.center}>
              <img alt="logo" src={kolia_color} width='130px'  />
          </ListItem>
          </a>
          
      </List>
      <Divider />
      <List>
                
        <Link href='/about' >
            <ListItem  >
              
            <Button  className={classes.buttonwidth}><Box id='fontTamma' >About Us</Box></Button>

            </ListItem>
        </Link>
        
        <Link href='/portfolio' >
            <ListItem  >
            <Button  className={classes.buttonwidth}><Box id='fontTamma'>Portfolio</Box></Button>
        
            </ListItem>
        </Link>
        
        <Link href='/benefit' >
            <ListItem  >
            <Button   className={classes.buttonwidth}><Box id='fontTamma'>Benefit</Box></Button>
            </ListItem>
        </Link>

        <Link href='/register' >
            <ListItem  >
            <Button   className={classes.buttonwidth}><Box id='fontTamma'>Sign Up</Box></Button>
            </ListItem>
        </Link>
        
              
      </List>
    </div>
  );

  return (

  <React.Fragment>

  {['left'].map((anchor) => (
    <React.Fragment key={anchor}>
      
      <Drawer anchor={anchor} open={state[anchor]} color='action' onClose={toggleDrawer(anchor, false)}>
        {list(anchor)}
      </Drawer>
    </React.Fragment>
  ))}
  
  
 
    <div className={classes.root}>
      <AppBar style={
        headerBackground === 'transparent' ? { opacity : 1 }
        : headerBackground === 'opacity25%' ? { opacity : 0.25 }
        : headerBackground === 'opacity50%' ? { opacity : 0.5 }
        : headerBackground === 'opacity60%' ? { opacity : 0.6 }
        : headerBackground === 'opacity75%' ? { opacity : 0.75 }
        : headerBackground === 'opacity80%' ? { opacity : 0.8 }
        : headerBackground === 'opacity90%' ? { opacity : 0.9 }
        : { opacity : 1 }
        }
        // eslint-disable-next-line
        style={{ paddingTop: '10px', paddingBottom: '10px', boxShadow: 'none' }}
        position="fixed" 
        color={headerBackground === 'transparent' ? 'transparent' : '#000'} >
        
      <Container maxWidth='lg'>
        <Toolbar>

        {['left'].map((anchor) => (
              
          <div key={anchor} className={classes.menuMobile}>
            <MenuIcon onClick={toggleDrawer(anchor, true)} />
          </div>
        ))}
          
            <Typography variant="h6" className={classes.title} >
              <a href='/'>              
                <img src={kolia_color} className={classes.logoStyles}  alt='logo' />            
              </a>
            </Typography>
            
            <Link href='/login'>
                  <Typography>
                    <Box pr={1} pl={2} >
                      <Button 
                        variant='contained' 
                        className={classes.headlineButtonMobile} 
                        color='primary' 
                        size='medium' 
                      > 
                      <Box id='fontTamma' className={classes.buttonTextMob}>Sign In</Box>
                      </Button>                      
                    </Box>
                      
                  </Typography>
              </Link> 

            <div className={classes.buttonMenu}>
            
              <Link href='/about'>
                <Typography id='fontTamma' style={isActive(history, "/about")} className={classes.btnSpacing} >About Us</Typography>
              </Link>

              <Link href='/portfolio'>
                <Typography id='fontTamma' style={isActive(history, "/portfolio")}  className={classes.btnSpacing} >Portfolio</Typography>
              </Link>

              <Link href='/benefit' style={{ paddingRight: localStorage.getItem('nama') ? '300px' : '220px' }}>
                <Typography id='fontTamma' style={isActive(history, "/benefit")} className={classes.btnSpacing}  >Benefits</Typography>
              </Link>

              { localStorage.getItem('nama') ? 
              
              <Link href='/dashboard'>
                  <Typography id='fontTamma' className={classes.btnSpacing} style={{ paddingBottom: '20px' }} >Dashboard</Typography>
              </Link>
              :
              <>
              <Box>
              
              <Link href='/register'>
                  <Typography>
                    <Box pr={1} pl={2} >
                      <Button 
                        variant='contained' 
                        className={classes.headlineButton} 
                        color='primary' 
                        size='medium' 
                      > 
                      <Box id='fontTamma' className={classes.buttonText}>Sign Up</Box>
                      </Button>                      
                    </Box>
                      
                  </Typography>
              </Link>    
              
              <Box id='fontTamma' pt={1} fontSize={15} textAlign='center' color='#142461' fontFamily='BalooTamma2-Bold' >
                  Already a Kolia user? <a href='/login' style={{color:'#142461'}} ><strong>Sign In</strong></a>
              </Box>
              </Box>
              
              </>
              }

            </div>
        </Toolbar>
        </Container>
      </AppBar>
    </div>
  </React.Fragment>
  );
}


export default withRouter(NavbarLanding)