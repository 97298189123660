import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    root: {
        flexGrow: 1
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
      },
      buttonclass : {
        color: '#e0e0e0'
      },
      list: {
        width: 210,
        justifyContent: 'right'
      },
      fullList: {
        width: 'auto',
      },
     
      buttonMenu: {
        
        [theme.breakpoints.up('xs')]: {
          display: 'none'
        },
    
        [theme.breakpoints.up('sm')]: {
          display: 'none'
         
        },
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          paddingTop: '10px'
         
        },
        [theme.breakpoints.up('lg')]: {
          display: 'flex',
          paddingTop: '15px'
          
        },
      },
      menuMobile: {
        paddingRight: '15px',
        [theme.breakpoints.up('xs')]: {
          display: 'block'
        },
    
        [theme.breakpoints.up('sm')]: {
          display: 'block'
         
        },
        [theme.breakpoints.up('md')]: {
          display: 'none'
         
        },
        [theme.breakpoints.up('lg')]: {
          display: 'none'      
         
        },
        center: {
          justifyContent: 'center'
        },
        large: {
          width: theme.spacing(10),
          height: theme.spacing(10),
        },
        medium: {
          width: theme.spacing(4),
          height: theme.spacing(4),
          marginRight:'20px'
        },
       
      },
      logoStyles: {
        [theme.breakpoints.up('xs')]: {
          width:'100px'
        },
    
        [theme.breakpoints.up('sm')]: {
          width:'100px'
         
        },
        [theme.breakpoints.up('md')]: {
          width:'100px'
         
        },
        [theme.breakpoints.up('lg')]: {
          width:'150px'   
        },
      },
      buttonwidth: {
        width: '300px',
        justifyContent: 'left',
        color: '#142461',
        textTransform: 'capitalize'
      },
    
      btnSpacing: {
        marginTop: '10px',
        paddingTop: '8px',
        paddingBottom: '5px',
        paddingLeft: '15px',
        paddingRight: '15px',
        color: '#142461',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 'bold',
        fontSize: '18px',
        textAlign:'center'
      },
      headlineButton: {
        borderRadius: '30px',
        paddingLeft: '60px',
        paddingRight: '60px',
        paddingTop: '8px',
        paddingBottom: '8px',
        backgroundColor: '#7c71d5',
      },
      headlineButtonMobile: {
        borderRadius: '20px',
        paddingLeft: '15px',
        paddingRight: '15px',
        backgroundColor: '#7c71d5',
        paddingTop: '5px',
        paddingBottom: '2px',
        fontSize: '10px',
        marginTop: '5px',    
        [theme.breakpoints.up('md')]: {
          display:'none'
         
        },
        [theme.breakpoints.up('lg')]: {
          display:'none'   
        },
      },
      buttonSignIn : {
        color: '#142461',
        textAlign: 'center',
        fontFamily: 'Baloo Tamma 2',
        fontSize: '10px',
        lineHeight: 1.5,
      },
      buttonText:{
        color: '#fbfbfb',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'capitalize'
      },
      buttonTextMob:{
        color: '#fbfbfb',
        fontSize: '15px',
        textTransform: 'capitalize'
      },
}));
