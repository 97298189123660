import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { Button, Box } from '@material-ui/core';
import second_img from '../../assets/img/landing_second.png'
import useStyles from './style/styleTwo'

 
  const SectionTwo = () => {
 
    const classes = useStyles();

    return (
    <Paper id='fontTamma' className={classes.mainFeaturedPost} >
      
      <div/>
      <Container maxWidth="lg">
        <Box textAlign='center' >
            <Box  className={classes.headTitleSecond} >
              here are some
            </Box>
            <Box className={classes.headTextSecond}  >
              Things you can do in Kolia.
            </Box>
        </Box>
          
        <Grid container spacing={4} direction='row' >        
          <Grid item md={6} sm={6} xs={12}  >
            <div className={classes.mainFeaturedPostContent}>
              
                <Box className={classes.headlineText}  >
                  Have Fun!
                </Box>

                <Box>
                  <Box className={classes.headlineCamption} >
                  You will have a direct access to the latest campaign assignments to choose from, No pitching or sourcing - we've done it for you.
                  </Box>
                </Box>
                
                <Box pt={4}  >
                <Link href="/about">
                  <Button className={classes.headlineButton} variant='contained' color='action' size='large'>
                    <Box className={classes.buttonText}>Know more</Box>
                  </Button>
                </Link>
              </Box> 
              
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}  >
            <Box className={classes.mainFeaturedPostContent} >
              <img alt='two people' width='100%' src={second_img} />
              
            </Box>
          </Grid>
          
        </Grid>
      </Container>
    </Paper>

    )
}

export default SectionTwo;