import React from 'react';
import NavbarLanding from '../../components/landing/NavbarLanding';
import SectionAboutOne from '../../components/about/SectionAboutOne'
import SectionAboutTwo from '../../components/about/SectionAboutTwo'
import FooterLanding from '../../components/landing/FooterLanding'

const AboutPage = () => {
    return (
        <React.Fragment>
            <NavbarLanding/>
            <SectionAboutOne/>             
            <SectionAboutTwo/>             
            <FooterLanding/>  
        </React.Fragment>
    )
}

export default AboutPage;