import React, {useEffect, useState} from 'react';
import AppBarUser from '../../components/AppBarUser';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Box,
} from '@material-ui/core';
import { API, TokenUser } from '../../config'
import { useDispatch } from 'react-redux';
import MenuSideUser from '../../components/MenuSIdeUser'
import Button from '@material-ui/core/Button';
import Axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root : {
      marginBottom: '100px',
      marginTop: '130px'  
    },
    containerdiv: {
      textAlign: 'center'
    },
    rootComponent: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: '100px',
            paddingBottom: '100px',
        }, 
        [theme.breakpoints.up('sm')]: {
            paddingTop: '100px',
            paddingBottom: '100px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
    },
    lineDivider: {
        paddingTop: '7px',
        borderBottom: '2px solid #eeeeee'
    },
    iconSection: {
        paddingTop: '45px'
    },
    backgroundIcon: {        
        padding: '30px',
        textAlign: 'center'
    },

    headlineButton: {
        "&:hover": {
            backgroundColor: "#574bb3 !important",        
        },
        textAlign: 'left',
        backgroundColor: '#7c71d5',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '7px',
          paddingBottom: '7px'
        },
      },
      buttonText: {
        color: '#fbfbfb',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '15px',
        textTransform: 'capitalize'
      },
     
    loadingCircleUpdate: {
        paddingLeft: '50px',
        paddingRight: '50px'
    }
    
    
  }));

const MyInterest = () => {
    const classes = useStyles();
    
    const [pickInterest, setPickInterest] = useState("")
    
    const [interest, setInterest] = useState([])

    // const [redirectNext, setRedirectNext] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(false)
  
    const dispatch = useDispatch()

    useEffect(()=>{       

        if(TokenUser) {
            const loadInterestUser = async () => {
                // setLoading(true)
                   const interest = await Axios.get(`${API}/influencer/interest`, {
                        headers: {                    
                            Authorization: `Bearer ${TokenUser}`
                        }
                    })
                    .then((res) => {        
                        let user_interest = res.data.interest_tag
                        setPickInterest(user_interest)
                        if(res.data) {
                            Axios.get(`${API}/influencer/linking-account`, {
                                headers: {                    
                                    Authorization: `Bearer ${TokenUser}`
                                }
                            })
                            .then((res) => {        
                                if(res.data) {
                                    let master_interest = res.data.data.interest           
                                    
                                    let user_interest_length = user_interest.length
                                    let state = []  
                                    
                                    let int = false 
                                    
                                    for (var j = 0; j < master_interest.length; j++) {
                                        // console.log(master_interest[j].interest_name, 'master loop interest')
                                        for(var i = 0; i < user_interest_length; i++ ) {
                                            // console.log(user_interest[i], 'user loop interest')
                                            if( master_interest[j].interest_name === user_interest[i] ) {
                                                state.push({
                                                    id: master_interest[j].id,
                                                    iconUrl: master_interest[j].iconUrl,
                                                    interest_name: master_interest[j].interest_name,
                                                    color : '#efedf8',
                                                    fontweight: 'bold'
                                                })
                                                int = true
                                            }       
                                        } 
                                        if(!int) {
                                            state.push({
                                                id: master_interest[j].id,
                                                iconUrl: master_interest[j].iconUrl,
                                                interest_name: master_interest[j].interest_name,
                                                color : '#fff',
                                                fontweight: 'reguler'
                                            })
                                        }
                                        int = false           
                                    }            
                                    // console.log(state, 'state interest')
                                    setInterest(state)
                                }
                                // setInterest(res.data.data.interest)
                                // setLoading(false)
                            }).catch((err) => {
                                console.log(err)
                                // setLoading(false)
                            }) 
                            // setLoading(false)
                        }
                        
                    }).catch((err) => {
                        console.log(err)
                        // setLoading(false)
                    })      
                    return interest  
                }
            loadInterestUser()
        }       

    }, [dispatch]);
    
    const [alertError, setAlertError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [alertSuccess, setAlertSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")

    
    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertError(false);
      };
    
    const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setAlertSuccess(false);
    };
  
    const onClickSubmit = ()  => {
        setLoadingBtn(true)

        setTimeout(async() => {
            let data_string = pickInterest.toString()
            let data_replace = data_string.replace(/,/g, '#')
            
            let sendData = {
                'interest_tag' : data_replace
            }

            const post = await Axios.post(`${API}/influencer/interest`, sendData, {
                headers: {                    
                    Authorization: `bearer ${TokenUser}`
                }            
                })
                .then((res) => {
                    setLoadingBtn(false)
                    setAlertSuccess(true)
                    setSuccessMessage(res.data.message)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }).catch((err) => {
                    setLoadingBtn(false)
                    setAlertError(true)
                    setErrorMessage(err.response.data.message[0].message)
                })   
            return post;    
        }, 2000); 

    }
 
    return (
    <React.Fragment>
        <div className={classes.roots}>
        <Snackbar 
            open={alertError} 
            autoHideDuration={2000} 
            onClose={handleCloseError}
            anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}
        >
            <Alert onClose={handleCloseError} severity="error">
            <Box className={classes.textError} >{errorMessage}</Box>
            </Alert>
        </Snackbar>

        <Snackbar 
            open={alertSuccess} 
            autoHideDuration={2000} 
            onClose={handleCloseSuccess}
            anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}
        >
            <Alert onClose={handleCloseSuccess} severity="success">
            <Box className={classes.textError} >{successMessage}</Box>
            </Alert>
        </Snackbar>

        </div>
    <AppBarUser/>           
        <Container maxWidth='lg'>
            <Grid container spacing={2} >
                <Grid item lg={3} md={3} >
                    <MenuSideUser/>
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12} >
                    <div className={classes.rootComponent}>
                        <Grid container>
                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                <Box>
                                    <Box id='fontTamma' fontSize={25} color='#343c5a' >
                                        <span style={{fontWeight:'bold', color:'#7c71d5'}}>{localStorage.getItem('nama') + ' '}</span>
                                        - My Interests
                                    </Box>
                                </Box>
                                <Box className={classes.lineDivider}></Box>
                                <Box pt={1} id='fontTamma' color='#6e738a' fontSize={18} >
                                My expertise business.
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.iconSection} >
                            <Grid item lg={10} md={10} sm={12} xs={12} >
                                <Grid container spacing={2} >

                                {interest.map((data, i) => (
                                    <Grid key={i} item lg={3} md={3} sm={3} xs={6} >
                                        <Box 
                                            style={{ backgroundColor: data.color, cursor: 'pointer' }}
                                            className={classes.backgroundIcon}
                                            onClick={() => {
                                            if(data.color === '#fff') {
                                                setInterest([
                                                    ...interest, 
                                                    {
                                                        interest: interest[i].color = '#efedf8',
                                                         // eslint-disable-next-line
                                                        interest: interest[i].fontweight = 'bold'
                                                    }
                                                ])
                                                setInterest(interest.filter((data,i) => { return i !== interest.length } ))
                                                setPickInterest([...pickInterest, data.interest_name])
                                            } else if (data.color === '#efedf8') {
                                                setInterest([
                                                    ...interest, 
                                                    {
                                                        interest: interest[i].color = '#fff', 
                                                        // eslint-disable-next-line
                                                        interest: interest[i].fontweight = 'reguler' 
                                                    }
                                                        
                                                ])
                                                setInterest(interest.filter((data,i) => { return i !== interest.length } ))
                                                pickInterest.splice(pickInterest.findIndex(e => e === data.interest_name),1)

                                            }
                                        }}
                                        >                                        
                                            <Box><img src={data.iconUrl} alt='icon interest' width='64px' /></Box>
                                            <Box pt={1} id='fontTamma' fontSize={15} style={{color: '#7c71d5', fontWeight: data.fontweight }} >
                                                {data.interest_name}
                                            </Box>                                        
                                        </Box>
                                    </Grid>
                                    
                                    ))}
                                    
                                </Grid>

                                <Box pt={4} className={classes.lineDivider}></Box>

                                <Box pt={4}>
                                    <Grid container direction='row' justify='flex-end'  >
                                        <Button className={classes.headlineButton} onClick={onClickSubmit} >
                                            { loadingBtn ?
                                                <Box className={classes.loadingCircleUpdate} ><CircularProgress style={{color: '#fff'}} size={23} /></Box>
                                                :
                                                <Box className={classes.buttonText}>Save My Interests</Box>
                                            }                                            
                                        </Button>
                                    </Grid>
                                </Box>

                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>            
        </Container>
        
    </React.Fragment>
    )
}

export default MyInterest;