import React from 'react';
import { Route, Redirect} from 'react-router-dom'

const AuthRoute = ({component: Component, ...rest}) => {    
  
    const isAuthenticated = () => {
        if(typeof window == 'undefined') {
            return false
        }

        if(localStorage.getItem('nama')) {
            return localStorage.getItem('nama')
        } else {
            return false
        }
    }

    return (
            <Route 
                {...rest} 
                render={props => {
                    if(isAuthenticated())  {
                        return <Component {...props} />
                    }
                        else {
                            return   <Redirect 
                                        to={{
                                            pathname: "/login",
                                            state: { from: props.location }
                                        }}
                                    />
                        }
                
                } }
            />
)

}

export default AuthRoute;