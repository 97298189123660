import React from 'react';
import NavbarLanding from '../../components/landing/NavbarLanding';
import SectionOne from '../../components/landing/SectionOne'
import SectionTwo from '../../components/landing/SectionTwo'
import SectionThird from '../../components/landing/SectionThird'
import SectionFour from '../../components/landing/SectionFour'
import SectionFive from '../../components/landing/SectionFive'
import FooterLanding from '../../components/landing/FooterLanding'

const LandingPage = () => {
    return (
        <React.Fragment> 
           <NavbarLanding/>
           <SectionOne/>  
           <SectionTwo/> 
           <SectionThird/>
           <SectionFour/>        
           <SectionFive/>
           <FooterLanding/>  
        </React.Fragment>
    )
}

export default LandingPage;