import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    mainFeaturedPost: {
        color: theme.palette.common.white,
        backgroundColor: '#fff',
        border: 'none !important  ',
        [theme.breakpoints.up('xs')]: {
          paddingTop: '5px',
          height: 850,
        },
        [theme.breakpoints.up('sm')]: {
          paddingTop: '10px',
          height: 600,
        },
        [theme.breakpoints.up('md')]: {
          paddingTop: '10px',
          height: 700,
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: '30px',
          height: 700,
        },
        [theme.breakpoints.up('xl')]: {
          paddingTop: '30px',
          height: 800,
        }
      },
      mainFeaturedPostContent: {
        position: 'relative',      
        [theme.breakpoints.up('xs')]: {
          fontSize: '10px',
          paddingTop: '30px',
          textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
          paddingRight: 0,
          paddingTop: '80px',
          textAlign: 'center'
        },
        [theme.breakpoints.up('md')]: {
          paddingRight: 0,
          paddingTop: '80px',
          textAlign: 'left'
        },
        [theme.breakpoints.up('lg')]: {
          paddingRight: 0,
          paddingTop: '100px',
          textAlign: 'left'
        }
      },
      
      headlineCamption: {
        color:'#fbfbfb',
        fontFamily: 'Baloo Tamma 2',
        lineHeight: 1.4,
        [theme.breakpoints.up('xs')]: {
          fontSize: '15px',
          textAlign: 'left',
          height: 110
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: '12px',
          textAlign: 'justify',
          height: 140
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '20px',
          textAlign: 'left',
          height: 180
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '20px',
          textAlign: 'left',
          height: 160
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '20px',
          textAlign: 'left',
          height: 170
        }
      },
      headTextSecond: {
        fontFamily: 'Baloo Tamma 2',      
        fontWeight: 'bold',
        color: '#142461',
        [theme.breakpoints.up('xs')]: {
          fontSize: '25px',
        },      
        [theme.breakpoints.up('sm')]: {
          fontSize: '30px',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '40px',
        },
      },
      headTitleSecond: {
        color: '#bcc3de',
        [theme.breakpoints.up('xs')]: {
          fontSize: '20px',
        },      
        [theme.breakpoints.up('sm')]: {
          fontSize: '20px',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '20px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '30px',
        },
      },
      headTitleBold: {
        color: '#142461',
        fontWeight: 'bold',
        fontSize: '20px',
        textDecoration: 'underline'
      },
      headTitleBold1: {
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#7c71d5'
      },
      boxTestimoni: {
        [theme.breakpoints.up('xs')]: {
          paddingTop: '20px',
        },      
        [theme.breakpoints.up('sm')]: {
          paddingTop: '30px',
        },
        [theme.breakpoints.up('md')]: {        
          paddingTop: '80px',
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: '100px',
        },
      },
      large: {
        [theme.breakpoints.up('xs')]: {
          width: theme.spacing(6),
          height: theme.spacing(6),
        },      
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(4),
          height: theme.spacing(4),
        },
        [theme.breakpoints.up('md')]: {        
          width: theme.spacing(7),
          height: theme.spacing(7),
        },
        [theme.breakpoints.up('lg')]: {
          width: theme.spacing(7),
          height: theme.spacing(7),
        },
        
      },
      name: {
        [theme.breakpoints.up('xs')]: {
          fontWeight: 'bold',
          fontSize: '12px'
        },      
        [theme.breakpoints.up('sm')]: {
          fontWeight: 'bold',
          fontSize: '13px'
        },
        [theme.breakpoints.up('md')]: {        
          fontWeight: 'bold',
          fontSize: '16px'
        },
        [theme.breakpoints.up('lg')]: {
          fontWeight: 'bold',
          fontSize: '20px'
        },
        [theme.breakpoints.up('xl')]: {
          fontWeight: 'bold',
          fontSize: '20px'
        },
      },
      medium: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        paddingTop: '13px',
        paddingBottom: '10px',
        paddingRight: '10px',
        paddingLeft: '8px',
        
      },
      loveBackground: {
        backgroundColor: '#eeeeee',
        borderRadius: '50%',
        width:'55px',
        height:'55px',
        [theme.breakpoints.up('xs')]: {
          marginTop: '30px',
        },      
        [theme.breakpoints.up('sm')]: {
          marginTop: '40px',
        },
        [theme.breakpoints.up('md')]: {        
          marginTop: '70px',
        },
        [theme.breakpoints.up('lg')]: {
          marginTop: '100px',
        },
      }
}));
