import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import {FaHeart} from 'react-icons/fa'
import useStyles from './style/styleFour'
  
  const SectionFour = () => {
 
    const classes = useStyles();
    const testiPeople = [
      {
        id: 1,
        name: "SAJJAAD ALI",
        job: "Islamic Pop Singer",
        testimonial: "Alhamdulillah… Secara keseluruhan, saya suka sekali bergabung dengan Mediacartz, karena prosesnya tergolong cepat dan mudah untuk saya bisa dapet orderan hehehe. Terima kasih yah Mediacartz, Insya Allah sukses terus!",
        image: require('./../../assets/img/sajjad.jpeg')
      }, 
      {
        id: 1,
        name: "WITHNEY SAMBONO",
        job: "Singer/Performer",
        testimonial : "Senang deh bergabung sama Mediacartz, bisa langsung dapet order tanpa harus berkomunikasi langsung dengan klien. Semuanya dibantu sama Mediacartz, jadi gak ribet. Untuk teman-teman influencer, ikutan daftar deh ke Medicartz… Gampang banget daftarnya, dan gak ada biaya alias gratis!",
        image: require('./../../assets/img/whitney.jpeg')
        
      }
    ]

    return (
    <Paper id='fontTamma' className={classes.mainFeaturedPost} >
      
      <div/>
      <Container maxWidth="lg">
        <Box textAlign='center' >
            <Box  className={classes.headTitleSecond} >
              in case you were wondering
            </Box>
            <Box className={classes.headTextSecond}  >
              What they say about Kolia.
            </Box>
        </Box>
        
        <Grid container spacing={2} className={classes.boxTestimoni} >
          
          {testiPeople.map((data,index) => (
            <Grid item lg={6} md={6} sm={6} xs={12} key={index}>
            <Box >
              <Paper className="paperTestimoniGradient" >
                <Box p={4} >
                  <Box className={classes.headlineCamption} >
                  {data.testimonial}
                  </Box>
                  <Box pt={2} >
                    <Grid container direction='row' >
                      <Box pr={2} ><Avatar  className={classes.large} src={data.image}></Avatar></Box>
                      <Box id='fontTamma'>
                        <Box className={classes.name} color='#fbfbfb' >{data.name}</Box>
                        <Box color='#fbfbfb'>{data.job}</Box>                         
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Grid>
          ))}
          
        
        </Grid>

        <Grid container justify='center'>
          <Box className={classes.loveBackground} >
            <FaHeart color='#7c71d5' className={classes.medium} />
          </Box>
        </Grid>
        
      </Container>
    </Paper>

    )
}

export default SectionFour;