import React from 'react';
import SignUpComponent from '../../components/signup/SignUpComponent';

const SignUpPage = () => {
    return (
        <React.Fragment>               
            <SignUpComponent/>           
        </React.Fragment>
    )
}

export default SignUpPage;