import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { 
  AppBar, 
  Divider,
  Toolbar, 
  Box,
  Grid,
  List,
  ListItem,
  Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import imglogo from './../assets/img/kolia_color.png'
import { Link, withRouter } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import profile from '../assets/img/profile_img.png'
import { API, TokenUser } from '../config'
import Axios from 'axios'


const isActive = (history, path) => {
  if (history.location.pathname === path) {
     return { 
              backgroundColor: '#dfd9f2',
              borderRadius: '25px', 
              fontWeight:"600",
              color:'#7c71d5', 
              textTransform: 'capitalize',
            };
  } else {
      return { color :'black', fontWeight:"600", textTransform: 'capitalize' };
  }
};


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  leftMenu: {
    display: 'flex',
    direction:'row',
    flexGrow: 1,
    marginLeft: '35px',
    paddingTop: '5px'
  },
  buttonclass : {
    color: '#e0e0e0'
  },
  list: {
    width: 210,
    justifyContent: 'right'
  },
  containerAppBar : {
    [theme.breakpoints.up('xs')]: {
     
    },

    [theme.breakpoints.up('sm')]: {
      marginLeft: '50px',
      marginRight: '50px'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '60px',
      marginRight: '60px'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '120px',
      marginRight: '120px'
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '300px',
      marginRight: '300px'
    },
  },
  buttonMenu: {    
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },

    [theme.breakpoints.up('sm')]: {
      display: 'none'
     
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex'
     
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex'
      
    },
    [theme.breakpoints.up('xl')]: {
      display: 'flex'
      
    },
  },
  menuMobile: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: '10px',
      paddingRight: '10px',
      display: 'flex'
    },

    [theme.breakpoints.up('sm')]: {
      display: 'flex'
     
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
     
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none'      
     
    },
    [theme.breakpoints.up('xl')]: {
      display: 'none'      
     
    },
    center: {
      justifyContent: 'center'
    },
    large: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    medium: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight:'10px'
    },
   
  },
  buttonwidth: {
    width: '300px',
    justifyContent: 'left',
    color: '#000',
    textTransform: 'capitalize',
  },
  btnSpacing: {
    paddingTop: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
    fontWeight: 400,
    fontSize: '18px'
  },
  menuLeft: {    
    paddingTop: '6px',
    marginTop: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginRight: '20px',
    fontSize: '20px'
  },
  menuRight: {
    paddingTop: '5px',
    marginTop: '5px',
  },
  rightName : {
    fontSize: '20px',
    paddingLeft: '10px',
    paddingTop: '5px'
  },
  logoKolia : {
    [theme.breakpoints.up('xs')]: {
      paddingTop: '10px'
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '10px'
    },
    [theme.breakpoints.up('md')]: {
     
    },
    [theme.breakpoints.up('lg')]: {
          
    },
    [theme.breakpoints.up('xl')]: {
            
    }
  },
  
}));

const AppBarUser = ({ history }) => {
  const classes = useStyles();

 
  const [state, setState] = React.useState({
    top: false,
    left: false,
    
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

    const [dataProfile, setDataProfile] = useState([])   
    const [dataExist, setDataExist] = useState(false)    
    const [detailProfile, setDetailProfile] = useState([]) 
    // const [loading, setLoading] = React.useState(false);

    useEffect(()=>{    
    const getUserProfile = () => {
        // setLoading(true)            
        Axios.get(`${API}/influencer/profile`, {               
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${TokenUser}`
            }
        })
        .then((res) => {
            setDataProfile(res.data.result)
            setDetailProfile(res.data.result.profile)
            setDataExist(true)
            // console.log(res.data, 'response data single')                
            // setLoading(false)

        }).catch((err) => {
            console.log(err)
            // setLoading(false)
        })        
    }

    getUserProfile()
    }, []);


  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List style={{ justifyContent: 'center' }}>
          <a href='/'>
          <ListItem className={classes.center}>
              <img alt="logo" src={imglogo} width='150px'  />
          </ListItem>
          </a>
      </List>
      <Divider />
        <List>
         
          <Link to='/dashboard' >
              <ListItem>
                <Button  className={classes.buttonwidth}><Box id='fontStyle' >Requests</Box></Button>
              </ListItem>
          </Link>
          
          <Link to='/project-progress' > 
              <ListItem>
                <Button  className={classes.buttonwidth}><Box id='fontStyle'>Progress</Box></Button>
              </ListItem>
          </Link>

          <Link to='/history' > 
              <ListItem>
                <Button  className={classes.buttonwidth}><Box id='fontStyle'>History</Box></Button>
              </ListItem>
          </Link>

          <Link to='/my-profile' > 
              <ListItem>
                <Button  className={classes.buttonwidth}><Box id='fontStyle'>Profile</Box></Button>
              </ListItem>
          </Link>
          <Link to='/social-profiles' > 
              <ListItem>
                <Button  className={classes.buttonwidth}><Box id='fontStyle'>Social Profiles</Box></Button>
              </ListItem>
          </Link>

          <Link to='/my-interest' > 
              <ListItem>
                <Button  className={classes.buttonwidth}><Box id='fontStyle'>My Interests</Box></Button>
              </ListItem>
          </Link>

          <Link to='/pricebook' > 
              <ListItem>
                <Button  className={classes.buttonwidth}><Box id='fontStyle'>Pricebook</Box></Button>
              </ListItem>
          </Link>

          <a href='/' onClick={() => localStorage.clear()}>
              <ListItem>
                <Button  className={classes.buttonwidth}><Box id='fontStyle'>Keluar</Box></Button>
              </ListItem>
          </a>

          <Divider/>

          <Link to='/dashboard' > 
              <ListItem>
                <Button  className={classes.buttonwidth}><Box id='fontStyle'>Syarat & Ketentuan</Box></Button>
              </ListItem>
          </Link>

          <Link to='/dashboard' > 
              <ListItem>
                <Button  className={classes.buttonwidth}><Box id='fontStyle'>Kebijakan Privasi</Box></Button>
              </ListItem>
          </Link>

        </List>
    </div>
  );

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const renderImg = () => {
    if(dataProfile.profile !== null) { 
        return (       
          
          <img src={ detailProfile.imageUrl ? detailProfile.imageUrl : profile } alt='profile' width='100%' />
          
        )           
    }  else {
      return (
        <img src={ profile } alt='profile' width='100%' />
      )
    }
  }

  const renderNama = () => {
    if(dataExist) {
      return (
        <>{dataProfile.fullname ? dataProfile.fullname : 'User Name' }</>
      )
    }
  }

  return (

   
  <React.Fragment>

  {['left'].map((anchor) => (
    <React.Fragment key={anchor}>
      
      <Drawer anchor={anchor} open={state[anchor]} color='secondary' onClose={toggleDrawer(anchor, false)}>
        {list(anchor)}
      </Drawer>
    </React.Fragment>
  ))}

    <div className={classes.root}>
      <AppBar position="fixed" style={{backgroundColor: '#fbfbfb', height: '70px'}} >
      <div className={classes.containerAppBar} >
        <Toolbar >
          <Box className={classes.logoKolia} >
            <a href='/'>              
              <img src={imglogo} width='100px' alt='logo' />            
            </a>
          </Box>
          <Box className={classes.leftMenu}>          
            
            
          </Box>

          <div className={classes.buttonMenu}>
              <Link to='/dashboard'>
                <Box id='fontTamma' className={classes.menuLeft} style={isActive(history, "/dashboard")} >Requests</Box>              
              </Link>  
              
              <Link to='/project-progress'>
                <Box id='fontTamma' className={classes.menuLeft} style={isActive(history, "/project-progress")} >Progress</Box>              
              </Link> 

              <Link to='/history'>
                <Box id='fontTamma' className={classes.menuLeft} style={isActive(history, "/history")} >History</Box>              
              </Link> 

              <Link>
                <Box 
                  id='fontTamma'
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle} 
                  className={classes.menuRight} 
                  style={isActive(history, "/profile")}
                > 
                  <Grid container direction='row' >
                    <Box><Avatar className={classes.medium}>{renderImg()}</Avatar></Box>
                    <Box className={classes.rightName} >{renderNama()} <ExpandMoreIcon/></Box>
                  </Grid>
                </Box>              
              </Link>


              <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                          <Link to='/my-profile' >
                            <MenuItem >
                              <Box pl={2} id='fontTamma' color='black' >Profile</Box>
                            </MenuItem>
                          </Link>
                          <Link to='/pricebook' >
                            <MenuItem >
                              <Box pl={2} id='fontTamma' color='black' >Price Book</Box>
                            </MenuItem>  
                          </Link>
                          <Link to='/social-profiles' >
                            <MenuItem >
                              <Box pl={2} id='fontTamma' color='black' >Social Profiles</Box>
                            </MenuItem>
                          </Link>
                          <a href='/' onClick={() => localStorage.clear()}>
                            <MenuItem >
                              <Box pl={2} pb={1} id='fontTamma' color='black' >Logout</Box>
                            </MenuItem>
                          </a>

                          <Divider/>

                          <Link to='/pricebook' >
                            <MenuItem >
                              <Box pl={2} pt={1} id='fontTamma' color='black' >Terms of service</Box>
                            </MenuItem>
                          </Link>
                          <Link to='/pricebook' >
                            <MenuItem >
                              <Box pl={2} id='fontTamma' color='black' >Privacy Policy</Box>
                            </MenuItem>
                          </Link>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            
            {['left'].map((anchor) => (              
              <div className={classes.menuMobile}>
                <MenuIcon style={{color:'#9e69d2'}} onClick={toggleDrawer(anchor, true)} />
              </div>
            ))}
            

        </Toolbar>
        </div>
      </AppBar>
    </div>
  </React.Fragment>
  );
}


export default withRouter(AppBarUser)