import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    rootContainer: {       
        [theme.breakpoints.up('xs')] : {
            display:'none'
        },
        [theme.breakpoints.up('sm')] : {
            display:'block'
        },
        [theme.breakpoints.up('md')] : {
            display:'block'
        },
        [theme.breakpoints.up('lg')] : {
            display:'block'
        },
        [theme.breakpoints.up('xl')] : {
            display:'block'
        }       
    },
    displayMobile : {
        [theme.breakpoints.up('xs')] : {
            display:'block',
            height: 'fit-content'
        },
        [theme.breakpoints.up('sm')] : {
            display:'block',
            height: 'fit-content'
        },
        [theme.breakpoints.up('md')] : {
            display:'none'
        },
        [theme.breakpoints.up('lg')] : {
            display:'none'
        },
        [theme.breakpoints.up('xl')] : {
            display:'none'
        }   
    },
    root: {
        marginTop: '80px',
        marginBottom: '40px'
    },   
    socialMedia : {
        backgroundColor:'#f4e8ff', 
        borderRadius: '20px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    rootlist: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    contentRightMobile: {
        color: '#343c5a',
        fontSize: '14px',
        paddingTop: '15px',
        paddingBottom: '9px'
    },
    paperproject: {
        marginTop: '20px', 
        minHeight: 700
    },
    papermobile: {
        marginTop: '20px',
        minHeight: 200
    },
    buttonstyle: {
        marginRight: '10px',
        paddingLeft:'40px',
        paddingRight: '40px'
    },
    hastagstyle: {
        color:'black',
        display:'flex',
        justifyContent: 'center'        
    },
    media: {
        height: 100,
        width: 120,
    },
    papermedia: {
        borderRadius: '15px'
    },
    table: {
        minWidth: 650,
    },
    btnaction: {
        marginRight: '5px'
    },
    imgStyle: {
        [theme.breakpoints.up('xs')]: {
            width: 150,
            textAlign: 'center'
        },      
        [theme.breakpoints.up('sm')]: {
            width: 200,
            textAlign: 'center'
        },
        [theme.breakpoints.up('md')]: {        
            width: 200,
            textAlign: 'center'
        },
        [theme.breakpoints.up('lg')]: {
            width: 250,
            textAlign: 'center'
        },
        [theme.breakpoints.up('xl')]: {
            textAlign: 'center',
            width: 250
        },
    },
    sectionNoData: {
        
        [theme.breakpoints.up('xs')]: {
            marginTop: '70px',
            marginBottom: '100px'
        },      
        [theme.breakpoints.up('sm')]: {
            marginTop: '70px',
            marginBottom: '100px'
        },
        [theme.breakpoints.up('md')]: {        
            marginTop: '70px',
            marginBottom: '100px'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '70px',
            marginBottom: '150px'
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: '150px',
            marginBottom: '200px'
        },
    },
    titlerow: {       
        color: '#6e738a',
        [theme.breakpoints.up('xs')]: {
            fontSize: '5px',
        },      
        [theme.breakpoints.up('sm')]: {
            fontSize: '5px',
        },
        [theme.breakpoints.up('md')]: {        
            fontSize: '10px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '15px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '15px',
        },
    },
    titleCell: {        
        color: '#6e738a',
        fontWeight: 'bold',
        [theme.breakpoints.up('xs')]: {
            fontSize: '9px',
        },      
        [theme.breakpoints.up('sm')]: {
            fontSize: '10px',
        },
        [theme.breakpoints.up('md')]: {        
            fontSize: '10px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '15px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '15px',
        },
    },
    btnsocial: {
        borderRadius: '15px',
        marginRight: '5px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    lineDivider: {
        paddingTop: '4px',
        borderBottom: '2px solid #eeeeee'
    },
    buttonWhite: {       
        textAlign: 'left',
        backgroundColor: '#fbfbfb',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '5px',
          paddingBottom: '8px'
        },
    },
    textWhite: {
        color: '#343c5a',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '15px',
        textTransform: 'capitalize'
    },
    headlineButton: {
        textAlign: 'left',
        backgroundColor: '#7c71d5',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingTop: '2px',
          paddingBottom: '1px',
          fontSize:'10px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingTop: '2px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingTop: '7px',
          paddingBottom: '7px'
        },
      },
    buttonText:{
        color: '#fbfbfb',
        fontFamily: 'Baloo Tamma 2',            
        textTransform: 'capitalize',
        [theme.breakpoints.up('xs')]: {
            fontSize: '13px',
            fontWeight: 400,   
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft:'10px',
            paddingRight: '10px'
        },      
        [theme.breakpoints.up('sm')]: {
            fontSize: '13px',
            fontWeight: 400,   
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft:'10px',
            paddingRight: '10px'
        },
        [theme.breakpoints.up('md')]: {        
            fontSize: '12px',
            fontWeight: 700,   
        },
        [theme.breakpoints.up('lg')]: {
             fontSize: '15px',
             fontWeight: 700,   
        },
        [theme.breakpoints.up('xl')]: {
             fontSize: '15px',
             fontWeight: 700,   
        },
    },
    buttonTextPending: {
    paddingTop: '7px',
    marginRight: '15px',
    color: '#d57193',
    fontFamily: 'Baloo Tamma 2',
    fontWeight: 700,
    fontSize: '15px',
    textTransform: 'capitalize'
    },
    rowTableHover: {
    "&:hover": {
        cursor: 'pointer'     ,
        boxShadow: '0 8px 6px -6px #bcc3de'
    },
    },
    sectionContent: {
        paddingTop: '20px'
    },
    sectionContentBottom: {
        paddingTop: '5px'
    },
    boxContent: {
        border : '1px solid #bcc3de',
        minHeight: 230
    },   

    boxContentDesc: {
        border : '1px solid #bcc3de',
        height: 400
    },
    boxContentSmall : {
        border : '1px solid #bcc3de',
        height: 'fit-content',
    },
    
    boxContentCaption: {
        border : '1px solid #bcc3de',
        minHeight: 200
    },
    updateSection: {
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    updateSectionbg: {
        backgroundColor: '#efedf8',
        marginBottom: '8px',
    },
    multilineColor:{
        color:'red'
    },
    buttonStatus :{
        backgroundColor: '#edf5f7',        
        border: 'none',        
        [theme.breakpoints.up('xs')]: {
            borderRadius: '10px',
        },      
        [theme.breakpoints.up('sm')]: {
            borderRadius: '10px',
        },
        [theme.breakpoints.up('md')]: {        
            borderRadius: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: '20px',
        },
        [theme.breakpoints.up('xl')]: {
            borderRadius: '20px',
        },
        
    },
    textStatus: {
        color: '#6abbcc',        
        textTransform :'capitalize',
        [theme.breakpoints.up('xs')]: {
            paddingTop: '4px',
            fontSize:'7px',
        },      
        [theme.breakpoints.up('sm')]: {          
            paddingTop: '4px',
            fontSize:'7px',
        },
        [theme.breakpoints.up('md')]: {        
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize:'16px',
            fontWeight: 'bold',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize:'16px',
            fontWeight: 'bold',
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize:'16px',
            fontWeight: 'bold',
        },
    },
   
    ViewCaption: {
        fontSize:'17px',
        color:'#7c71d5',
        fontWeight:'bold',
        cursor: 'pointer',
        textAlign: 'left',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    contengImg : {
        width: 200
    },
    titleLeft: {
        color: '#343c5a',
        fontSize: '15px',
        textTransform: 'uppercase',
        paddingTop: '15px',
        paddingBottom: '9px',
        paddingLeft: '10px'
    },
    contentRight: {
        color: '#343c5a',
        fontSize: '17px',
        paddingTop: '15px',
        paddingBottom: '9px'
    },
    titleLeft2: {
        color: '#343c5a',
        fontSize: '13px',
        textTransform: 'uppercase',
        paddingLeft: '20px'
    },
    titleLeft3: {
        color: '#343c5a',
        fontSize: '15px',
        textTransform: 'uppercase',
        paddingBottom: '9px',
        paddingTop: '20px',
        paddingLeft: '20px'
    },
    contentRight2: {
        color: '#343c5a',
        fontSize: '17px',
        paddingBottom: '9px',
        paddingLeft: '5px'
    },
    boxCaptions: {
        backgroundColor: '#efedf8'
    }
}));
