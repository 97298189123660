import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import kolia_color from './../../assets/img/kolia_color.png'
import mediacartz from './../../assets/img/logo_mediacartz.png'
import { FaFacebookSquare, FaTwitterSquare,  FaInstagramSquare } from 'react-icons/fa'
import useStyles from './style/styleFooter'


export default function Footer() {
  const classes = useStyles();

  return (
    <React.Fragment>      
    <div id='fontTamma' className="overlayFooterBg" >
     
    {/* Footer */}
      <Container maxWidth="lg" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          <Grid item lg={3} md={3} sm={4} xs={12}>
            <img alt="logo" src={kolia_color} width='160px'  />
            <Box pt={2} color='#142461' >Get exclusive info from Kolia</Box>
            <Box pt={2}>
              <TextField id="outlined-basic" label="Your Email" variant="outlined" />
            </Box>
            <Box pt={2}  >
                <Link href="/register">
                  <Button className={classes.headlineButton} variant='contained' color='primary' size='large'>
                    <Box id='fontTamma' className={classes.buttonText}>Join Us</Box>
                  </Button>
                </Link>
            </Box>
            <Box pt={2} color='#142461'>
            By using this form you agree with the storage and handling of your data by Kolia. Read our <a href='/' style={{color:'#7c71d5'}} >privacy policy.</a>
            </Box>
          </Grid>

          <Grid item lg={6} md={6} sm={4} xs={12}>
            <Grid container id='fontTamma' >
              <Grid item xs={6} sm={6} md={3} lg={3} >
                <Typography variant="h6" style={{color: '#142461', fontWeight:'bold'}} gutterBottom >
                  Company
                </Typography>
                <ul>
                    <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      About
                      </Link>
                    </li>
                    <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Careers
                      </Link>
                    </li>
                    <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Contact
                      </Link>
                    </li>
                    <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Testimonials
                      </Link>
                    </li>
                </ul>
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} >
                <Typography variant="h6" style={{color: '#142461', fontWeight:'bold'}} gutterBottom >
                 Influencers
                </Typography>
                <ul>
                    <li >
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                        Portfolio
                      </Link>
                    </li>
                    <li >
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Code of conduct
                      </Link>
                    </li>
                    <li >
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Influencers blog
                      </Link>
                    </li>
                </ul>
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} >
                <Typography variant="h6" style={{color: '#142461', fontWeight:'bold'}} gutterBottom >
                  Brands
                </Typography>
                <ul>
                    <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Create project
                      </Link>
                    </li>
                     <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Advertise with us
                      </Link>
                    </li>
                    <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Brands blog
                      </Link>
                    </li>
                </ul>
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} >
                <Typography variant="h6" style={{color: '#142461', fontWeight:'bold'}} gutterBottom >
                  Directories
                </Typography>
                <ul>
                    <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Top influencers
                      </Link>
                    </li>
                    <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Top brands
                      </Link>
                    </li>
                    <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Explore projects
                      </Link>
                    </li>
                    <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Categories
                      </Link>
                    </li>
                    <li>
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Tags
                      </Link>
                    </li>
                    
                </ul>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} >
                <Typography variant="h6" style={{color: '#142461', fontWeight:'bold'}} gutterBottom >
                 Get in touch
                </Typography>
                <ul>
                    <li >
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      021-2295-8145
                      </Link>
                    </li>
                    <li >
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      admin@exel-int.com
                      </Link>
                    </li>
                    <li >
                      <Link href="#" variant="subtitle1" style={{color: '#142461'}}>
                      Influencers blog
                      </Link>
                    </li>
                </ul>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} >
                <Typography variant="h6" style={{color: '#142461', fontWeight:'bold'}} gutterBottom >
                Follow us
                </Typography>
                <ul>
                    <li>
                      <Grid container direction='row'  >
                        <Link target="_blank" href="https://www.facebook.com/" >
                          <FaFacebookSquare color='#7c71d5' size={25} /> 
                        </Link>
                        <Link target="_blank" href="https://twitter.com/" >                        
                          <FaTwitterSquare color='#7c71d5' size={25}/>                        
                        </Link>
                        <Link target="_blank" href="https://www.instagram.com/" >                                                  
                            <FaInstagramSquare color='#7c71d5' size={25}/>                        
                        </Link>
                      </Grid>
                    </li>
                    
                </ul>
              </Grid>


            </Grid>
          </Grid>

          <Grid item lg={3} md={3} sm={4} xs={12}>
            <a href="https://www.mediacartz.com"><img alt="logo" src={mediacartz} width='200px' /> </a>
            <Box id='fontTamma' pt={2} color='#142461' fontSize={19} >
            One stop advertising platform to meet your marketing goals & providing the best solutions that right for your needs.
            </Box>
          </Grid>
          
        </Grid>

        <Box className={classes.dividerLine} >
        
        </Box>

        <Grid container>
          <Grid item lg={9} md={9} sm={8} xs={12}>
            <Grid container direction='row' justify='flex-start' >
              <Box pt={2} color='#142461' >© 2020 Kolia. All rights reserved.</Box>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={12}>
            <Grid container direction='row' justify='space-between' >
              <Box pt={2} >
                <a href='/' style={{color:'#142461'}} >Terms of service</a>
              </Box>
              <Box pt={2} >
                <a href='/' style={{color:'#142461'}} >Privacy policy</a>
              </Box>
            </Grid>
          </Grid>

        </Grid>

        
       
      </Container>
      {/* End footer */}
      </div>
    </React.Fragment>
  );
}