import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  mainFeaturedPost: {
    color: theme.palette.common.white,
    backgroundColor: '#fff',
    [theme.breakpoints.up('xs')]: {
      paddingTop: '5px',
      height: 930,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '10px',
      height: 550,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '10px',
      height: 550,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '30px',
      height: 750,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '30px',
      height: 800,
    }
  },
  mainFeaturedPostRight: {
    position: 'relative',      
    [theme.breakpoints.up('xs')]: {
      textAlign: 'center',
      paddingTop: '30px',
      paddingBottom: '10px'
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '80px',
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '80px',
      textAlign: 'left'
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '80px',
      textAlign: 'left',
      paddingLeft: '50px'
    }
  },
  mainFeaturedPostLeft: {
    position: 'relative',      
    [theme.breakpoints.up('xs')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '80px',
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '80px',
      textAlign: 'left'
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '80px',
      textAlign: 'left',
    }
  },
  headlineText : {     
    fontWeight: 'bold',
    fontFamily: 'Baloo Tamma 2',
    paddingBottom: '5px', 
    lineHeight: 1.4,
    color: '#142461',      
    [theme.breakpoints.up('xs')]: {
      fontSize: '20px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '30px',
      textAlign: 'left',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '36px',
      textAlign: 'left',
    }
  },
  headlineCamption: {
    color: '#142461',
    fontFamily: 'Baloo Tamma 2',
    lineHeight: 1.4,
    [theme.breakpoints.up('xs')]: {
      fontSize: '15px',
      textAlign: 'justify',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
      textAlign: 'justify',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '20px',
      textAlign: 'justify',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
      textAlign: 'justify',
    }
  },
  headlineButton: {
    textAlign: 'left',
    backgroundColor: '#eae8ff',
    [theme.breakpoints.up('xs')]: {
      borderRadius: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '1px',
      paddingBottom: '1px',
      fontSize:'10px'
    },      
    [theme.breakpoints.up('sm')]: {
      borderRadius: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '1px',
      paddingBottom: '1px'
    },
    [theme.breakpoints.up('md')]: {        
      borderRadius: '30px',
      paddingLeft: '40px',
      paddingRight: '40px',
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '30px',
      paddingLeft: '60px',
      paddingRight: '60px',
      paddingTop: '10px',
      paddingBottom: '10px'
    },
  },
  buttonText:{
      color: '#7c71d5',
      fontFamily: 'Baloo Tamma 2',
      fontWeight: 700,
      fontSize: '20px',
      textTransform: 'capitalize'
  },
  headTextSecond: {
    fontFamily: 'Baloo Tamma 2',      
    fontWeight: 'bold',
    color: '#142461',
    [theme.breakpoints.up('xs')]: {
      fontSize: '25px',
    },      
    [theme.breakpoints.up('sm')]: {
      fontSize: '30px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '30px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '40px',
    },
  },
  headTitleSecond: {
    color: '#bcc3de',
    [theme.breakpoints.up('xs')]: {
      fontSize: '20px',
    },      
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '30px',
    },
  },
  boxCategory: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: '20px'
    },      
    [theme.breakpoints.up('sm')]: {
      paddingTop: '30px'
    },
    [theme.breakpoints.up('md')]: {        
      paddingTop: '40px'
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '60px'
    },
  },
  headTitleBold: {
    color: '#142461',
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginRight: '2px',
    marginLeft: '2px',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
    },      
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '16px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
    },
  },
  headTitleBold1: {
    fontWeight: 'bold',      
    color: '#7c71d5',
    marginRight: '2px',
    marginLeft: '2px',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
    },      
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '16px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
    },
  }
}));
