import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Box } from '@material-ui/core';
import getpaid from '../../assets/img/getpaid.png'


const useStyles = makeStyles((theme) => ({
  mainFeaturedPostRoot: {
    position: 'relative',      
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px',
      height: 800,
      paddingTop: '100px',
      paddingBottom: '50px',
    },
    [theme.breakpoints.up('sm')]: {        
      paddingTop: '130px',
      height: 550
    },
    [theme.breakpoints.up('md')]: {        
      paddingTop: '200px',
      height: 750
    },
    [theme.breakpoints.up('lg')]: {        
      paddingTop: '200px',
      height: 900,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '200px',
      height: 990,
    }
  }, 
    mainFeaturedPostContent: {
      position: 'relative',      
      [theme.breakpoints.up('xs')]: {
        fontSize: '10px',
        paddingTop: '30px',
        textAlign: 'center'
      },
      [theme.breakpoints.up('sm')]: {
        paddingRight: 0,
        paddingTop: '60px',
        textAlign: 'center'
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: 0,
        paddingTop: '80px',
        textAlign: 'left'
      },
      [theme.breakpoints.up('lg')]: {
        paddingRight: 0,
        paddingTop: '100px',
        textAlign: 'left'
      }
    },
    mainFeaturedPostRight: {
      position: 'relative',      
      [theme.breakpoints.up('xs')]: {
        fontSize: '10px',
        paddingTop: '10px',
        textAlign: 'center'
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: '60px',
        textAlign: 'center'
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: '80px',
        textAlign: 'left'
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: '170px',
        textAlign: 'left'
      }
    },
    headlineText : {     
      fontWeight: 'bold',
      fontFamily: 'Baloo Tamma 2',
      paddingBottom: '5px', 
      lineHeight: 1.4,
      color: '#142461',
      
      [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '30px',
        textAlign: 'left',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '30px',
        textAlign: 'left',
      }
    },
    headlineCamption: {
      color: '#142461',
      fontFamily: 'Baloo Tamma 2',
      lineHeight: 1.5,
      [theme.breakpoints.up('xs')]: {
        fontSize: '15px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '13px',
        textAlign: 'justify',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '15px',
        textAlign: 'left',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '20px',
        textAlign: 'left',
      }
    },
    headlineButton: {
      textAlign: 'left',
      backgroundColor: '#eae8ff',
      [theme.breakpoints.up('xs')]: {
        borderRadius: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '1px',
        paddingBottom: '1px',
        fontSize:'10px'
      },      
      [theme.breakpoints.up('sm')]: {
        borderRadius: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '1px',
        paddingBottom: '1px'
      },
      [theme.breakpoints.up('md')]: {        
        borderRadius: '30px',
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      [theme.breakpoints.up('lg')]: {
        borderRadius: '30px',
        paddingLeft: '60px',
        paddingRight: '60px',
        paddingTop: '10px',
        paddingBottom: '10px'
      },
    },
    buttonText:{
        color: '#7c71d5',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'capitalize'
    },
    headTextSecond: {
      fontFamily: 'Baloo Tamma 2',      
      fontWeight: 'bold',
      color: '#142461',
      [theme.breakpoints.up('xs')]: {
        fontSize: '25px',
      },      
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '30px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '40px',
      },
    },
    headTitleSecond: {
      color: '#bcc3de',
      [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
      },      
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '20px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '30px',
      },
    },
    headTitleBold: {
      color: '#142461',
      fontWeight: 'bold',
      fontSize: '20px',
      textDecoration: 'underline'
    },
    headTitleBold1: {
      fontWeight: 'bold',
      fontSize: '20px',
      color: '#7c71d5'
    }
    
  }));

  
  const SectionBenefitsOne = () => {
 
    const classes = useStyles();

    return (
    <Paper id='fontTamma' className='mainFeaturedPostAboutOne' >
      
      <Container maxWidth="lg" className={classes.mainFeaturedPostRoot}>
        <Box textAlign='center' >
            <Box className={classes.headTextSecond}  >
              What Kolia is able to provide for you.
            </Box>
        </Box>

        <Grid container spacing={4} direction='row' >       
          <Grid item md={6} sm={6} xs={12}  >
            <Box className={classes.mainFeaturedPostContent} >
              <img alt='two people' width='100%' src={getpaid} />
              
            </Box>
          </Grid> 
          <Grid item md={6} sm={6} xs={12}  >
            <div className={classes.mainFeaturedPostRight}>
              
                <Box className={classes.headlineText}  >
                Get Paid For What You Love
                </Box>

                <Box pt={2} >
                  <Box className={classes.headlineCamption} >
                  You make recommendations everyday on your social media, so why not get paid for it?
                  </Box>
                </Box>
                
                
            </div>
          </Grid>
          
          
        </Grid>
      </Container>
    </Paper>

    )
}

export default SectionBenefitsOne;