import React from 'react';
import { 
    Grid,     
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonTable = () => {
    return (
        <React.Fragment>
        <div>
            <Grid container spacing={2}>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={80} />
                </Grid>
                <Grid item lg={3} md={3} sm={3} >
                    <Skeleton variant="text"  height={80} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={80} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={80} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={80} />
                </Grid>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={80} />
                </Grid> 
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={80} />
                </Grid>                          
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={3} md={3} sm={3} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid> 
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid>                          
            </Grid>

            <Grid container spacing={2}>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={3} md={3} sm={3} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid> 
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid>                          
            </Grid>

            <Grid container spacing={2}>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={3} md={3} sm={3} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid> 
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid>                          
            </Grid>

            <Grid container spacing={2}>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={3} md={3} sm={3} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid> 
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid>                          
            </Grid>

            <Grid container spacing={2}>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={3} md={3} sm={3} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={2} md={2} sm={2} >
                    <Skeleton variant="text"  height={60} />
                </Grid>
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid> 
                <Grid item lg={1} md={1} sm={1} >
                    <Skeleton variant="text"  height={60} />
                </Grid>                          
            </Grid>

            
            
            
        </div>
        </React.Fragment>
    )
}

export default SkeletonTable;