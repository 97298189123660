import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Box} from '@material-ui/core';
import './../../assets/css/main.css'
import coming from '../../assets/img/coming.svg'

const useStyles = makeStyles((theme) => ({
    
    mainFeaturedPostContent: {
      position: 'relative',      
      [theme.breakpoints.up('xs')]: {
        fontSize: '10px',
        height: 750,
        paddingTop: '150px',
        paddingBottom: '50px',
      },
      [theme.breakpoints.up('sm')]: {        
        paddingTop: '150px',
        paddingBottom: '100px',
        height: 750
      },
      [theme.breakpoints.up('md')]: {        
        paddingTop: '200px',
        height: 950
      },
      [theme.breakpoints.up('lg')]: {        
        paddingTop: '200px',
        height: 950,
      },
      [theme.breakpoints.up('xl')]: {
        paddingTop: '200px',
        height: 990,
      }
    },
    headlineText : {     
      fontWeight: 'bold',
      paddingBottom: '5px', 
      lineHeight: 1.2,
      color: '#142461',
      textAlign: 'center',
      [theme.breakpoints.up('xs')]: {
        fontSize: '25px',
        paddingBottom: '5px'
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '35px',
        paddingBottom: '10px'
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '40px',
        paddingBottom: '20px'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '40px',
        paddingBottom: '40px'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '50px',
        paddingBottom: '40px'
      }
    },
    btnCategory: {
      backgroundColor: '#dcd8f4', 
      borderRadius: '20px', 
      color:'#7c71d5',
      fontSize: '20px',
      marginRight: '10px',
      marginLeft: '10px'
    },
    btnCategoryNot: { 
      color:'#7c71d5',
      fontSize: '20px',
      marginRight: '10px',
      marginLeft: '10px'
    },
    btnName: {
      width: 'fit-content',
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingTop: '5px',
      paddingBottom: '2px',
      fontWeight: 'bold'
    },
    btnName2: {
      width: 'fit-content',
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingTop: '5px',
      paddingBottom: '2px',
    },
    comingImg: {
      [theme.breakpoints.up('xs')]: {
        paddingTop: '50px',
        width: '70%'
      },
      [theme.breakpoints.up('sm')]: {
        width: '75%'
      },
      [theme.breakpoints.up('md')]: {        
        width: '70%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '70%'
      },
      [theme.breakpoints.up('xl')]: {
        width: '70%'
      }
    }
    
  }));

  
  const SectionPortfolioOne = () => {
 
    const classes = useStyles();

    return (
    <Paper className='mainFeaturedPostAboutOne' >
      <Container >
        <Grid container  direction='row' justify='center' >
          <Grid item lg={12} md={12} sm={12} xs={12}  >
            <div id='fontTamma' className={classes.mainFeaturedPostContent}>
              
                <Box className={classes.headlineText} >
                  Coming Soon
                </Box>

    {/* 
                <Grid container direction='row' justify='center' >
                  <Box className={classes.btnCategory}>
                    <Link href='/'>
                      <Box id='fontTamma' className={classes.btnName} >All</Box>
                    </Link>
                  </Box>
                  <Box className={classes.btnCategoryNot}>
                    <Link href='/'>
                      <Box id='fontTamma' className={classes.btnName2} >Category</Box>
                    </Link>
                  </Box>
                  <Box className={classes.btnCategoryNot}>
                    <Link href='/'>
                      <Box id='fontTamma' className={classes.btnName2} >Category</Box>
                    </Link>
                  </Box>
                  <Box className={classes.btnCategoryNot}>
                    <Link href='/'>
                      <Box id='fontTamma' className={classes.btnName2} >Category</Box>
                    </Link>
                  </Box>
                  <Box className={classes.btnCategoryNot}>
                    <Link href='/'>
                      <Box id='fontTamma' className={classes.btnName2} >Category</Box>
                    </Link>
                  </Box>
                  <Box className={classes.btnCategoryNot}>
                    <Link href='/'>
                      <Box id='fontTamma' className={classes.btnName2} >Category</Box>
                    </Link>
                  </Box>
                  <Box className={classes.btnCategoryNot}>
                    <Link href='/'>
                      <Box id='fontTamma' className={classes.btnName2} >Category</Box>
                    </Link>
                  </Box>
                </Grid>
*/}             

            <Grid container direction='row' justify='center'>
              <img src={coming} alt='coming' className={classes.comingImg} />
            </Grid>
            </div>
          </Grid>
          
        </Grid>
      </Container>
    </Paper>

    )
}

export default SectionPortfolioOne;