import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { Button, Box } from '@material-ui/core';
import aboutsec2 from '../../assets/img/about_sec2.png'


const useStyles = makeStyles((theme) => ({
    mainFeaturedPost: {
      color: theme.palette.common.white,
      backgroundColor: '#fffeff',
      border: 'none',
      [theme.breakpoints.up('xs')]: {
        paddingTop: '5px',
        height: 700,
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: '10px',
        height: 500,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: '10px',
        height: 700,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: '30px',
        height: 800,
      },
      [theme.breakpoints.up('xl')]: {
        paddingTop: '40px',
        height: 990,
      }
    },
    mainFeaturedPostContent: {
      position: 'relative',      
      [theme.breakpoints.up('xs')]: {
        fontSize: '10px',
        paddingTop: '30px',
        textAlign: 'center'
      },
      [theme.breakpoints.up('sm')]: {
        paddingRight: 0,
        paddingTop: '80px',
        textAlign: 'center'
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: 0,
        paddingTop: '80px',
        textAlign: 'left'
      },
      [theme.breakpoints.up('lg')]: {
        paddingRight: 0,
        paddingTop: '100px',
        textAlign: 'left'
      }
    },
    headlineText : {     
      fontWeight: 'bold',
      fontFamily: 'Baloo Tamma 2',
      paddingBottom: '5px', 
      lineHeight: 1.4,
      color: '#142461',
      
      [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '30px',
        textAlign: 'left',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '30px',
        textAlign: 'left',
      }
    },
    headlineCamption: {
      color: '#142461',
      fontFamily: 'Baloo Tamma 2',
      lineHeight: 1.4,
      [theme.breakpoints.up('xs')]: {
        fontSize: '15px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '19px',
        textAlign: 'left',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '19px',
        textAlign: 'left',
      }
    },
    headlineButton: {
      textAlign: 'left',
      backgroundColor: '#eae8ff',
      [theme.breakpoints.up('xs')]: {
        borderRadius: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '1px',
        paddingBottom: '1px',
        fontSize:'10px'
      },      
      [theme.breakpoints.up('sm')]: {
        borderRadius: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '1px',
        paddingBottom: '1px'
      },
      [theme.breakpoints.up('md')]: {        
        borderRadius: '30px',
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      [theme.breakpoints.up('lg')]: {
        borderRadius: '30px',
        paddingLeft: '60px',
        paddingRight: '60px',
        paddingTop: '10px',
        paddingBottom: '10px'
      },
    },
    buttonText:{
        color: '#7c71d5',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'capitalize'
    },
    headTextSecond: {
      fontFamily: 'Baloo Tamma 2',      
      fontWeight: 'bold',
      color: '#142461',
      [theme.breakpoints.up('xs')]: {
        fontSize: '25px',
      },      
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '30px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '40px',
      },
    },
    headTitleSecond: {
      color: '#bcc3de',
      [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
      },      
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.up('md')]: {        
        fontSize: '20px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '30px',
      },
    },
    
  }));

  
  const SectionAboutTwo = () => {
 
    const classes = useStyles();

    return (
    <Paper id='fontTamma' className={classes.mainFeaturedPost} >
      
      <div/>
      <Container maxWidth="lg">
        
        <Grid container spacing={4} direction='row' >        
          <Grid item lg={5} md={5} sm={5} xs={12}  >
            <div className={classes.mainFeaturedPostContent}>
              
                <Box className={classes.headlineText}  >
                  What Kolia does.
                </Box>

                <Box pt={2} >
                  <Box className={classes.headlineCamption} >
                  Kolia constantly search for the best products or services for our Influencer to experience the best partnership and network.
                  </Box>
                </Box>
                
                <Box pt={4}  >
                <Link href="/register">
                  <Button className={classes.headlineButton} variant='contained' color='action' size='large'>
                    <Box className={classes.buttonText}>Join us</Box>
                  </Button>
                </Link>
              </Box>
              
            </div>
          </Grid>
          <Grid item lg={7} md={7} sm={7} xs={12}  >
            <Box className={classes.mainFeaturedPostRight} >
              <img alt='two people' width='100%' src={aboutsec2} />
              
            </Box>
          </Grid>
          
        </Grid>
      </Container>
    </Paper>

    )
}

export default SectionAboutTwo;