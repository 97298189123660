import React, { useState, useEffect } from 'react';
import AppBarUser from '../../components/AppBarUser';
// import FooterDashboard from '../../components/FooterDashboard';
import { 
    Grid, 
    Container, 
    Paper,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Divider
} from '@material-ui/core';
import Axios from "axios";
import { API, TokenUser } from '../../config'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination';
import { FaTimes } from 'react-icons/fa'
import SkeletonTable from '../../components/SkeletonTable'
import nohistory from '../../assets/img/nohistory.png'
import useStyles from '../../assets/css/hisProStyle'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import SkeletonMobile from '../../components/SkeletonMobile'
import norequest from '../../assets/img/norequest.png'

const HistoryProjects = ({ history }) => {
    
    const classes = useStyles();
    
    const [historyProjects, setHistoryProjects] = useState([])     
    const [historyProjectsData, setHistoryProjectsData] = useState([])     
    const [dataLoaded, setDataLoaded] = useState(false)    

    const [detailProjects, setDetailProjects] = useState({
        dialog: false,
        project_name: '',
        owner_brand: '',
        description: '',
        id:'',
        start_date: '',
        link_content: '',
        desc_content: '',
        status: '',
        is_influencer_approved: ''
    })

    const [detailAttachment, setDetailAttachment] = useState({
        dialog: false,
        link_attach: '',
        desc_attach: '',
    })

    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState('md');
    const [maxWidthAttach] = React.useState('sm');


    const [page, setPage] = React.useState(1);

    const handleChange = (event, value) => {
      setPage(value);
      loadHistoryProjectsPaginate(value)
    };


    const handleClose = () => {
        setDetailProjects({...detailProjects, dialog: false});
    };

    const handleCloseAttachment = () => {
        setDetailAttachment({...detailAttachment, dialog: false});
    };

    const loadHistoryProjectsPaginate = (data) => {
        let page = data

        // setLoading(true)            
        Axios.get(`${API}/influencer/project/list`, {
            method: 'GET',
            params: {
                page: page,
                rows: 10,
                status : ["REJECTED BY INFLUENCER", "REJECTED BY OWNER", "DONE"]
            },
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${TokenUser}`
            }
        })
        .then((res) => {
            setHistoryProjects(res.data.data)
            setHistoryProjectsData(res.data)
            setDataLoaded(true)
            // console.log(res.data, 'response data single')                
            // setLoading(false)

        }).catch((err) => {
            console.log(err)
            // setLoading(false)
        })        
    }

    
    useEffect(()=>{    

        const loadHistoryProjects = () => {
            // setLoading(true)            
            Axios.get(`${API}/influencer/project/list`, {
                method: 'GET',
                params: {
                    page: 1,
                    rows: 10,
                    status : ["REJECTED BY INFLUENCER", "REJECTED BY OWNER", "DONE"]
                },
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": `Bearer ${TokenUser}`
                }
            })
            .then((res) => {
                setHistoryProjects(res.data.data)
                setHistoryProjectsData(res.data)
                setDataLoaded(true)
                // console.log(res.data, 'response data single')                
                // setLoading(false)

            }).catch((err) => {
                console.log(err)
                // setLoading(false)
            })        
        }
        loadHistoryProjects()

      }, []);


    //   const [loading, setLoading] = React.useState(false);

    const renderPaginate = () => {
        if(dataLoaded) {
            if(historyProjects.length > 10) {
                return (                    
                    <Pagination count={historyProjectsData.lastPage} page={page} onChange={handleChange} />
                )
            }
        }
    }

  const renderDataProject = () => {
    if(dataLoaded) {
        if(historyProjects.length !== 0) {
            return (
              <Table className={classes.table} aria-label="simple table">
              <TableHead>
                  <TableRow   >
                      <TableCell id='fontTamma' width='10px' className={classes.titlerow} ></TableCell>
                      <TableCell id='fontTamma' className={classes.titlerow}>PROJECT</TableCell>
                      <TableCell id='fontTamma' className={classes.titlerow}>BRAND</TableCell>
                      <TableCell id='fontTamma' className={classes.titlerow}>DATE</TableCell>
                      <TableCell id='fontTamma' className={classes.titlerow}>STATUS</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                {historyProjects.map((data, index) => (                    
                  <TableRow>
                      <TableCell id='fontTamma' className={classes.titleCell} >
                            { historyProjectsData.page > 1 ? 
                                <>
                                { index + 1 + (historyProjectsData.perPage  * (historyProjectsData.page - 1) ) }
                                </>

                                : 

                                <>
                                {index  + 1}
                                </>

                            }     
                      
                      </TableCell>
                      <TableCell id='fontTamma' className={classes.titleCell} >
                          {data.order.project_name}
                      </TableCell>
                      <TableCell id='fontTamma' className={classes.titleCell} >{data.order.owner_brand}</TableCell>                      
                      <TableCell id='fontTamma' className={classes.titleCell} >
                        {moment(data.start_date).format(' DD MMMM YYYY')}
                      </TableCell>
                      <TableCell id='fontTamma' className={classes.titleCell} >
                        { 
                            data.status === 'REJECTED BY INFLUENCER' ? 
                            <button className={classes.buttonStatus}>
                                <Box className={classes.textStatus}>
                                    Rejected
                                </Box>
                            </button> 
                            :
                            data.status === 'DONE' ? 
                            <button className={classes.buttonStatusDone}>
                                <Box className={classes.textStatusDone}>
                                    Done
                                </Box>
                            </button> 
                            :
                            <button className={classes.buttonStatus}>
                                <Box className={classes.textStatus}>
                                    {data.status}
                                </Box>
                            </button> 
                        }
                          
                      </TableCell>
                      
                  </TableRow>
              ))}
              </TableBody>
              </Table>
              
            )
        } else {
            return ( 

                <Grid container direction='column' alignItems='center'  >
                    <Box className={classes.sectionNoData}>
                        <Box textAlign='center'>
                            <img src={nohistory} alt='icon' className={classes.imgStyle} />
                        </Box>

                        <Box id='fontTamma' textAlign='center' pt={5} >
                            You don't have any history yet.
                        </Box>
                    </Box>
                </Grid>
            )
        }
    } else {
        return (
            <SkeletonTable/>
        )
    }
    }

  
    const renderDataProjectMobile = () => {
        if(dataLoaded) {
            if(historyProjects.length !== 0) {
                return (
                <>
                 {historyProjects.map((data, index) => (

                <>
                 <Box pb={2}>                            
                    <Accordion key={index}  >
                        <AccordionSummary                           
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid container direction="column" justify="left" alignItems="left" >
                                <Grid container direction="row" justify="space-between" style={{paddingBottom: '10px'}}>
                                    <Box className={classes.statusHistory}  >
                                        <Box id='fontTamma' color="#d57193" fontSize={13} style={{paddingTop: '3px'}}>
                                        { 
                                            data.status === 'REJECTED BY INFLUENCER' ? 'Rejected' :
                                            data.status === 'DONE' ? 'Done' :
                                            data.status
                                        }
                                        </Box>
                                    </Box>
                                    <Box className={classes.socialMedia} >
                                        <Box id='fontTamma' color="#9e69d2" fontSize={13} style={{paddingTop: '3px'}} >
                                            { data.order.platform_child === null ? '' : data.order.platform_child.name}
                                        </Box>
                                    </Box>
                                </Grid>

                                <Divider />

                                <Box pt={1} id='fontTamma' fontSize={18} color='#7c71d5'  fontWeight='bold'>
                                   {data.order.project_name}
                                </Box>
                                <Box pb={1} id='fontTamma' fontSize={15}>
                                    By : {data.order.owner_brand} 
                                </Box>

                                <Divider/>  
                                <Grid container direction="row" justify="flex-end">
                                    <Box id='fontTamma' fontSize={15} pt={1}>{moment(data.start_date).format('DD MMMM YYYY ')}</Box>                             
                                </Grid>
                                
                            </Grid>

                        </AccordionSummary>
                        
                    </Accordion>   
                    </Box>
                    
                    </>               
                                               
                    
                   
                 ))}

                </>
                )
            } else {
                return ( 
                    <Grid container direction='column' alignItems='center'  >
                        <Box className={classes.sectionNoData}>
                            <Box textAlign='center'>
                                <img src={norequest} alt='icon' className={classes.imgStyle} />
                            </Box>

                            <Box id='fontTamma' textAlign='center' pt={5} >
                                You don't have any request.
                            </Box>
                        </Box>
                    </Grid>
                )
            }
        } else {
            return (
                <SkeletonMobile/>
            )
        }
    }

    return (
        <React.Fragment>
           <AppBarUser/>            

           <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={detailProjects.dialog}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"     
                                 
            >
                
                <DialogContent style={{backgroundColor: '#fbfbfb'}} >
               
                <Grid container spacing={5} >                    
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        
                            <Box p={4} mb={4}>
                                <Grid container direction='row' justify='space-between' alignItems='center'>
                                    <div >
                                        <Box id='fontTamma' fontSize={25} color='#7c71d5' fontWeight='bold' >
                                            {detailProjects.project_name}
                                        </Box>      
                                        <Box id='fontTamma' color='#6e738a' fontSize={18} >
                                            by {detailProjects.owner_brand}
                                        </Box>                                 
                                    </div>
                                    <div>
                                        <Grid container direction='row'>                                                 

                                            <Button 
                                                className={classes.headlineButton}
                                                variant='contained' 
                                                color='primary' 
                                            >
                                                <Box className={classes.buttonText}>
                                                { 
                                                    detailProjects.status === 'REJECTED BY INFLUENCER' ? 'Rejected' :
                                                    'Done'
                                                }
                                                
                                                </Box>
                                            </Button> 
                                        </Grid> 
                                    </div> 
                                    
                                </Grid>

                                <Grid container spacing={2} className={classes.sectionContent} >
                                    <Grid item lg={5} md={5} sm={5} xs={12}>
                                        <Box pt={1}  id='fontTamma' color='#343c5a' fontSize={15} >
                                            CONTENTS
                                        </Box>
                                        <div className={classes.boxContent} >
                                            <Box p={2} >
                                                <Box>
                                                    <img src={detailProjects.link_content} alt='img content' width='100%' height='100%' />                                    
                                                </Box>
                                            </Box>
                                        </div>
                                    </Grid>
                                    <Grid item lg={7} md={7} sm={7} xs={12}>
                                        <Box pt={1}  id='fontTamma' color='#343c5a' fontSize={15} >
                                            DESCRIPTION
                                        </Box>
                                        <div className={classes.boxContent} >
                                            <Box p={2} >
                                                <Box id='fontTamma' color='#343c5a'>{detailProjects.description}</Box>
                                            </Box>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} className={classes.sectionContentBottom} >
                                    <Grid item lg={5} md={5} sm={5} xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={7} md={7} sm={7} xs={12}>
                                                <Box pt={1}  id='fontTamma' color='#343c5a' fontSize={15} >
                                                    START DATE
                                                </Box>
                                                <div className={classes.boxContentSmall} >
                                                    <Box p={1} >
                                                        <Box p={1} textAlign='center' id='fontTamma' color='#343c5a' fontWeight='bold' fontSize={17} >
                                                            {moment(detailProjects.start_date).format(' DD MMMM YYYY')}
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Grid>
                                            <Grid item  lg={5} md={5} sm={5} xs={12}>
                                                <Box pt={1}  id='fontTamma' color='#343c5a' fontSize={15} >
                                                    START TIME
                                                </Box>
                                                <div className={classes.boxContentSmall} >
                                                    <Box p={1} >
                                                        <Box p={1} textAlign='center'  id='fontTamma' color='#343c5a' fontWeight='bold' fontSize={17} >
                                                        {moment(detailProjects.start_date).format('h:mm a')}
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={7} md={7} sm={7} xs={12}>
                                        
                                    </Grid>
                                </Grid>              
                                
                                <Box mt={4} mr={1} mb={4} >

                                    <button
                                        className={classes.buttonWhite}
                                        style={{ float: 'right' }} onClick={handleClose}
                                    >
                                        <Box className={classes.textWhite} >Close <FaTimes/></Box>
                                    </button>
                                    
                                </Box>
                            </Box>
                    </Grid>
                </Grid>
            
                
                </DialogContent>
                
            </Dialog>


            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidthAttach}
                open={detailAttachment.dialog}
                onClose={handleCloseAttachment}
                aria-labelledby="max-width-dialog-title"    
                  
            >                
                <DialogContent >
               
                <Grid container spacing={5} >                    
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        
                            <Box p={2} mb={4} >

                                <Grid container direction='column' justify='center' alignItems='center' >
                                    <Box>
                                        <img src={detailAttachment.link_attach} alt='img content' width='100%' height='100%' />                                    
                                    </Box>
                                    <Box pt={2} fontWeight={600} ><a rel="noopener noreferrer" target='_blank' href={detailAttachment.link_attach}>Download Image</a></Box>
                                    <Box pt={2} >
                                        {detailAttachment.desc_attach}
                                    </Box>
                                   
                                </Grid>
                                
                                <Box pt={2} >
                                    <Button size='small' style={{ float: 'right' }} onClick={handleCloseAttachment} variant='contained' color="secondary">
                                        Close
                                    </Button>
                                </Box>
                            </Box>
                    </Grid>
                </Grid>
            
                
                </DialogContent>
                
            </Dialog>

            <Container maxWidth='lg'className={classes.rootContainer}   >
                <Grid container spacing={5} className={classes.root} >                    
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box pb={3}>
                            <Box id='fontTamma' fontSize={25} color='#7c71d5' fontWeight='bold' >
                            Projects History 
                            </Box>

                            <Box className={classes.lineDivider}></Box>

                            <Box pt={1}  id='fontTamma' color='#6e738a' fontSize={18} >
                            Here is your Project History  Data
                            </Box>
                                                        
                        </Box>

                        <Paper elevation={3} className={classes.paperproject}>
                            <Box p={2}>
                                
                                {renderDataProject()}                                        
                                
                            </Box>
                            <Grid container direction='row' justify='flex-end' >
                                <Box pt={2} pb={4} pr={6} textAlign='center' >
                                    {renderPaginate()}
                                </Box>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth='lg' className={classes.displayMobile}   >
                <Grid container spacing={5} className={classes.root} >                    
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box pb={1}>
                            <Box id='fontTamma' fontSize={24} color='#7c71d5' fontWeight='bold' >
                                Project History 
                            </Box>

                            <Box className={classes.lineDivider}></Box>
                                                                                    
                        </Box>

                        {renderDataProjectMobile()}

                        <Grid container direction='row' justify='flex-end' >
                            <Box pt={2} pb={4} pr={6} textAlign='center' >
                                {renderPaginate()}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
{/* 
           <FooterDashboard/> */}
        </React.Fragment>
    )
}

export default HistoryProjects;