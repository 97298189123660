import React from 'react';
import NavbarLanding from '../../components/landing/NavbarLanding';
import SectionBenefitsOne from '../../components/benefits/SectionBenefitsOne'
import SectionBenefitsTwo from '../../components/benefits/SectionBenefitsTwo'
import SectionBenefitsThird from '../../components/benefits/SectionBenefitsThird'
import SectionBenefitsFour from '../../components/benefits/SectionBenefitsFour'
import FooterLanding from '../../components/landing/FooterLanding'


const BenefitPage = () => {
    return (
        <React.Fragment>
            <NavbarLanding/>
            <SectionBenefitsOne/>            
            <SectionBenefitsTwo/>            
            <SectionBenefitsThird/>            
            <SectionBenefitsFour/>            
            <FooterLanding/>  
        </React.Fragment>
    )
}

export default BenefitPage;