import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    mainFeaturedPost: {
        [theme.breakpoints.up('xs')]: {
          paddingTop: '5px',
          height: 950,
        },
        [theme.breakpoints.up('sm')]: {
          paddingTop: '10px',
          height: 1150,
        },
        [theme.breakpoints.up('md')]: {
          paddingTop: '10px',
          height: 1200,
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: '30px',
          height: 1400,
          width: '100%'
        },
        [theme.breakpoints.up('xl')]: {
          paddingTop: '30px',
          height: 1550,
          width: '100%'
        }
      },
      inConclusion: {
        [theme.breakpoints.up('xs')]: {
          paddingTop: '20px'
        },
        [theme.breakpoints.up('sm')]: {
          paddingTop: '30px'
        },
        [theme.breakpoints.up('md')]: {        
          paddingTop: '40px'
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: '50px'
        }
      },
      headTextSecond: {
        fontFamily: 'Baloo Tamma 2',      
        fontWeight: 'bold',
        color: '#142461',
        [theme.breakpoints.up('xs')]: {
          fontSize: '25px',
        },      
        [theme.breakpoints.up('sm')]: {
          fontSize: '30px',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '40px',
        },
      },
      headTitleSecond: {
        color: '#bcc3de',
        [theme.breakpoints.up('xs')]: {
          fontSize: '20px',
        },      
        [theme.breakpoints.up('sm')]: {
          fontSize: '20px',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '20px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '30px',
        },
      },
      headParapgrap: {
        fontFamily: 'Baloo Tamma 2', 
        color: '#142461',
        [theme.breakpoints.up('xs')]: {
          fontSize: '15px',
        },      
        [theme.breakpoints.up('sm')]: {
          fontSize: '20px',
        },
        [theme.breakpoints.up('md')]: {        
          fontSize: '20px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '20px',
        },
      },   
  
      headlineButton: {
        backgroundColor: '#7c71d5',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '8px',
          paddingBottom: '5px',
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '3px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '13px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '50px',
          paddingRight: '50px',
          paddingTop: '18px',
          paddingBottom: '15px'
        },
      },
      buttonText:{
        color: '#fbfbfb',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'capitalize'
      },
      brandSection: {
        [theme.breakpoints.up('xs')]: {
          paddingTop: '50px',
        },      
        [theme.breakpoints.up('sm')]: {
          paddingTop: '50px',
        },
        [theme.breakpoints.up('md')]: {        
          paddingTop: '100px',
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: '150px',
        },
      },
      paperTestimoni: {
        height: '200px'      
      },
      boxLogo: {
        [theme.breakpoints.up('xs')]: {
          paddingTop: '70px',
        },      
        [theme.breakpoints.up('sm')]: {
          paddingTop: '50px',
        },
        [theme.breakpoints.up('md')]: {        
          paddingTop: '100px',
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: '100px',
        },
        [theme.breakpoints.up('xl')]: {
          paddingTop: '120px',
        },
      },

      imgLogo: {
        [theme.breakpoints.up('xs')]: {
            width: 150,           
          },      
          [theme.breakpoints.up('sm')]: {
            width: '100%',
            paddingLeft: '5px',
            paddingRight:'5px'
          },
          [theme.breakpoints.up('md')]: {        
            width: '100%',
            paddingLeft: '5px',
            paddingRight:'5px'
          },
          [theme.breakpoints.up('lg')]: {
            width: '100%',
            paddingLeft: '5px',
            paddingRight:'5px'
          },
          [theme.breakpoints.up('xl')]: {
            width: '100%',
            paddingLeft: '5px',
            paddingRight:'5px'
          },
      },
      
      nowYouKnow: {
        [theme.breakpoints.up('xs')]: {
          paddingTop: '50px',
        },      
        [theme.breakpoints.up('sm')]: {
          paddingTop: '100px',
        },
        [theme.breakpoints.up('md')]: {        
          paddingTop: '150px',
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: '200px'
        },
        [theme.breakpoints.up('xl')]: {
          paddingTop: '200px'
        },
      }
}));
