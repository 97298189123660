import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { Button, Box} from '@material-ui/core';
import './../../assets/css/main.css'
import {FaArrowRight} from "react-icons/fa";
import useStyles from './style/styleOne'


const SectionOne = () => {
 
    const classes = useStyles();

    return (
    <Paper className='mainFeaturedPostFirstLanding' >
      <Container >
        <Grid container  direction='row' justify='center' >
          <Grid item lg={12} md={12} sm={12} xs={12}  >
            <div className={classes.mainFeaturedPostContent}>
              
                <Box id='fontTamma' className={classes.headlineText} >
                  Influencers Network Platform
                </Box>

                <Box>
                  <Box id='fontTamma' className={classes.headlineCamption} >
                  We connect influencers and advertisers 
                   to create the most engaging <br/>  campaign on social media                 
                  </Box>
                </Box>
                
                <Box pt={4} textAlign='center' >
                  <Link variant="subtitle1" href="/register">
                    <Button className={classes.headlineButton} variant='contained' color='primary' size='large'>
                    <Box id='fontTamma' className={classes.buttonText}>Let get started <FaArrowRight/></Box>
                    </Button>
                  </Link>
                </Box>
              
            </div>
          </Grid>
          
        </Grid>
      </Container>
    </Paper>

    )
}

export default SectionOne;