import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    rootContainer: {       
        [theme.breakpoints.up('xs')] : {
            display:'none'
        },
        [theme.breakpoints.up('sm')] : {
            display:'block'
        },
        [theme.breakpoints.up('md')] : {
            display:'block'
        },
        [theme.breakpoints.up('lg')] : {
            display:'block'
        },
        [theme.breakpoints.up('xl')] : {
            display:'block'
        }       
    },
    displayMobile : {
        [theme.breakpoints.up('xs')] : {
            display:'block',
            height: 'fit-content'
        },
        [theme.breakpoints.up('sm')] : {
            display:'block',
            height: 'fit-content'
        },
        [theme.breakpoints.up('md')] : {
            display:'none'
        },
        [theme.breakpoints.up('lg')] : {
            display:'none'
        },
        [theme.breakpoints.up('xl')] : {
            display:'none'
        }   
    },
    root: {
        marginTop: '80px',
        marginBottom: '40px'
    },      
    papermobile: {
        marginTop: '20px',
        minHeight: 200
    },    
    rootlist: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    paperproject: {
        marginTop: '20px',
        minHeight: 700
    },
    buttonstyle: {
        marginRight: '10px',
        paddingLeft:'40px',
        paddingRight: '40px'
    },
    socialMedia : { 
        backgroundColor:'#f4e8ff', 
        borderRadius: '20px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    statusHistory : { 
        backgroundColor:'#f8edf1', 
        borderRadius: '20px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    hastagstyle: {
        color:'black',
        display:'flex',
        justifyContent: 'center'        
    },
    media: {
        height: 100,
        width: 120,
    },
    papermedia: {
        borderRadius: '15px'
    },
    table: {
        minWidth: 650,
    },
    btnaction: {
        marginRight: '5px'
    },
    titlerow: {
        fontSize: '15px',
        color: '#6e738a',
    },
    titleCell: {
        fontSize: '18px',
        color: '#6e738a',
        fontWeight: 'bold'
    },
    btnsocial: {
        borderRadius: '15px',
        marginRight: '5px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    lineDivider: {
        paddingTop: '4px',
        borderBottom: '2px solid #eeeeee'
    },

    buttonStatus :{
        backgroundColor: '#f8edf1',
        borderRadius: '20px',
        border: 'none',
        
    },
    textStatus: {
        color: '#d57193',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize:'19px'
    },
    buttonStatusDone :{
        backgroundColor: '#edf5f7',
        borderRadius: '20px',
        border: 'none',
        
    },
    textStatusDone: {
        color: '#7fbbcc',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize:'19px'
    },
    buttonWhite: {       
        textAlign: 'left',
        backgroundColor: '#fbfbfb',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '5px',
          paddingBottom: '8px'
        },
    },
    textWhite: {
        color: '#343c5a',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '15px',
        textTransform: 'capitalize'
    },
    headlineButton: {
        textAlign: 'left',
        backgroundColor: 'red',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingTop: '7px',
          paddingBottom: '7px'
        },
      },
      buttonText:{
          color: '#fbfbfb',
          fontFamily: 'Baloo Tamma 2',
          fontWeight: 700,
          fontSize: '15px',
          textTransform: 'capitalize'
      },
      buttonTextPending: {
        paddingTop: '7px',
        marginRight: '15px',
        color: '#d57193',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '15px',
        textTransform: 'capitalize'
      },
      rowTableHover: {
        "&:hover": {
            cursor: 'pointer'     ,
            boxShadow: '0 8px 6px -6px #bcc3de'
        },
      },
      sectionContent: {
          paddingTop: '20px'
      },
      sectionContentBottom: {
        paddingTop: '5px'
      },
      boxContent: {
          border : '1px solid #bcc3de',
          height: 'fit-content',
          minHeight: 250
      },
      boxContentSmall : {
        border : '1px solid #bcc3de',
        height: 'fit-content',
      },
      imgStyle: {
        [theme.breakpoints.up('xs')]: {
            width: 150,
            textAlign: 'center'
        },      
        [theme.breakpoints.up('sm')]: {
            width: 200,
            textAlign: 'center'
        },
        [theme.breakpoints.up('md')]: {        
            width: 200,
            textAlign: 'center'
        },
        [theme.breakpoints.up('lg')]: {
            width: 250,
            textAlign: 'center'
        },
        [theme.breakpoints.up('xl')]: {
            textAlign: 'center',
            width: 250
        },
    },
    sectionNoData: {
        
        [theme.breakpoints.up('xs')]: {
            marginTop: '70px',
            marginBottom: '100px'
        },      
        [theme.breakpoints.up('sm')]: {
            marginTop: '70px',
            marginBottom: '100px'
        },
        [theme.breakpoints.up('md')]: {        
            marginTop: '70px',
            marginBottom: '100px'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '70px',
            marginBottom: '150px'
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: '150px',
            marginBottom: '200px'
        },
    },
}));
