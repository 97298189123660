import React, {useEffect, useState} from 'react';
import AppBarUser from '../../components/AppBarUser';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Box,
    Button
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert';
import { API, TokenUser } from './../../config'
// import { useDispatch } from 'react-redux';
import Axios from 'axios'
import MenuSideUser from '../../components/MenuSIdeUser'
// import CircularProgress from '@material-ui/core/CircularProgress';
import {Form} from 'react-bootstrap'
// import Button from '@material-ui/core/Button';
// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';
// import { FiInstagram, FiFacebook, FiTwitter } from "react-icons/fi";


// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
    root : {
      marginBottom: '100px',
      marginTop: '130px'  
    },
    containerdiv: {
      textAlign: 'center'
    },
    rootComponent: {        
        [theme.breakpoints.up('xs')]: {
            paddingTop: '100px',
            paddingBottom: '100px',
        }, 
        [theme.breakpoints.up('sm')]: {
            paddingTop: '100px',
            paddingBottom: '100px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
    },
    lineDivider: {
        paddingTop: '7px',
        borderBottom: '2px solid #eeeeee'
    },
    headlineButton: {
        textAlign: 'left',
        backgroundColor: '#7c71d5',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '7px',
          paddingBottom: '7px'
        },
      },
      buttonText:{
          color: '#fbfbfb',
          fontFamily: 'Baloo Tamma 2',
          fontWeight: 700,
          fontSize: '15px',
          textTransform: 'capitalize'
      },
      formSection: {
            paddingTop: '15px'
      },
      loadingCircleUpdate: {
        paddingLeft: '50px',
        paddingRight: '50px'
    }
    
  }));

const PriceBook = () => {
    const classes = useStyles();
    
    const [pricebook, setPricebook] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false)
    // const [loading, setLoading] = useState(false)
    // const dispatch = useDispatch()

    const [updatePrice, setUpdatePrice] = useState({
        id: '',
        post_price: '',
        ppc_price: '',
        name: "",
        modal: false
    })
    
    // console.log(pricebook,'iki pricebook data ')

    useEffect(()=>{       
        if(TokenUser) {
            const loadPriceBook = () => {
            // setLoading(true)
                Axios.get(`${API}/influencer/pricebook`, {
                    headers: {                    
                        Authorization: `Bearer ${TokenUser}`
                    }
                })
                .then((res) => { 
                    if(res.data.status) {
                        setPricebook(res.data.result)
                        setDataLoaded(true)
                    }   
                }).catch((err) => {
                    console.log(err)
                    // setLoading(false)
                })        
            }
            loadPriceBook()      
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const onClickSubmit = async ()  => {
        // setLoading(true)'
    // console.log(updatePrice, 'update prce')
        let data = {            
            "id": updatePrice.id,
            "post_submision_price": updatePrice.post_price,
            "ppc_submision_price": updatePrice.ppc_price,
            "submision_status": "ACCEPTED"
            // "interest": accountPlatform.interest            
        }
        const post = await Axios.put(`${API}/influencer/pricebook`, data, {
            headers: {                    
                Authorization: `bearer ${TokenUser}`
                }            
            })
            .then((res) => {
                // console.log(res.data, 'responsee')
                swal("Good job!", `${res.data.message}`, "success");
                setTimeout(() => {
                    swal.close()
                    window.location.reload()
                }, 1500);
                // setLoading(false)
            }).catch((err) => {
                console.log(err, 'errrorrr')
                // setLoading(false)
                swal("Error", `${err.reponse.data.message}`, "error");
            })      
        
        return post;

    }

    const renderDataPricebook = () => {
        if(dataLoaded) {
            return (
                <Box className={classes.formSection}>
                    <Form>
                        <Grid container spacing={2} >  
                            {pricebook.map((data, index)=> (
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box pt={3} pb={1} id='fontTamma' color='#343c5a' fontWeight={600} fontSize={19} >
                                        {data.platformChild.name}
                                    </Box>
                                    <Box style={{backgroundColor: '#dcd8f4', borderRadius: '10px'}}>
                                        <Box p={2}  color='#343c5a' fontWeight={600} fontSize={17}>
                                            Rp. {data.post_price.toLocaleString()}
                                        </Box>
                                    </Box>
                                    <Box pt={1} 
                                        style={{cursor: 'pointer', color: '#363d87', fontWeight: 'bold'}}
                                        onClick={() => {
                                            setUpdatePrice({...updatePrice, 
                                                id : data.id,
                                                post_price: data.post_price,
                                                ppc_price: data.ppc_price,
                                                name: data.platformChild.name,
                                                modal: true
                                            })
                                        }}
                                    
                                    >Change Price</Box>
                                </Grid>
                            ))}

                            
                        </Grid>
                    </Form>
                </Box>
            )
        }
    }
    
    return (
    <React.Fragment>
       
    <AppBarUser/>           
        <Container maxWidth='lg'>
            <Grid container spacing={2} >
                <Grid item lg={3} md={3}  >
                    <MenuSideUser/>
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12} >
                    <div className={classes.rootComponent}>
                        <Box>
                            <Box id='fontTamma' fontSize={25} color='#343c5a' >
                                <span style={{fontWeight:'bold', color:'#7c71d5'}}>{localStorage.getItem('nama') + ' '}</span>
                                - Pricebook
                            </Box>
                        </Box>
                        <Box className={classes.lineDivider}></Box>
                        <Box pt={1} id='fontTamma' color='#6e738a' fontSize={18} >
                            Here is your pricebook list.
                        </Box>

                        {renderDataPricebook()}

                        
                    </div>
                </Grid>
            </Grid>            
        </Container>


        <Dialog 
            open={updatePrice.modal} 
            onClose={() => setUpdatePrice({...updatePrice, modal: false})} 
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="form-dialog-title">Update Price {updatePrice.name} </DialogTitle>
            <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Post Price"
                type="number"
                defaultValue={updatePrice.post_price}
                onChange={(e) => setUpdatePrice({...updatePrice, post_price: e.target.value})}
                fullWidth
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setUpdatePrice({...updatePrice, modal: false})} color="primary">
                Cancel
            </Button>
            <Button color="primary" variant='contained' onClick={onClickSubmit} >
                Submit
            </Button>
            </DialogActions>
        </Dialog>
        
    </React.Fragment>
    )
}

export default PriceBook;