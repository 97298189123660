import React, {useState} from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button, 
  Link,
  Container,
  Grid,
  Box  
} from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import {Form, } from 'react-bootstrap'
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import signup from '../../assets/img/signup.png'
import logo_white from '../../assets/img/kolia_white.svg'
import logo_color from '../../assets/img/kolia_color.png'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { 
  userLogin
} from '../../redux'

const useStyles = makeStyles((theme) => ({
  componentRoot : {    
    [theme.breakpoints.up('xs')]: {
      height: 400,
    },
    [theme.breakpoints.up('sm')]: {      
      height: 400,
    },
    [theme.breakpoints.up('md')]: {   
      height: 600,
    },
    [theme.breakpoints.up('lg')]: {   
      height: 750,
      
    },
    [theme.breakpoints.up('xl')]: {
      height: 980,
      
    },
  },
  componentLeft: {
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {      
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {   
      display:'flex'
    },
    [theme.breakpoints.up('lg')]: {   
      display:'flex'
    },
    [theme.breakpoints.up('xl')]: {
      display:'flex'
    },
  },
  textBottomSign: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      textAlign: 'center'
    },
    [theme.breakpoints.up('sm')]: {      
      display: 'flex',
    },
    [theme.breakpoints.up('md')]: {   
      display:'none'
    },
    [theme.breakpoints.up('lg')]: {   
      display:'none'
    },
    [theme.breakpoints.up('xl')]: {
      display:'none'
    },
  },
  imageBackground: {
    
    [theme.breakpoints.up('md')]: {   
     width: '100%'
    },
    [theme.breakpoints.up('lg')]: {   
     width: '100%'
    },
    [theme.breakpoints.up('xl')]: {
     width: '100%'
    }
  },
  logoWhite: {
    [theme.breakpoints.up('md')]: {   
      width: '100px'
     },
     [theme.breakpoints.up('lg')]: {   
      width: '130px'
     },
     [theme.breakpoints.up('xl')]: {
      width: '150px'
     }
  },
  boxAbsolute: {
    position: 'absolute',
    textAlign: 'center',    
    [theme.breakpoints.up('md')]: {   
      top: 100,
      paddingRight: '40px'
     },
     [theme.breakpoints.up('lg')]: {   
      top: 120,
      paddingRight: '50px'
     },
     [theme.breakpoints.up('xl')]: {
      top: 150,
      left: 65,
     }
  },
  boxAbsolute2: {
    position: 'absolute',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {   
      bottom: 100,
      left: 95
     },
     [theme.breakpoints.up('lg')]: {   
      bottom: 130,
      left: 105
     },
     [theme.breakpoints.up('xl')]: {
      bottom: 130,
      left: 165
     }
  
  },
  headlineCamptionLeft: {
    color: '#fbfbfb',
    textAlign: 'center',
    fontFamily: 'Baloo Tamma 2',
    lineHeight: 1.5,
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '15px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '23px',
    }
  },
  headlineCamptionBottom : {
    color: '#7c71d5',    
    fontFamily: 'Baloo Tamma 2',
    lineHeight: 1.5,
    [theme.breakpoints.up('xs')]: {
      fontSize: '20px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      textAlign: 'center',
    },
  
  },
  imgRelative: {
    position: 'relative'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  facebookclass: {
    width: '100%',
    height: '45px',
    backgroundColor: '#4267B2',
    color: '#fff',
    border: 'none',
    fontSize: '14px'
  },
  imageLeft: {
    height: '100%',
    width: '100%',
  },
  formRight: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: '40px'
    },
    [theme.breakpoints.up('sm')]: {      
     paddingTop: '100px'
    },
    [theme.breakpoints.up('md')]: {   
     paddingTop: '150px'
    },
    [theme.breakpoints.up('lg')]: {   
     paddingTop: '200px'
    },
    [theme.breakpoints.up('xl')]: {
     paddingTop: '300px'
    },
  },
  headTextSecond: {
    fontFamily: 'Baloo Tamma 2',      
    fontWeight: 'bold',
    color: '#142461',
    [theme.breakpoints.up('xs')]: {
      fontSize: '25px',
    },      
    [theme.breakpoints.up('sm')]: {
      fontSize: '25px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '25px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '30px',
    },
  },
  imgformobile: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('sm')]: {      
      display: 'flex',
    },
    [theme.breakpoints.up('md')]: {   
      display:'none'
    },
    [theme.breakpoints.up('lg')]: {   
      display:'none'
    },
    [theme.breakpoints.up('xl')]: {
      display:'none'
    },
  },
  headlineCamption: {
    color: '#142461',
    textAlign: 'left',
    fontFamily: 'Baloo Tamma 2',
    lineHeight: 1.5,
    [theme.breakpoints.up('xs')]: {
      fontSize: '15px',
      paddingTop: '10px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
    }
  },

  
  headlineButton: {
    backgroundColor: '#7c71d5',
    [theme.breakpoints.up('xs')]: {
      borderRadius: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '5px',
      paddingBottom: '5px',
      width: '100%'
    },      
    [theme.breakpoints.up('sm')]: {
      borderRadius: '30px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '1px',
      paddingBottom: '1px',
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {        
      borderRadius: '30px',
      paddingLeft: '40px',
      paddingRight: '40px',
      paddingTop: '5px',
      paddingBottom: '5px'
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '30px',
      paddingLeft: '40px',
      paddingRight: '40px',
      paddingTop: '5px',
      paddingBottom: '5px'
    },
  },
  buttonText:{
    color: '#fbfbfb',
    fontFamily: 'Baloo Tamma 2',
    fontWeight: 500,
    fontSize: '18px',
    textTransform: 'capitalize',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  loadingCircle: {
    marginTop: '5px'
  },
}));

const SignInComponent = () => {
  const classes = useStyles();
  
  const [signinEmail, setSigninEmail] = useState ({
    email: '',
    password: ''
  })

  const handleChangeLogin = email => event => {
    setSigninEmail({...signinEmail, [email]: event.target.value})
  }

  // dispatch action
  const dispatch = useDispatch()

  // selector
   const responall = useSelector( state => state.response_register_email.response_login )
   const loading = useSelector( state => state.response_register_email )
  //  const redirect = useSelector( state => state.response_register_email.response_login.data )

  // console.log(responall, 'respon loading')
  
  const showError = () => {
    if(responall.message === 'Welcome to website!') {
      return (
        <Alert style={{display: 'none' }} severity="error">
          none
        </Alert>
      )
    } else {
      return (
        <Alert style={{display: responall.message ? '' : 'none' }} severity="error">
        {responall.message}
        </Alert>
      )
    }
  }

  const redirectUser = () => {
    if(localStorage.getItem('_@tKn_iD&&')) {
      if(localStorage.getItem('acc_sttg') === 'true') {
        return (
          <Redirect to='/social-profiles' />          
        )
      } else {            
        return (
          <Redirect to='/dashboard' />          
        )
      }
    }
  }      

  const onClickSubmit = (e) => {
    e.preventDefault()
    dispatch(userLogin(signinEmail))
    // console.log(signinEmail, 'data sign in')
  }
  

  return (
    <>
   
    <Grid container className={classes.componentRoot} >    
      <Grid item lg={5} md={6} sm={12} xs={12} className={classes.componentLeft} >
        <Box className={classes.imgRelative} >
          <img src={signup} alt='signup' className={classes.imageBackground} />
            <Box className={classes.boxAbsolute} >
              <Container maxWidth='sm' style={{textAlign: 'center', paddingRight: '15px'}} >
                <Box >
                  <a href='/'>
                  <img src={logo_white} alt='logo' className={classes.logoWhite}/>
                  </a>
                </Box>
                <Box pt={2} >
                  <Box id='fontTamma' className={classes.headlineCamptionLeft} >
                  Let us help you run and scale a profitable digital business that gives you the freedom to do more of what you love.
                  </Box>
                </Box>
              </Container>
            </Box>
            <Box className={classes.boxAbsolute2} >
              <Container maxWidth='sm' >
               
                <Box pt={2} >
                  <Box id='fontTamma' className={classes.headlineCamptionLeft} >
                  Don't have an account yet?  <a href='/register' style={{color:'#fbfbfb'}} ><strong>Sign Up!</strong></a>
                  </Box>
                </Box>
              </Container>
            </Box>
        </Box>
      </Grid>

      <Grid item lg={7} md={6} sm={12} xs={12}>
        <Box className={classes.formRight} >
        
          <Container maxWidth='sm'  >
            <Box pb={2} className={classes.imgformobile} >
              <a href='/'><img alt='logo' src={logo_color} width='120px' /></a>
            </Box>

            <Box className={classes.headTextSecond}  >Alriteeee, let's start it!</Box>

          <Form className={classes.form} onSubmit={onClickSubmit} validate >
            {redirectUser()}
            {showError()}

            
            <Form.Group controlId="exampleForm.ControlIn1">
              <Form.Label><Box id='fontTamma' color='#142461' >EMAIL</Box> </Form.Label>
              <Form.Control autoFocus required type="email" placeholder="name@example.com" onChange={handleChangeLogin('email')} />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlIna2">
              <Form.Label><Box id='fontTamma' color='#142461' >PASSWORD </Box></Form.Label>
              <Form.Control required type="password" placeholder="*******" onChange={handleChangeLogin('password')} />
            </Form.Group>

            <Grid container direction='row' justify='space-between' >
              <Box  >
                <FormControlLabel
                  control={<Checkbox name="checkedA" />}
                  label="Remember me"
                />
              </Box>

              <Box id='fontTamma' pt={1}>
                <a href='/forgot' style={{color:'#7c71d5'}} >Forgot Password?</a>
              </Box>
            </Grid>
            
            <Box pt={4} textAlign='center' >
              <Link variant="subtitle1" href="/register">
                <Button type='submit' className={classes.headlineButton} variant='contained' color='primary' size='large'>
                  
                  { loading.loading ? 
                    <Box id='fontTamma' className={classes.loadingCircle}>
                      <CircularProgress color="action"  /> 
                    </Box>
                    :
                    <Box id='fontTamma' className={classes.buttonText}>Sign in</Box>
                  }
                </Button>
              </Link>
            </Box>

            <Box pt={2} className={classes.textBottomSign} >
              <Grid container direction='row' justify='center' >
                <Box id='fontTamma' className={classes.headlineCamptionBottom} >
                  Don't have an account yet?  <a href='/register' style={{color:'#7c71d5'}} ><strong>Sign Up!</strong></a>
                </Box>
              </Grid>
            </Box>
                            
          </Form>
          </Container>
          
        </Box>
      </Grid>
    
    </Grid>   

    </>
  );
  }

export default SignInComponent