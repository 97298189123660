import React from 'react';
import SignInComponent from '../../components/signin/SignInComponent';

const SignInPage = () => {
    return (
        <React.Fragment>               
            <SignInComponent/>           
        </React.Fragment>
    )
}

export default SignInPage;