import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { Button, Box } from '@material-ui/core';
import {FaArrowRight} from "react-icons/fa";
import useStyles from './style/styleFive'

  
  const SectionFive = () => {
 
    const classes = useStyles();

    return (
    <Paper id='fontTamma' className='mainFeaturedPostSection5' >
      
      <Container maxWidth="lg" className={classes.mainFeaturedPost}>

        <Box textAlign='center' className={classes.inConclusion} >
            <Box  className={classes.headTitleSecond} >
              in conclusion
            </Box>
            <Box className={classes.headTextSecond}  >
              They love Kolia.
            </Box>
        </Box>
        
        <Grid container spacing={2} className={classes.boxLogo} direction="row" justify="center" alignItems="center" >
          
          <Grid >
            <Box >
              <img 
                src={require("../../assets/img/logocompany1.png")}
                className={classes.imgLogo}
                alt="logo 2"
                />
            </Box>
          </Grid>

          <Grid >
            <Box >
            <img 
                src={require("../../assets/img/logocompany1.png")}
                className={classes.imgLogo}
                alt="logo 1"
                />
            </Box>
          </Grid>

          <Grid >
            <Box >
            <img 
                src={require("../../assets/img/logocompany1.png")}
                className={classes.imgLogo}
                alt="logo 2"
                />
            </Box>
          </Grid>

        </Grid>


        <Box textAlign='center' className={classes.nowYouKnow} >
            <Box  className={classes.headTitleSecond} >
             now you know what to do
            </Box>
            <Box className={classes.headTextSecond}  >
              If you are an influencer
            </Box>
            <Box className={classes.headParapgrap}  >
            and you are interested in creating original content then get paid.
            </Box>

            <Box pt={4} textAlign='center' >
              <Link variant="subtitle1" href="/register">
                <Button className={classes.headlineButton} variant='contained' color='primary' size='large'>
                  <Box id='fontTamma' className={classes.buttonText}>Sign up here <FaArrowRight/></Box>
                </Button>
              </Link>
            </Box>

            <Box className={classes.brandSection} >
              <Box className={classes.headTextSecond}  >
              If you are a brand
              </Box>
              <Box className={classes.headParapgrap}  >
              finding the right influencers to collaborate with.
              </Box>

              <Box pt={4} textAlign='center' >
              <Link variant="subtitle1" href="https://www.mediacartz.com">
                <Button className={classes.headlineButton} variant='contained' color='primary' size='large'>
                <Box id='fontTamma' className={classes.buttonText}>Create project <FaArrowRight/></Box>
                </Button>
              </Link>
              </Box>
            </Box>



        </Box>
        
        
      </Container>
    </Paper>

    )
}

export default SectionFive;