import React from 'react';
import AppBarUser from '../../components/AppBarUser';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Box,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuSideUser from '../../components/MenuSIdeUser'
import {Form} from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
    root : {
      marginBottom: '100px',
      marginTop: '130px'  
    },
    containerdiv: {
      textAlign: 'center'
    },
    rootComponent: {
        paddingTop: '150px'
    },
    lineDivider: {
        paddingTop: '7px',
        borderBottom: '2px solid #eeeeee'
    },
    headlineButton: {
        textAlign: 'left',
        backgroundColor: '#7c71d5',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '7px',
          paddingBottom: '7px'
        },
      },
      buttonText:{
          color: '#fbfbfb',
          fontFamily: 'Baloo Tamma 2',
          fontWeight: 700,
          fontSize: '15px',
          textTransform: 'capitalize'
      },
      formSection: {
            paddingTop: '15px'
      }
    
  }));

const AccountSetting = () => {
    const classes = useStyles();
    

    return (
    <React.Fragment>
    <AppBarUser/>           
        <Container maxWidth='lg'>
            <Grid container spacing={2} >
                <Grid item lg={3} md={3} sm={3} xs={3} >
                    <MenuSideUser/>
                </Grid>
                <Grid item lg={9} md={9} sm={9} xs={9} >
                    <div className={classes.rootComponent}>
                        <Box>
                            <Box id='fontTamma' fontSize={25} color='#343c5a' >
                                <span style={{fontWeight:'bold', color:'#7c71d5'}}>{localStorage.getItem('nama') + ' '}</span>
                                - Account Setting
                            </Box>
                        </Box>
                        <Box className={classes.lineDivider}></Box>
                        <Box pt={1} id='fontTamma' color='#6e738a' fontSize={18} >
                            What content you talk about most of the time?
                        </Box>

                        <Box className={classes.formSection}>
                        <Form>
                            <Grid container >  
                                <Grid item lg={10} md={10} sm={10} xs={10}>
                                <Box pt={4} id='fontTamma' color='#343c5a' fontWeight={600} fontSize={19} >
                                    Change Email
                                </Box>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >EMAIL</Box></Form.Label>
                                    <Form.Control required type="email" placeholder="Full Name" value='aris@gmail.com'  />
                                </Form.Group>

                                </Grid>

                                <Grid item lg={10} md={10} sm={10} xs={10}>
                                <Box pt={4} id='fontTamma' color='#343c5a' fontWeight={600} fontSize={19} >
                                    Change Password
                                </Box>
                                <Form.Group controlId="exampleForm.ControlInput2">
                                    <Form.Label><Box id='fontTamma' color='#142461' fontSize={15} >PASSWORD </Box> </Form.Label>
                                    <Form.Control required type="password" placeholder="*******" />
                                </Form.Group>
                                </Grid>

                                <Grid item lg={10} md={10} sm={10} xs={10}>
                                    <Box pt={2}>
                                    <Grid container direction='row' justify='flex-end'  >
                                        <Button className={classes.headlineButton} variant='contained' color='primary' >
                                            <Box className={classes.buttonText}>Save Changes</Box>
                                        </Button>
                                    </Grid>
                                    </Box>
                                </Grid>

                                
                            </Grid>
                                
                            </Form>
                        </Box>


                    </div>
                </Grid>
            </Grid>            
        </Container>
        
    </React.Fragment>
    )
}

export default AccountSetting;