import React, {useState} from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button, 
  Link,
  Container,
  Grid,
  Box
} from '@material-ui/core';
import {Form } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles';
import signup from '../../assets/img/signup.png'
import logo_white from '../../assets/img/kolia_white.svg'
import logo_color from '../../assets/img/kolia_color.png'
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from 'axios'
import { API } from '../../config'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
  componentRoot : {    
    [theme.breakpoints.up('xs')]: {
      height: 400,
    },
    [theme.breakpoints.up('sm')]: {      
      height: 400,
    },
    [theme.breakpoints.up('md')]: {   
      height: 400,
    },
    [theme.breakpoints.up('lg')]: {   
      height: 600,
      width: '100%'
    },
    [theme.breakpoints.up('xl')]: {
      height: 980,
      width: '100%'
    },
  },
  componentLeft: {
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {      
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {   
      display:'flex'
    },
    [theme.breakpoints.up('lg')]: {   
      display:'flex'
    },
    [theme.breakpoints.up('xl')]: {
      display:'flex'
    },
  },
  imageBackground: {
    
    [theme.breakpoints.up('md')]: {   
     width: '100%'
    },
    [theme.breakpoints.up('lg')]: {   
     width: '100%'
    },
    [theme.breakpoints.up('xl')]: {
     width: '100%'
    }
  },
  logoWhite: {
    [theme.breakpoints.up('md')]: {   
      width: '100px'
     },
     [theme.breakpoints.up('lg')]: {   
      width: '130px'
     },
     [theme.breakpoints.up('xl')]: {
      width: '150px'
     }
  },
  boxAbsolute: {
    position: 'absolute',
    textAlign: 'center',    
    [theme.breakpoints.up('md')]: {   
      top: 100,
      paddingRight: '40px'
     },
     [theme.breakpoints.up('lg')]: {   
      top: 120,
      paddingRight: '50px'
     },
     [theme.breakpoints.up('xl')]: {
      top: 150,
      left: 65,
     }
  },
  boxAbsolute2: {
    position: 'absolute',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {   
      bottom: 100,
      left: 95
     },
     [theme.breakpoints.up('lg')]: {   
      bottom: 130,
      left: 125
     },
     [theme.breakpoints.up('xl')]: {
      bottom: 130,
      left: 195
     }
  
  },
  headlineCamptionLeft: {
    color: '#fbfbfb',
    textAlign: 'center',
    fontFamily: 'Baloo Tamma 2',
    lineHeight: 1.5,
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '12px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '17px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '23px',
    }
  },
  headlineCamptionLeftBottom: {
    color: '#fbfbfb',
    textAlign: 'center',
    fontFamily: 'Baloo Tamma 2',
    lineHeight: 1.5,
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '15px',
      paddingLeft: '20px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '17px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '23px',
    }
  },
  headlineCamptionBottom: {
    color: '#7c71d5',
    textAlign: 'center',
    fontFamily: 'Baloo Tamma 2',
    lineHeight: 1.5,
    [theme.breakpoints.up('xs')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '25px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '23px',
    }
  },
  imgRelative: {
    position: 'relative'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
  imageLeft: {
    height: '100%',
    width: '100%',
  },
  formRight: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: '40px'
    },
    [theme.breakpoints.up('sm')]: {      
     paddingTop: '40px'
    },
    [theme.breakpoints.up('md')]: {   
     paddingTop: '40px'
    },
    [theme.breakpoints.up('lg')]: {   
     paddingTop: '200px'
    },
    [theme.breakpoints.up('xl')]: {
     paddingTop: '250px'
    },
  },
  headTextSecond: {
    fontFamily: 'Baloo Tamma 2',      
    fontWeight: 'bold',
    color: '#142461',
    [theme.breakpoints.up('xs')]: {
      fontSize: '25px',
    },      
    [theme.breakpoints.up('sm')]: {
      fontSize: '25px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '25px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '30px',
    },
  },
  imgformobile: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('sm')]: {      
      display: 'flex',
    },
    [theme.breakpoints.up('md')]: {   
      display:'none'
    },
    [theme.breakpoints.up('lg')]: {   
      display:'none'
    },
    [theme.breakpoints.up('xl')]: {
      display:'none'
    },
  },
  headlineCamption: {
    color: '#142461',
    textAlign: 'left',
    fontFamily: 'Baloo Tamma 2',
    lineHeight: 1.5,
    [theme.breakpoints.up('xs')]: {
      fontSize: '15px',
      paddingTop: '10px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.up('md')]: {        
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
    }
  },

  
  headlineButton: {
    backgroundColor: '#7c71d5',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      borderRadius: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '5px',
      paddingBottom: '5px',
    },      
    [theme.breakpoints.up('sm')]: {
      borderRadius: '30px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '1px',
      paddingBottom: '1px'
    },
    [theme.breakpoints.up('md')]: {        
      borderRadius: '30px',
      paddingLeft: '40px',
      paddingRight: '40px',
      paddingTop: '5px',
      paddingBottom: '5px'
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '30px',
      paddingLeft: '40px',
      paddingRight: '40px',
      paddingTop: '5px',
      paddingBottom: '5px'
    },
  },
  buttonText:{
    color: '#fbfbfb',
    fontFamily: 'Baloo Tamma 2',
    fontWeight: 500,
    fontSize: '18px',
    textTransform: 'capitalize',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  loadingCircle: {
    marginTop: '5px'
  },
  textBottomSign: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      textAlign: 'center'
    },
    [theme.breakpoints.up('sm')]: {      
      display: 'flex',
    },
    [theme.breakpoints.up('md')]: {   
      display:'none'
    },
    [theme.breakpoints.up('lg')]: {   
      display:'none'
    },
    [theme.breakpoints.up('xl')]: {
      display:'none'
    },
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  
  // console.log(response_register_email, 'responseemail')

  const [forgotPass, setForgotPass] = useState({
    email: '',
    
  })

  const [redirectPage, setRedirectPage] = useState(false)
  const [loading, setLoading] = useState(false)

  const [alertError, setAlertError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [alertSuccess, setAlertSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")

  // console.log('registerbyemail',forgotPass)

  const handleChangeForgot = fullname => event => {
    setForgotPass({
      ...forgotPass, 
      [fullname]: event.target.value 
    })
  }
 
  
  const onClickSubmit =  (e) => {
    e.preventDefault()
    
    setLoading(true)
    setTimeout( async () => {
      let body = {
        email : forgotPass.email
      }
  
      const forgot = await Axios.post(`${API}/auth/forgot`, body)
      .then((res) => {
          // console.log(res.data , 'response email')
          setSuccessMessage(res.data.message)
          setLoading(false)
          setAlertSuccess(true)
          setTimeout(() => {
            setRedirectPage(true)
          }, 1500);
      }).catch((err) => {
          // console.log(err.reponse , 'response error')
          setErrorMessage("We cannot find the user email, please contact administrator")
          setLoading(false)
          setAlertError(true)
      })
      return forgot
    }, 1000);
    
  }

  
  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertError(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertSuccess(false);
  };

  const redirectUser = () => {        
    if(redirectPage) {
      return (
        <Redirect to="/forgot/info"/>
      )
    } 
  }

 

  return (
    <>
   
   {redirectUser()}

    <div className={classes.root}>
        <Snackbar 
            open={alertError} 
            autoHideDuration={3000} 
            onClose={handleCloseError}
            anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}
        >
            <Alert onClose={handleCloseError} severity="error">
            <Box className={classes.textError} >{errorMessage}</Box>
            </Alert>
        </Snackbar>

        <Snackbar 
            open={alertSuccess} 
            autoHideDuration={2000} 
            onClose={handleCloseSuccess}
            anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}
        >
            <Alert onClose={handleCloseSuccess} severity="success">
            <Box className={classes.textError} >{successMessage}</Box>
            </Alert>
        </Snackbar>
    </div>

    <Grid container className={classes.componentRoot} >    
      <Grid item lg={5} md={6} sm={12} xs={6} className={classes.componentLeft} >
        <Box className={classes.imgRelative} >
          <img src={signup} alt='signup' className={classes.imageBackground} />
            <Box className={classes.boxAbsolute} >
              <Container maxWidth='sm' >
                <Box >
                  <a href='/'>
                    <img src={logo_white} alt='logo' className={classes.logoWhite}/>
                  </a>
                </Box>
                <Box pt={2} >
                  <Box id='fontTamma' className={classes.headlineCamptionLeft} >
                  Do you have power to influence your followers? Become a Kolia Influencer and get yourself discovered to a thousand of campaigns now.
                  </Box>
                </Box>
              </Container>
            </Box>
            <Box className={classes.boxAbsolute2} >
              <Container maxWidth='sm' >
               
                <Box pt={2} >
                  <Box id='fontTamma' className={classes.headlineCamptionLeftBottom} >
                    Already a Kolia user? <a href='/login' style={{color:'#fbfbfb'}} ><strong>Sign In</strong></a>
                  </Box>
                </Box>
              </Container>
            </Box>
        </Box>
      </Grid>

      <Grid item lg={7} md={6} sm={12} xs={12}>
        <Box className={classes.formRight} >
        
          <Container maxWidth='sm'  >
            <Box pb={2} className={classes.imgformobile} >
            <a href='/'><img alt='logo' src={logo_color} width='120px' /></a>
            </Box>
            <Box pb={2} className={classes.headTextSecond}  >Let's reset your password.</Box>

          <Form className={classes.form} onSubmit={onClickSubmit} validate >
        
         

            <Form.Group controlId="exampleForm.ControlInput2">
              <Form.Label><Box id='fontTamma' color='#142461' >EMAIL </Box> </Form.Label>
              <Form.Control required type="email" placeholder="Enter your email Address" onChange={handleChangeForgot('email')} />
            </Form.Group>


            <Box pt={2} >
              <Box id='fontTamma' className={classes.headlineCamption} >
                <a href='/login' style={{color:'#7c71d5'}} >Back to Login</a>
              </Box>
            </Box>
            
            <Box pt={4} textAlign='center' >
              <Link variant="subtitle1" href="/register">
                <Button type='submit' className={classes.headlineButton} variant='contained' color='primary' size='large'>
                { loading ? 
                  <Box id='fontTamma' className={classes.loadingCircle}>
                    <CircularProgress color="action"  /> 
                  </Box>
                  :
                  <Box id='fontTamma' className={classes.buttonText}>
                    Submit
                  </Box>
                }
                </Button>
              </Link>
            </Box>

            <Box pt={2} className={classes.textBottomSign} >
              <Grid container direction='row' justify='center' >
                <Box id='fontTamma' className={classes.headlineCamptionBottom} >
                Already a Kolia user? <a href='/login' style={{color:'#7c71d5'}} ><strong>Sign In</strong></a>
                </Box>
              </Grid>
            </Box>
                            
          </Form>
          </Container>
          
        </Box>
      </Grid>
    
    </Grid>   

    </>
  );
  }

export default ForgotPassword