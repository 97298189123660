import React from 'react';
// import { 
//     Grid,     
// } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonMobile = () => {
    return (
        <React.Fragment>
        <div>

        <Skeleton variant="rect" height={110} />  
        <br/>
        <Skeleton variant="rect" height={110} /> 
        <br/>
        <Skeleton variant="rect" height={110} />  
            
        </div>
        </React.Fragment>
    )
}

export default SkeletonMobile;