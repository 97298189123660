import React, {useEffect, useState} from 'react';
import AppBarUser from '../../components/AppBarUser';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Box,
    Button,
    Link
} from '@material-ui/core';
import { API, TokenUser } from '../../config'
import { useDispatch } from 'react-redux';
import Axios from 'axios'
import MenuSideUser from '../../components/MenuSIdeUser'
import {Form} from 'react-bootstrap'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root : {
      marginBottom: '100px',
      marginTop: '130px'  
    },
    containerdiv: {
      textAlign: 'center'
    },
    rootComponent: {
        paddingTop: '150px',
        paddingBottom: '150px',
        [theme.breakpoints.up('xs')]: {
            paddingTop: '100px',
            paddingBottom: '80px',
        }, 
        [theme.breakpoints.up('sm')]: {
            paddingTop: '100px',
            paddingBottom: '100px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: '150px',
            paddingBottom: '150px',
        },
    },
    lineDivider: {
        paddingTop: '7px',
        borderBottom: '2px solid #eeeeee'
    },
    instagramConnect: {       
        textAlign: 'left',
        backgroundColor: '#fbfbfb',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '5px',
          paddingBottom: '8px'
        },
      },
      buttonTextCOnnect: {
        color: '#343c5a',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '15px',
        textTransform: 'capitalize'
      },
      instagram: {
        "&:hover": {
            backgroundColor: "#fbfbfb !important", 
            color: "#7c71d5 !important",   
            border: '1px solid black'          
        },
        textAlign: 'left',        
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '7px',
          paddingBottom: '7px'
        },
      },
      facebook: {
        "&:hover": {
            backgroundColor: "#fbfbfb !important", 
            color: "#7c71d5 !important",   
            border: '1px solid black'          
        },
        textAlign: 'left',
        backgroundColor: '#3b5998',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '7px',
          paddingBottom: '7px'
        },
      },
      buttonText: {
        "&:hover": {
            color: "#7c71d5 !important",           
        },
        color: '#fbfbfb',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '15px',
        textTransform: 'capitalize'
      },

      headlineButton: {
        "&:hover": {
            backgroundColor: "#574bb3 !important",        
        },
        textAlign: 'left',
        backgroundColor: '#7c71d5',
        [theme.breakpoints.up('xs')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px',
          fontSize:'8px'
        },      
        [theme.breakpoints.up('sm')]: {
          borderRadius: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '1px',
          paddingBottom: '1px'
        },
        [theme.breakpoints.up('md')]: {        
          borderRadius: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        [theme.breakpoints.up('lg')]: {
          borderRadius: '30px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '7px',
          paddingBottom: '7px'
        },
      },
      buttonTextBtn: {
        color: '#fbfbfb',
        fontFamily: 'Baloo Tamma 2',
        fontWeight: 700,
        fontSize: '15px',
        textTransform: 'capitalize'
      },
    
  }));

const SocialProfiles = () => {
    const classes = useStyles();
    const [platformData, setPlatformData] = useState([])
    // const [interest, setInterest] = useState([])
    // const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    
    // console.log(platformData,'iki paltform data ')

    useEffect(()=>{       
        if(TokenUser) {
            const loadPlatform = () => {
            // setLoading(true)
                Axios.get(`${API}/influencer/linking-account`, {
                    headers: {                    
                        Authorization: `Bearer ${TokenUser}`
                    }
                })
                .then((res) => {        
                    if(res.data) {
                        let platform = res.data.data.platform
                        let user_platform = res.data.data.user_platform

                        let state = []
                        let plaform = false

                        for(var i = 0; i < platform.length; i++) {
                            for(var j = 0; j < user_platform.length; j++) {
                                if(platform[i].id === user_platform[j].platform_id) {
                                    state.push(user_platform[j])
                                    plaform = true
                                }
                            } if(!plaform) {
                                state.push(platform[i])
                            }
                            plaform = false
                        }
                        setPlatformData(state)
                    }
                    // setInterest(res.data.data.interest)
                    // setLoading(false)
                }).catch((err) => {
                    console.log(err)
                    // setLoading(false)
                })        
            }
            loadPlatform()         

        }
    }, [dispatch]);

    const [accountPlatform, setAccountPlatform] = useState({
        username : '',
        follower : '',
        interest: []
    })
    

    const [alertError, setAlertError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [alertSuccess, setAlertSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")

    
    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertError(false);
      };
    
    const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setAlertSuccess(false);
    };

    const onClickSubmit = async (id)  => {
        // setLoading(true)
        let data = {            
            "platform_id": id,
            "username": accountPlatform.username,
            "follower": accountPlatform.follower,
            "interest": accountPlatform.interest            
        }
        const post = await Axios.post(`${API}/influencer/linking-account`, data, {
            headers: {                    
                Authorization: `bearer ${TokenUser}`
                }            
            })
            .then((res) => {
                setAlertSuccess(true)
                setSuccessMessage(res.data.message)
                setTimeout(() => {
                    window.location.reload()                    
                }, 2000);
                // setLoading(false)
            }).catch((err) => {
                setAlertError(true)
                setErrorMessage(err.response.data.message[0].message)
                // setLoading(false)
            })      
        
        return post;

    }

    
    const renderDataPlatform = () => {
        if(platformData) {
            return (
            <>
            {
                platformData.map((data, index) => (
                <>
                    
                    <Box key={index}>
                        <Box pt={4} id='fontTamma' color='#343c5a' fontWeight={600} fontSize={19} >
                            {data.platform_name}
                        </Box>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container direction='column'>
                                        <Box>
                                            <Form.Label>
                                                <Box id='fontTamma' color='#142461' fontSize={13} >USERNAME</Box>
                                            </Form.Label>
                                        </Box>

                                        <Box pb={2}>
                                            <Form.Control  
                                                type="text" 
                                                id={`${data.platform_name}-username`}
                                                placeholder="Username" 
                                                disabled={data.username ? true : false}
                                                defaultValue={data.username}
                                                onChange={(e) => setAccountPlatform({...accountPlatform, username : e.target.value})}
                                                
                                            /> 
                                        </Box>
                                        <Box>
                                            <Form.Label>
                                                <Box id='fontTamma' color='#142461' fontSize={13} >FOLLOWERS</Box>
                                            </Form.Label>
                                        </Box>

                                        <Box pb={2}>
                                            <Form.Control 
                                                type="number" 
                                                id={`${data.platform_name}-follower`}
                                                placeholder="Followers"                                                 
                                                size='small' 
                                                disabled={data.username ? true : false}
                                                defaultValue={data.followers}
                                                onChange={(e) => setAccountPlatform({...accountPlatform, follower : e.target.value})}
                                               
                                            /> 
                                        </Box>
                                        

                                        {/* <Box>
                                            <Form.Group controlId="formGridState">
                                                <Form.Control as="select"  disabled={data.username ? true : false}
                                                        onChange={(e) => setAccountPlatform({...accountPlatform, interest : [e.target.value] })}> 
                                                    <option value="">{ data.interest ? data.interest : 'Choose Interest' }</option>
                                                    {interest.map((data, i) => (
                                                        <option value={data.id}>{data.interest_name}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Box> */}

                                        
                                        <Box>
                                            <Button 
                                                className={classes.instagram} 
                                                style={{ backgroundColor: data.username ? '#7fbbcc' : '#9e69d2'}}
                                                onClick={() => {
                                                    let id = data.id                                            
                                                    onClickSubmit(id)
                                                }}    
                                                disabled={data.username ? true : false}
                                            >
                                            {data.username ? 
                                                <Box className={classes.buttonText}  >Submited Account</Box>
                                                :
                                                <Box className={classes.buttonText} >Submit Account</Box>
                                            }
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>                                
                            </Grid>                            
                        </Form.Group>
                    </Box>

                    </>
                ))
            }
            </>
            )
            
        } 
    }

    return (
    <React.Fragment>
    <div className={classes.roots}>
        <Snackbar 
            open={alertError} 
            autoHideDuration={2000} 
            onClose={handleCloseError}
            anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}
        >
            <Alert onClose={handleCloseError} severity="error">
            <Box className={classes.textError} >{errorMessage}</Box>
            </Alert>
        </Snackbar>

        <Snackbar 
            open={alertSuccess} 
            autoHideDuration={2000} 
            onClose={handleCloseSuccess}
            anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}
        >
            <Alert onClose={handleCloseSuccess} severity="success">
            <Box className={classes.textError} >{successMessage}</Box>
            </Alert>
        </Snackbar>

        </div>
    <AppBarUser/>           
        <Container maxWidth='lg'>
            <Grid container spacing={2} >
                <Grid item lg={3} md={3}  >
                    <MenuSideUser/>
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12} >
                    <Grid className={classes.rootComponent}>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                        
                            <Box>
                                <Box id='fontTamma' fontSize={25} color='#343c5a' >
                                    <span style={{fontWeight:'bold', color:'#7c71d5'}}>{localStorage.getItem('nama') + ' '}</span>
                                    - Social Profiles 
                                </Box>
                            </Box>
                            <Box className={classes.lineDivider}></Box>
                            <Box pt={1} id='fontTamma' color='#6e738a' fontSize={18} >
                                Link Kolia to your social profile accounts
                            </Box>

                            {renderDataPlatform()}

                            <Box className={classes.lineDivider}></Box>

                            <Box pt={4}>
                                <Grid container direction='row' justify='flex-end'  >
                                    <Link href='my-interest' >
                                        <Button className={classes.headlineButton}  >
                                            <Box className={classes.buttonTextBtn}>Save Details</Box>
                                        </Button>
                                    </Link>
                                </Grid>
                            </Box>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>            
        </Container>
        
    </React.Fragment>
    )
}

export default SocialProfiles;